import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

import {
  getMatchedCareersList,
  getDailyCareersDisplayList,
  getNextDayDailyCareersDisplayList,
  getCareerAndSaveToFavoriteCareersList,
  getCareerAndRemoveFromFavoriteCareersList,
} from "../store/careers";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import Footer from "./layout/Footer";
import Career from "./layout/Career";
import RadioButtons from "./layout/RadioButtons";
import DropdownCheckboxList from "./layout/DropdownCheckboxList";
import Loader from "./layout/Loader";
import Toast from "./layout/Toast";
import PaymentInfo from "./layout/PaymentInfo";

import ChatbotButton from "./ChatbotButton";

import DirectHitIcon from "../assets/images/direct-hit_icon.png";
import SparklingHeartIcon from "../assets/images/sparkling-heart_icon.png";
import BriefcaseIcon from "../assets/images/briefcase_icon.png";

import Vector2Img from "../assets/images/vector-2.png";
import Vector5Img from "../assets/images/vector-5.png";

import NoBullshitJobsImg from "../assets/images/No-bullshit-jobs-img.png";
import PlainWhiteHeartIcon from "../assets/images/plain_white_heart.svg";
import LockIcon from "../assets/images/lock.svg";

import "../styles/CareersList.css";

const DAILY_CAREERS_DISPLAY = {
  "01-01": [2, 82, 162],
  "01-02": [3, 83, 163],
  "01-03": [4, 84, 164],
  "01-04": [5, 85, 165],
  "01-05": [6, 86, 166],
  "01-06": [7, 87, 167],
  "01-07": [8, 88, 168],
  "01-08": [9, 89, 169],
  "01-09": [10, 90, 170],
  "01-10": [11, 91, 171],
  "01-11": [12, 92, 172],
  "01-12": [13, 93, 173],
  "01-13": [14, 94, 174],
  "01-14": [15, 95, 175],
  "01-15": [16, 96, 176],
  "01-16": [17, 97, 177],
  "01-17": [18, 98, 178],
  "01-18": [19, 99, 179],
  "01-19": [20, 100, 180],
  "01-20": [21, 101, 181],
  "01-21": [22, 102, 182],
  "01-22": [23, 103, 183],
  "01-23": [24, 104, 184],
  "01-24": [25, 105, 185],
  "01-25": [26, 106, 186],
  "01-26": [27, 107, 187],
  "01-27": [28, 108, 188],
  "01-28": [29, 109, 189],
  "01-29": [30, 110, 190],
  "01-30": [31, 111, 191],
  "01-31": [32, 112, 192],
  "02-01": [33, 113, 193],
  "02-02": [34, 114, 194],
  "02-03": [35, 115, 195],
  "02-04": [36, 116, 196],
  "02-05": [37, 117, 197],
  "02-06": [38, 118, 198],
  "02-07": [39, 119, 199],
  "02-08": [40, 120, 200],
  "02-09": [41, 121, 201],
  "02-10": [42, 122, 202],
  "02-11": [43, 123, 203],
  "02-12": [44, 124, 204],
  "02-13": [45, 125, 205],
  "02-14": [46, 126, 206],
  "02-15": [47, 127, 207],
  "02-16": [48, 128, 208],
  "02-17": [49, 129, 209],
  "02-18": [50, 130, 210],
  "02-19": [51, 131, 211],
  "02-20": [52, 132, 212],
  "02-21": [53, 133, 213],
  "02-22": [54, 134, 214],
  "02-23": [55, 135, 215],
  "02-24": [56, 136, 216],
  "02-25": [57, 137, 217],
  "02-26": [58, 138, 218],
  "02-27": [59, 139, 219],
  "02-28": [60, 140, 220],
  "02-29": [61, 141, 221],
  "03-01": [62, 142, 222],
  "03-02": [63, 143, 223],
  "03-03": [64, 144, 224],
  "03-04": [65, 145, 225],
  "03-05": [66, 146, 226],
  "03-06": [67, 147, 227],
  "03-07": [68, 148, 228],
  "03-08": [69, 149, 229],
  "03-09": [70, 150, 230],
  "03-10": [71, 151, 231],
  "03-11": [72, 152, 232],
  "03-12": [73, 153, 233],
  "03-13": [74, 154, 234],
  "03-14": [75, 155, 235],
  "03-15": [76, 156, 236],
  "03-16": [77, 157, 237],
  "03-17": [78, 158, 238],
  "03-18": [79, 159, 239],
  "03-19": [80, 160, 240],
  "03-20": [81, 161, 241],
  "03-21": [82, 162, 242],
  "03-22": [83, 163, 243],
  "03-23": [84, 164, 244],
  "03-24": [85, 165, 245],
  "03-25": [86, 166, 246],
  "03-26": [87, 167, 247],
  "03-27": [88, 168, 248],
  "03-28": [89, 169, 249],
  "03-29": [90, 170, 250],
  "03-30": [91, 171, 251],
  "03-31": [92, 172, 1],
  "04-01": [93, 173, 2],
  "04-02": [94, 174, 3],
  "04-03": [95, 175, 4],
  "04-04": [96, 176, 5],
  "04-05": [97, 177, 6],
  "04-06": [98, 178, 7],
  "04-07": [99, 179, 8],
  "04-08": [100, 180, 9],
  "04-09": [101, 181, 10],
  "04-10": [102, 182, 11],
  "04-11": [103, 183, 12],
  "04-12": [104, 184, 13],
  "04-13": [105, 185, 14],
  "04-14": [106, 186, 15],
  "04-15": [107, 187, 16],
  "04-16": [108, 188, 17],
  "04-17": [109, 189, 18],
  "04-18": [110, 190, 19],
  "04-19": [111, 191, 20],
  "04-20": [112, 192, 21],
  "04-21": [113, 193, 22],
  "04-22": [114, 194, 23],
  "04-23": [115, 195, 24],
  "04-24": [116, 196, 25],
  "04-25": [117, 197, 26],
  "04-26": [118, 198, 27],
  "04-27": [119, 199, 28],
  "04-28": [120, 200, 29],
  "04-29": [121, 201, 30],
  "04-30": [122, 202, 31],
  "05-01": [123, 203, 32],
  "05-02": [124, 204, 33],
  "05-03": [125, 205, 34],
  "05-04": [126, 206, 35],
  "05-05": [127, 207, 36],
  "05-06": [128, 208, 37],
  "05-07": [129, 209, 38],
  "05-08": [130, 210, 39],
  "05-09": [131, 211, 40],
  "05-10": [132, 212, 41],
  "05-11": [133, 213, 42],
  "05-12": [134, 214, 43],
  "05-13": [135, 215, 44],
  "05-14": [136, 216, 45],
  "05-15": [137, 217, 46],
  "05-16": [138, 218, 47],
  "05-17": [139, 219, 48],
  "05-18": [140, 220, 49],
  "05-19": [141, 221, 50],
  "05-20": [142, 222, 51],
  "05-21": [143, 223, 52],
  "05-22": [144, 224, 53],
  "05-23": [145, 225, 54],
  "05-24": [146, 226, 55],
  "05-25": [147, 227, 56],
  "05-26": [148, 228, 57],
  "05-27": [149, 229, 58],
  "05-28": [150, 230, 59],
  "05-29": [151, 231, 60],
  "05-30": [152, 232, 61],
  "05-31": [153, 233, 62],
  "06-01": [154, 234, 63],
  "06-02": [155, 235, 64],
  "06-03": [156, 236, 65],
  "06-04": [157, 237, 66],
  "06-05": [158, 238, 67],
  "06-06": [159, 239, 68],
  "06-07": [160, 240, 69],
  "06-08": [161, 241, 70],
  "06-09": [162, 242, 71],
  "06-10": [163, 243, 72],
  "06-11": [164, 244, 73],
  "06-12": [165, 245, 74],
  "06-13": [166, 246, 75],
  "06-14": [167, 247, 76],
  "06-15": [168, 248, 77],
  "06-16": [169, 249, 78],
  "06-17": [170, 250, 79],
  "06-18": [171, 251, 80],
  "06-19": [172, 1, 81],
  "06-20": [173, 2, 82],
  "06-21": [174, 3, 83],
  "06-22": [175, 4, 84],
  "06-23": [176, 5, 85],
  "06-24": [177, 6, 86],
  "06-25": [178, 7, 87],
  "06-26": [179, 8, 88],
  "06-27": [180, 9, 89],
  "06-28": [181, 10, 90],
  "06-29": [182, 11, 91],
  "06-30": [183, 12, 92],
  "07-01": [184, 13, 93],
  "07-02": [185, 14, 94],
  "07-03": [186, 15, 95],
  "07-04": [187, 16, 96],
  "07-05": [188, 17, 97],
  "07-06": [189, 18, 98],
  "07-07": [190, 19, 99],
  "07-08": [191, 20, 100],
  "07-09": [192, 21, 101],
  "07-10": [193, 22, 102],
  "07-11": [194, 23, 103],
  "07-12": [195, 24, 104],
  "07-13": [196, 25, 105],
  "07-14": [197, 26, 106],
  "07-15": [198, 27, 107],
  "07-16": [199, 28, 108],
  "07-17": [200, 29, 109],
  "07-18": [201, 30, 110],
  "07-19": [202, 31, 111],
  "07-20": [203, 32, 112],
  "07-21": [204, 33, 113],
  "07-22": [205, 34, 114],
  "07-23": [206, 35, 115],
  "07-24": [207, 36, 116],
  "07-25": [208, 37, 117],
  "07-26": [209, 38, 118],
  "07-27": [210, 39, 119],
  "07-28": [211, 40, 120],
  "07-29": [212, 41, 121],
  "07-30": [213, 42, 122],
  "07-31": [214, 43, 123],
  "08-01": [215, 44, 124],
  "08-02": [216, 45, 125],
  "08-03": [217, 46, 126],
  "08-04": [218, 47, 127],
  "08-05": [219, 48, 128],
  "08-06": [220, 49, 129],
  "08-07": [221, 50, 130],
  "08-08": [222, 51, 131],
  "08-09": [223, 52, 132],
  "08-10": [224, 53, 133],
  "08-11": [225, 54, 134],
  "08-12": [226, 55, 135],
  "08-13": [227, 56, 136],
  "08-14": [228, 57, 137],
  "08-15": [229, 58, 138],
  "08-16": [230, 59, 139],
  "08-17": [231, 60, 140],
  "08-18": [232, 61, 141],
  "08-19": [233, 62, 142],
  "08-20": [234, 63, 143],
  "08-21": [235, 64, 144],
  "08-22": [236, 65, 145],
  "08-23": [237, 66, 146],
  "08-24": [238, 67, 147],
  "08-25": [239, 68, 148],
  "08-26": [240, 69, 149],
  "08-27": [241, 70, 150],
  "08-28": [242, 71, 151],
  "08-29": [243, 72, 152],
  "08-30": [244, 73, 153],
  "08-31": [245, 74, 154],
  "09-01": [246, 75, 155],
  "09-02": [247, 76, 156],
  "09-03": [248, 77, 157],
  "09-04": [249, 78, 158],
  "09-05": [250, 79, 159],
  "09-06": [251, 80, 160],
  "09-07": [1, 81, 161],
  "09-08": [2, 82, 162],
  "09-09": [3, 83, 163],
  "09-10": [4, 84, 164],
  "09-11": [5, 85, 165],
  "09-12": [6, 86, 166],
  "09-13": [7, 87, 167],
  "09-14": [8, 88, 168],
  "09-15": [9, 89, 169],
  "09-16": [10, 90, 170],
  "09-17": [11, 91, 171],
  "09-18": [12, 92, 172],
  "09-19": [13, 93, 173],
  "09-20": [14, 94, 174],
  "09-21": [15, 95, 175],
  "09-22": [16, 96, 176],
  "09-23": [17, 97, 177],
  "09-24": [18, 98, 178],
  "09-25": [19, 99, 179],
  "09-26": [20, 100, 180],
  "09-27": [21, 101, 181],
  "09-28": [22, 102, 182],
  "09-29": [23, 103, 183],
  "09-30": [24, 104, 184],
  "10-01": [25, 105, 185],
  "10-02": [26, 106, 186],
  "10-03": [27, 107, 187],
  "10-04": [28, 108, 188],
  "10-05": [29, 109, 189],
  "10-06": [30, 110, 190],
  "10-07": [31, 111, 191],
  "10-08": [32, 112, 192],
  "10-09": [33, 113, 193],
  "10-10": [34, 114, 194],
  "10-11": [35, 115, 195],
  "10-12": [36, 116, 196],
  "10-13": [37, 117, 197],
  "10-14": [38, 118, 198],
  "10-15": [39, 119, 199],
  "10-16": [40, 120, 200],
  "10-17": [41, 121, 201],
  "10-18": [42, 122, 202],
  "10-19": [43, 123, 203],
  "10-20": [44, 124, 204],
  "10-21": [45, 125, 205],
  "10-22": [46, 126, 206],
  "10-23": [47, 127, 207],
  "10-24": [48, 128, 208],
  "10-25": [49, 129, 209],
  "10-26": [50, 130, 210],
  "10-27": [51, 131, 211],
  "10-28": [52, 132, 212],
  "10-29": [53, 133, 213],
  "10-30": [54, 134, 214],
  "10-31": [55, 135, 215],
  "11-01": [56, 136, 216],
  "11-02": [57, 137, 217],
  "11-03": [58, 138, 218],
  "11-04": [59, 139, 219],
  "11-05": [60, 140, 220],
  "11-06": [61, 141, 221],
  "11-07": [62, 142, 222],
  "11-08": [63, 143, 223],
  "11-09": [64, 144, 224],
  "11-10": [65, 145, 225],
  "11-11": [66, 146, 226],
  "11-12": [67, 147, 227],
  "11-13": [68, 148, 228],
  "11-14": [69, 149, 229],
  "11-15": [70, 150, 230],
  "11-16": [71, 151, 231],
  "11-17": [72, 152, 232],
  "11-18": [73, 153, 233],
  "11-19": [74, 154, 234],
  "11-20": [75, 155, 235],
  "11-21": [76, 156, 236],
  "11-22": [77, 157, 237],
  "11-23": [78, 158, 238],
  "11-24": [79, 159, 239],
  "11-25": [80, 160, 240],
  "11-26": [81, 161, 241],
  "11-27": [82, 162, 242],
  "11-28": [83, 163, 243],
  "11-29": [84, 164, 244],
  "11-30": [85, 165, 245],
  "12-01": [86, 166, 246],
  "12-02": [87, 167, 247],
  "12-03": [88, 168, 248],
  "12-04": [89, 169, 249],
  "12-05": [90, 170, 250],
  "12-06": [91, 171, 251],
  "12-07": [92, 172, 1],
  "12-08": [93, 173, 2],
  "12-09": [94, 174, 3],
  "12-10": [95, 175, 4],
  "12-11": [96, 176, 5],
  "12-12": [97, 177, 6],
  "12-13": [98, 178, 7],
  "12-14": [99, 179, 8],
  "12-15": [100, 180, 9],
  "12-16": [101, 181, 10],
  "12-17": [102, 182, 11],
  "12-18": [103, 183, 12],
  "12-19": [104, 184, 13],
  "12-20": [105, 185, 14],
  "12-21": [106, 186, 15],
  "12-22": [107, 187, 16],
  "12-23": [108, 188, 17],
  "12-24": [109, 189, 18],
  "12-25": [110, 190, 19],
  "12-26": [111, 191, 20],
  "12-27": [112, 192, 21],
  "12-28": [113, 193, 22],
  "12-29": [114, 194, 23],
  "12-30": [115, 195, 24],
  "12-31": [116, 196, 25],
};

const PROFILE_FILTERS = {
  profileHearing: [
    1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 14, 15, 18, 19, 20, 21, 22, 23, 24, 25,
    26, 27, 28, 30, 31, 32, 51, 54, 59, 60, 62, 74, 75, 92, 96, 97, 98, 100,
    103, 104, 107, 108, 109, 110, 113, 114, 115, 122, 123, 124, 125, 129, 132,
    139, 140, 141, 143, 144, 147, 154, 159, 160, 161, 162, 164, 165, 166, 167,
    168, 169, 205, 206, 208, 210, 211, 212, 214, 215, 217, 221, 227, 228, 230,
    235, 238, 240, 242, 243, 244, 247, 250,
  ],
  profileSight: [
    8, 13, 16, 17, 29, 33, 34, 35, 36, 61, 78, 83, 84, 85, 86, 93, 95, 126, 127,
    128, 137, 138, 142, 145, 146, 148, 149, 150, 151, 153, 157, 158, 163, 207,
    213, 218, 223, 229, 237, 245, 246, 248, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12,
    15, 18, 19, 20, 21, 27, 30, 31, 32, 51, 59, 60, 62, 96, 97, 98, 100, 103,
    104, 107, 108, 109, 110, 113, 114, 115, 122, 123, 124, 125, 132, 139, 140,
    141, 143, 144, 147, 154, 160, 161, 162, 164, 165, 166, 205, 206, 208, 210,
    217, 221, 227, 228, 230, 235, 238, 240, 243, 244, 247, 250,
  ],
  profileMovement: [
    251, 14, 22, 23, 24, 25, 26, 28, 82, 94, 136, 216, 232, 233, 234, 249, 54,
    74, 129, 159, 167, 168, 169, 211, 212, 214, 215, 8, 13, 16, 17, 29, 33, 34,
    35, 36, 61, 78, 83, 84, 85, 86, 93, 95, 126, 127, 128, 137, 138, 142, 145,
    146, 148, 149, 150, 151, 153, 157, 158, 163, 207, 213, 218, 223, 229, 237,
    245, 246, 248, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 15, 18, 19, 20, 21, 27,
    30, 31, 32, 51, 59, 60, 62, 96, 97, 98, 100, 103, 104, 107, 108, 109, 110,
    113, 114, 115, 122, 123, 124, 125, 132, 139, 140, 141, 143, 144, 147, 154,
    160, 161, 162, 164, 165, 166, 205, 206, 208, 210, 217, 221, 227, 228, 230,
    235, 238, 240, 243, 244, 247, 250,
  ],
};

const CareersList = () => {
  const dispatch = useDispatch();

  const { isPaid } = useSelector((state) => state.user);
  const { riasecScore } = useSelector((state) => state.report);
  const {
    all,
    dailyCareersDisplay,
    matched,
    favorite,
    totalCareers,
    totalMatchedCareers,
  } = useSelector((state) => state.careers);

  const [pageAllCareersIndex, setPageAllCareersIndex] = useState(1);
  const [pageMatchedCareersIndex, setPageMatchedCareersIndex] = useState(1);

  const [category, setCategory] = useState("matched");

  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [filteredMatchedCareers, setFilteredMatchedCareers] = useState([]);
  const [filteredAllCareers, setFilteredAllCareers] = useState([]);
  const [filteredAllDailyCareers, setFilteredAllDailyCareers] = useState([]);

  const [currentDate, setCurrentDate] = useState(new Date());

  const headingRef = useRef(null);
  const paymentHeadingRef = useRef(null);

  const showMore = () => {
    if (
      pageAllCareersIndex <= Math.ceil(filteredAllCareers.length / 3) &&
      category === "all"
    ) {
      setPageAllCareersIndex(pageAllCareersIndex + 1);
    } else if (
      pageMatchedCareersIndex <= Math.ceil(filteredMatchedCareers.length / 3) &&
      category === "matched"
    ) {
      setPageMatchedCareersIndex(pageMatchedCareersIndex + 1);
    }
  };

  const getDatesFormat = (date) => {
    const currentDate = new Date(date);
    const nextDayDate = new Date(date);

    currentDate.setDate(currentDate.getDate());
    nextDayDate.setDate(currentDate.getDate() + 1);

    const formatter = new Intl.DateTimeFormat();
    const timeZone = formatter.resolvedOptions().timeZone;

    const formattedDate = currentDate.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone,
    });

    const formattedNextDayDate = nextDayDate.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone,
    });

    const formattedDateWithHyphen = formattedDate.replace(/\//g, "-");
    const formattedNextDayDateWithHyphen = formattedNextDayDate.replace(
      /\//g,
      "-"
    );

    const firstFiveCharacters = formattedDateWithHyphen.slice(0, 5);
    const firstNextDayFiveCharacters = formattedNextDayDateWithHyphen.slice(
      0,
      5
    );

    return [firstFiveCharacters, firstNextDayFiveCharacters];
  };

  const goToPaymentInfo = () => {
    paymentHeadingRef.current?.focus();
    window.scrollTo({
      top: paymentHeadingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  };

  const handleCareerStatus = (id, isFavorite) => {
    if (isFavorite) {
      dispatch(getCareerAndSaveToFavoriteCareersList(id));
    } else {
      dispatch(getCareerAndRemoveFromFavoriteCareersList(id));
    }
  };

  const itemsToShow = useMemo(() => {
    const selectedProfileFilters = selectedProfiles.map(
      (selectedProfile) => PROFILE_FILTERS[selectedProfile]
    );

    const combinedProfileFilter = selectedProfileFilters.reduce(
      (combined, profileFilter) => {
        if (combined.length === 0) {
          return profileFilter;
        }

        return combined.filter((element) => profileFilter.includes(element));
      },
      []
    );

    if (category === "matched" && isPaid) {
      return filteredMatchedCareers
        .slice(0, pageMatchedCareersIndex * 3)
        .map((item) => (
          <Career
            key={item.id}
            id={item.id}
            name_en={item.name_en}
            description={item.description}
            characteristics={item.characteristics}
            tasks={item.tasks}
            workplaces={item.workplaces}
            handleStatusChecked={handleCareerStatus}
          />
        ));
    } else if (category === "all" && !isPaid) {
      return filteredAllDailyCareers.map((item) => (
        <Career
          key={item.id}
          id={item.id}
          handleStatusChecked={handleCareerStatus}
          {...item.attributes}
        />
      ));
    } else if (category === "all" && isPaid) {
      return filteredAllCareers
        .slice(0, pageAllCareersIndex * 3)
        .map((item) => (
          <Career
            key={item.id}
            id={item.id}
            handleStatusChecked={handleCareerStatus}
            {...item.attributes}
          />
        ));
    } else if (selectedProfiles.length === 0 && category === "favorite") {
      return favorite.map((item) => (
        <Career
          key={item.id}
          id={item.id}
          name_en={item.name_en}
          description={item.description}
          characteristics={item.characteristics}
          tasks={item.tasks}
          workplaces={item.workplaces}
          handleStatusChecked={handleCareerStatus}
        />
      ));
    } else if (category === "favorite") {
      return favorite
        .filter((item) => combinedProfileFilter.includes(item.id))
        .map((item) => (
          <Career
            key={item.id}
            id={item.id}
            name_en={item.name_en}
            description={item.description}
            characteristics={item.characteristics}
            tasks={item.tasks}
            workplaces={item.workplaces}
            handleStatusChecked={handleCareerStatus}
          />
        ));
    }

    return [];
  }, [
    category,
    all,
    filteredAllDailyCareers,
    matched,
    favorite,
    isPaid,
    pageAllCareersIndex,
    pageMatchedCareersIndex,
    selectedProfiles,
    filteredAllCareers,
    filteredMatchedCareers,
  ]);

  const handleProfileChange = (selectedProfiles) => {
    setSelectedProfiles(selectedProfiles);
  };

  const handleValueChange = (e) => {
    setCategory(e.target.value);
  };

  useLayoutEffect(() => {
    document.title = "Lista zawodów | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(getMatchedCareersList());
  }, [isPaid]);

  useEffect(() => {
    const dateFormats = getDatesFormat(currentDate);

    dispatch(getDailyCareersDisplayList(DAILY_CAREERS_DISPLAY[dateFormats[0]]));
    dispatch(
      getNextDayDailyCareersDisplayList(DAILY_CAREERS_DISPLAY[dateFormats[1]])
    );

    const intervalId = setInterval(() => {
      const newDate = new Date();

      if (newDate.getDate() !== currentDate.getDate()) {
        setCurrentDate(newDate);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [currentDate]);

  useEffect(() => {
    if (selectedProfiles.length === 0) {
      setFilteredMatchedCareers(matched);
      setFilteredAllCareers(all);
      setFilteredAllDailyCareers(dailyCareersDisplay);
    } else {
      const selectedProfileFilters = selectedProfiles.map(
        (selectedProfile) => PROFILE_FILTERS[selectedProfile]
      );

      const combinedProfileFilter = selectedProfileFilters.reduce(
        (combined, profileFilter) => {
          if (combined.length === 0) {
            return profileFilter;
          }

          return combined.filter((element) => profileFilter.includes(element));
        },
        []
      );

      const filteredMatchedList = matched.filter((item) =>
        combinedProfileFilter.includes(item.id)
      );
      setFilteredMatchedCareers(filteredMatchedList);

      const filteredAllList = all.filter((item) =>
        combinedProfileFilter.includes(item.id)
      );
      setFilteredAllCareers(filteredAllList);

      const filteredAllDailyList = dailyCareersDisplay.filter((item) =>
        combinedProfileFilter.includes(item.id)
      );
      setFilteredAllDailyCareers(filteredAllDailyList);
    }
  }, [selectedProfiles, matched, all, dailyCareersDisplay]);

  useEffect(() => {
    if (category === "matched" && isPaid) {
      setPageMatchedCareersIndex(1);
    } else if (category === "all") {
      setPageAllCareersIndex(1);
    }
  }, [filteredMatchedCareers, filteredAllCareers, category, isPaid]);

  return (
    <>
      <Skiplinks isChatbot />
      <Navbar />
      <main id="content">
        <ContentLogo />
        <div className="l-careers-container">
          <div className="c-careers">
            <div className="c-careers__box">
              <h2 className="heading heading-h2" tabIndex={-1} ref={headingRef}>
                Lista zawodów <img src={BriefcaseIcon} alt="" />
              </h2>
            </div>
            <div className="c-careers__box">
              <div className="c-careers__content">
                <div className="c-careers__content-box">
                  <img className="c-decoration-img" src={Vector5Img} alt="" />
                  <p className="c-careers__content-title">
                    {totalMatchedCareers && totalCareers ? (
                      `${totalMatchedCareers}/${totalCareers}`
                    ) : (
                      <Skeleton />
                    )}
                  </p>
                  <p className="c-careers__content-subtitle">
                    dopasowanych zawodów{" "}
                    <img className="c-icon" src={DirectHitIcon} alt="" />
                  </p>
                </div>
                <div className="c-careers__content-box">
                  <img className="c-decoration-img" src={Vector2Img} alt="" />
                  <p className="c-careers__content-title">
                    {favorite ? favorite.length : <Skeleton />}{" "}
                    <img src={PlainWhiteHeartIcon} alt="" />
                  </p>
                  <p className="c-careers__content-subtitle">
                    dodano do ulubionych{" "}
                    <img className="c-icon" src={SparklingHeartIcon} alt="" />
                  </p>
                </div>
                <div className="c-careers__content-box">
                  <img src={NoBullshitJobsImg} alt="" />
                </div>
              </div>
            </div>
            <div className="c-careers__box">
              {!favorite.length && category === "favorite" && <Toast />}
            </div>
            <div className="c-careers__box">
              <div className="c-careers-filter">
                <h3 className="c-careers-filter__title">Filtry</h3>
                <div className="c-careers-filter__box">
                  <RadioButtons
                    valueChange={handleValueChange}
                    category={category}
                  />
                  <DropdownCheckboxList
                    PROFILE_FILTERS={PROFILE_FILTERS}
                    onProfileChange={handleProfileChange}
                  />
                </div>
              </div>
            </div>
            <div className="c-careers__box">
              <div className="c-careers__list">
                {itemsToShow.length ||
                (category === "matched" && !isPaid && riasecScore) ? (
                  itemsToShow
                ) : category !== "favorite" && selectedProfiles.length === 0 ? (
                  <Loader
                    type={"careers-list"}
                    content={"Trwa przygotowanie listy zawodów"}
                    noDataContent={[
                      "Wypełnij test i dowiedz się, jaka praca da Ci najwięcej satysfakcji!",
                      "Jesteś o krok od poznania idealnie dopasowanej kariery!",
                    ]}
                  />
                ) : favorite.length === 0 ? null : (
                  <p role="alert">
                    Brak zawodów zgodnych ze wskazanymi kryteriami
                  </p>
                )}
                {(itemsToShow.length !== 0 &&
                  pageAllCareersIndex <
                    Math.ceil(filteredAllCareers.length / 3) &&
                  category === "all") ||
                (itemsToShow.length !== 0 &&
                  pageMatchedCareersIndex <
                    Math.ceil(filteredMatchedCareers.length / 3) &&
                  category === "matched" &&
                  isPaid) ? (
                  <>
                    <button
                      className={
                        isPaid
                          ? "c-btn-show-more"
                          : "c-btn-show-more c-btn-show-more--disabled"
                      }
                      onClick={isPaid ? showMore : goToPaymentInfo}
                    >
                      {!isPaid && <img src={LockIcon} alt="" />} Pokaż więcej
                    </button>
                  </>
                ) : null}
                {!isPaid && category === "matched" && riasecScore ? (
                  <PaymentInfo
                    totalMatchedCareers={totalMatchedCareers}
                    type="matched"
                    ref={paymentHeadingRef}
                  />
                ) : null}
                {!isPaid && category === "all" ? (
                  <PaymentInfo
                    totalMatchedCareers={totalMatchedCareers}
                    type="all"
                    ref={paymentHeadingRef}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
      <ChatbotButton />
      <Footer />
    </>
  );
};

export default CareersList;
