import React, { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import Footer from "./layout/Footer";

import ChatbotButton from "./ChatbotButton";

import LeftArrow from "../assets/images/left-arrow.svg";
import DefaultJobbliIcon from "../assets/images/jobbli-default-icon.svg";
import LocationIcon from "../assets/images/location.svg";
import CategoryIcon from "../assets/images/briefcase.svg";
import BuildingIcon from "../assets/images/building-3.svg";
import TimeIcon from "../assets/images/clock.svg";

import "../styles/JobOfferDetails.css";

const JobOfferDetails = () => {
  const { id } = useParams();

  const { user } = useSelector((state) => state.user);

  const headingRef = useRef(null);

  const { data: offer, isLoading } = useQuery({
    queryKey: ["offer", id],
    queryFn: async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/job_offers.json`)
        .then((response) => {
          const data = response.data;

          const url = Object.keys(data)[id];
          const offer = Object.values(data)[id];

          if (offer) {
            return { url, ...offer };
          } else {
            throw new Error("Nie znaleziono oferty pracy");
          }
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
          throw new Error("Błąd podczas ładowania oferty pracy");
        }),
  });

  const removeAttributes = (node) => {
    if (node.type === "tag" && node.name) {
      delete node.attribs["role"];
      delete node.attribs["class"];
    }
    return node;
  };

  useEffect(() => {
    setTimeout(() => {
      headingRef.current?.focus();
      window.scrollTo({
        top: headingRef.current?.offsetTop - 200,
        behavior: "smooth",
      });
    }, 0);

    document.title = `${offer?.name} | Jobbli - kieszonkowy doradca zawodowy`;
  }, [offer?.name]);

  return (
    <>
      <Skiplinks isChatbot />
      <Navbar />
      <main id="content">
        <ContentLogo />
        <div className="l-job-offer-container">
          {!isLoading && (
            <>
              <div className="c-job-offer__header">
                <div className="c-job-offer__box">
                  <Link to={`/job-offers`}>
                    <img src={LeftArrow} alt="" />
                    Wróć do ofert pracy
                  </Link>
                </div>
                <div className="c-job-offer__box">
                  <div className="c-job-offer__company-logo">
                    <img
                      src={
                        offer?.company_logo
                          ? offer?.company_logo
                          : DefaultJobbliIcon
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="c-job-offer__box">
                  <h2
                    className="c-job-offer__name"
                    tabIndex={-1}
                    ref={headingRef}
                  >
                    {offer?.name}
                  </h2>
                  <p className="c-job-offer__company">{offer?.company}</p>
                </div>
              </div>
              <div className="c-job-offer__content">
                <div className="c-job-offer-infos">
                  {offer?.location && (
                    <div className="c-job-offer-info">
                      <p role="heading" aria-level={3}>
                        <img src={LocationIcon} alt="" />
                        Lokalizacja
                      </p>
                      <p>{offer?.location}</p>
                    </div>
                  )}
                  {offer?.category && (
                    <div className="c-job-offer-info">
                      <p role="heading" aria-level={3}>
                        <img src={CategoryIcon} alt="" />
                        Dział
                      </p>
                      <p>{offer?.category}</p>
                    </div>
                  )}
                  {offer?.workmode && (
                    <div className="c-job-offer-info">
                      <p role="heading" aria-level={3}>
                        <img src={BuildingIcon} alt="" />
                        Tryb pracy
                      </p>
                      <p>{offer?.workmode}</p>
                    </div>
                  )}
                  {offer?.worktype && (
                    <div className="c-job-offer-info">
                      <p role="heading" aria-level={3}>
                        <img src={TimeIcon} alt="" />
                        Tryb pracy
                      </p>
                      <p>{offer?.worktype}</p>
                    </div>
                  )}
                </div>
                {offer?.added !== "None" || offer?.expires !== "None" ? (
                  <div className="c-job-offer__box">
                    <p>
                      <strong>Dodano:</strong> {offer?.added.slice(0, 10)}
                      {offer?.expires !== "None" &&
                        parse(
                          `, <strong>Wygasa:</strong> ${offer?.expires.slice(
                            0,
                            10
                          )}`
                        )}
                    </p>
                  </div>
                ) : null}
                {offer?.desc && (
                  <div className="c-job-offer__box">
                    <p
                      role="heading"
                      aria-level={3}
                      className="c-job-offer__box__title"
                    >
                      Opis
                    </p>
                    {parse(offer?.desc, {
                      replace: removeAttributes,
                    })}
                  </div>
                )}
                {offer?.obligations && (
                  <div className="c-job-offer__box">
                    <p
                      role="heading"
                      aria-level={3}
                      className="c-job-offer__box__title"
                    >
                      Obowiązki
                    </p>
                    {parse(offer?.obligations, {
                      replace: removeAttributes,
                    })}
                  </div>
                )}
                {offer?.requirements && (
                  <div className="c-job-offer__box">
                    <p
                      role="heading"
                      aria-level={3}
                      className="c-job-offer__box__title"
                    >
                      Wymagania
                    </p>
                    {parse(offer?.requirements, {
                      replace: removeAttributes,
                    })}
                  </div>
                )}
                {offer?.we_offer && (
                  <div className="c-job-offer__box">
                    <p
                      role="heading"
                      aria-level={3}
                      className="c-job-offer__box__title"
                    >
                      Oferujemy
                    </p>
                    {parse(offer?.we_offer, {
                      replace: removeAttributes,
                    })}
                  </div>
                )}
                {offer?.additional_info && (
                  <div className="c-job-offer__box">
                    <p
                      role="heading"
                      aria-level={3}
                      className="c-job-offer__box__title"
                    >
                      Dodatkowe informacje
                    </p>
                    {parse(offer?.additional_info, {
                      replace: removeAttributes,
                    })}
                  </div>
                )}
                <div className="c-job-offer__box">
                  <button
                    className="c-btn c-btn--small-size c-btn--blue-color c-btn--100-w"
                    onClick={() =>
                      window.open(
                        `/api/outlink?uid=${user?.id}&url=${encodeURI(
                          offer?.url
                        )}`,
                        "_blank"
                      )
                    }
                  >
                    Aplikuj z Doing Good
                    <span className="sr-only">
                      (link otwiera się w nowej karcie)
                    </span>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </main>
      <ChatbotButton />
      <Footer />
    </>
  );
};

export default JobOfferDetails;
