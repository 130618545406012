import React, { useEffect } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  load,
  checkUserHasPaid,
  checkUserHasAccessToDashboard,
} from "./store/user";
import { connectTestWithUser, getUserRiasecInfo } from "./store/report";
import {
  getTotalCareersListCount,
  getInitialCareersList,
  getMatchedCareersList,
  getInitialFavoriteCareersList,
} from "./store/careers";
import { getInitialSkillsList } from "./store/skills";

import Login from "./components/Login";
import Signup from "./components/Signup";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import NotFound from "./components/NotFound";
import PaymentStatusInfo from "./components/PaymentStatusInfo";

import Dashboard from "./components/Dashboard";
import Report from "./components/Report";
import CareersList from "./components/CareersList";
import Skills from "./components/Skills";
import JobOffers from "./components/JobOffers";
import JobOfferDetails from "./components/JobOfferDetails";
import Settings from "./components/Settings";
import PublicProfile from "./components/PublicProfile";
import PublicCareerList from "./components/PublicCareerList";

import "./styles/App.css";

const App = () => {
  const dispatch = useDispatch();
  const { riasecProfile } = useSelector((state) => state.report);
  const { hasAccessToDashboard } = useSelector((state) => state.user);

  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const token = localStorage.getItem("token");
  const testToken = localStorage.getItem("testToken");

  useEffect(() => {
    if (window.location.href.indexOf("oauth-signin") > -1) {
      localStorage.setItem("token", window.location.href.split("#")[1]);
      dispatch(load());
      return;
    }
    dispatch(load());
  }, []);

  useEffect(() => {
    if (token && testToken) {
      dispatch(connectTestWithUser(testToken));
    }

    if (token) {
      dispatch(load());
      dispatch(getUserRiasecInfo());
      dispatch(checkUserHasPaid());
      dispatch(checkUserHasAccessToDashboard());
      dispatch(getInitialSkillsList());
      dispatch(getTotalCareersListCount());
      dispatch(getInitialCareersList());
      dispatch(getInitialFavoriteCareersList());

      if (riasecProfile) {
        dispatch(getMatchedCareersList(riasecProfile));
      }
    }
  }, [token, testToken, riasecProfile]);

  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route path="/profile/:code" element={<PublicProfile />} />
      <Route
        path="/profile/careers-list/:code"
        element={<PublicCareerList />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/oauth-signin" element={<Login />} />
      <Route path="/sign-up" element={<Signup />} />
      <Route path="/login/from-typeform" element={<Login />} />
      <Route path="/sign-up/from-typeform" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword code={code} />} />
      <Route
        path="/report"
        element={!token ? <Navigate to="/" replace /> : <Report />}
      />
      <Route
        path="/careers-list"
        element={!token ? <Navigate to="/" replace /> : <CareersList />}
      />
      <Route
        path="/skills"
        element={!token ? <Navigate to="/" replace /> : <Skills />}
      />
      <Route
        path="/job-offers"
        element={!token ? <Navigate to="/" replace /> : <JobOffers />}
      />
      <Route
        path="/job-offers/:id"
        element={!token ? <Navigate to="/" replace /> : <JobOfferDetails />}
      />
      <Route
        path="/settings"
        element={!token ? <Navigate to="/" replace /> : <Settings />}
      />
      <Route
        path="/dashboard"
        element={
          !token ? (
            <Navigate to="/" replace />
          ) : hasAccessToDashboard ? (
            <Dashboard />
          ) : (
            <Report />
          )
        }
      />
      <Route
        path="/payment-success"
        element={<PaymentStatusInfo type={"success"} />}
      />
      <Route
        path="/payment-cancelled"
        element={<PaymentStatusInfo type={"cancelled"} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
