import React from "react";
import { useSelector } from "react-redux";

import ErrorIcon from "../../assets/images/error.svg";
import SuccessIcon from "../../assets/images/success.svg";

import "../../styles/Alert.css";

const Alert = () => {
  const { alertMessage, status } = useSelector((state) => state.user);

  const alertMessageHandler = () => {
    switch (alertMessage) {
      case "Invalid identifier or password":
        return "Niepoprawny adres email lub hasło";
      case "The provided current password is invalid":
        return "Aktualne hasło jest nieprawidłowe";
      case "Password do not match":
        return "Hasła nie są identyczne";
      case "Email or Username are already taken":
        return "Adres email lub nazwa użytkownika są niedostępne";
      case "Coupon already claimed":
        return "Kod został już wykorzystany";
      case "No coupon of this name in the database":
        return "Błędny kod. Sprawdź czy kod został wpisany poprawnie.";
      case "User already paid":
        return "Twoje konto jest już opłacone - nie musisz wykorzystywać kodu. Sprawdź dopasowane zawody i wybierz karierę marzeń.";
      case "Twoje konto zostało aktywowane! Sprawdź dopasowane zawody i wybierz karierę marzeń.":
        return "Twoje konto zostało aktywowane! Sprawdź dopasowane zawody i wybierz karierę marzeń.";
      case "Hasło zostało zmienione.":
        return "Hasło zostało zmienione.";
      case "Hasło zostało zmienione. Do zobaczenia kiedy zapomnisz go następnym razem ;)":
        return "Hasło zostało zmienione. Do zobaczenia kiedy zapomnisz go następnym razem ;)";
      case "Na podany adres e-mail zostanie wysłana wiadomość z linkiem, która umożliwia zresetowanie hasła ;)":
        return "Na podany adres e-mail zostanie wysłana wiadomość z linkiem, która umożliwia zresetowanie hasła ;)";
      case "Konto zostało pomyślnie utworzone. Sprawdź pocztę email i aktywuj konto otrzymanym linkiem":
        return "Konto zostało pomyślnie utworzone. Sprawdź pocztę email i aktywuj konto otrzymanym linkiem";
      default:
        return "Wystąpił błąd podczas przetwarzania danych. Skontaktuj się z pomocą techniczną";
    }
  };

  return (
    <div
      role="alert"
      className={status ? "c-alert c-alert--success" : "c-alert c-alert--error"}
    >
      <img src={status ? SuccessIcon : ErrorIcon} alt="" />
      {alertMessageHandler()}
    </div>
  );
};

export default Alert;
