import React, { useLayoutEffect, useEffect, useRef } from "react";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import Footer from "./layout/Footer";

import JobbliProfileImg from "../assets/images/Jobbli-profile-img.png";

import "../styles/NotFound.css";

const NotFound = () => {
  useLayoutEffect(() => {
    document.title =
      "Oops! - Nie odnaleźliśmy Twojej strony | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  const headingRef = useRef(null);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Skiplinks />
      <Navbar />
      <main id="content">
        <ContentLogo />
        <div className="l-not-found-container">
          <div className="c-not-found-container">
            <div className="c-not-found-box">
              <img src={JobbliProfileImg} alt="" />
            </div>
            <div className="c-not-found-box">
              <h2 tabIndex={-1} ref={headingRef}>
                Ups! - Nie odnaleźliśmy Twojej strony
              </h2>
              <p>Niestety podany przez Ciebie adres strony nie istnieje.</p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default NotFound;
