import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { Navigate, Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { login, authorizationAlert, logout } from "../store/user";
import { userCompletedTest } from "../store/report";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import OAuthButton from "./layout/OAuthButton";
import Footer from "./layout/Footer";
import Alert from "./layout/Alert";
import Loader from "./layout/Loader";

import LoadingIcon from "../assets/images/loading-message-icon.gif";

import GoogleLogo from "../assets/images/google-logo.svg";
import FacebookLogo from "../assets/images/fb-logo.svg";

import TogglePasswordIcon from "../assets/images/eye.svg";

import LoginHomeImg from "../assets/images/Login-home-img.png";

const Login = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errorAlertMessages, setErrorAlertMessages] = useState({
    email: "",
    password: "",
  });

  const [showPasswordInput, setShowPasswordInput] = useState(false);

  const headingRef = useRef(null);

  const { email, password } = formData;

  const dispatch = useDispatch();
  const { loading, isAuthenticated, alertMessage } = useSelector(
    (state) => state.user
  );
  const { isTestCompleted } = useSelector((state) => state.report);

  useLayoutEffect(() => {
    document.title = "Zaloguj się | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(authorizationAlert("", null));
  }, []);

  useEffect(() => {
    let email = searchParams.get("email");
    if (email) {
      email = decodeURI(email);
      dispatch(logout());
      dispatch(userCompletedTest(email));
      setFormData({ ...formData, email });
    }
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrorAlertMessages({ ...errorAlertMessages, [e.target.name]: "" });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    dispatch(authorizationAlert("", null));

    setErrorAlertMessages({
      email: "",
      password: "",
    });

    const emailRegex = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );

    if (email === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        email: "Wprowadź adres email",
      }));
    } else if (!emailRegex.test(email)) {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        email:
          "Podaj poprawnie sformatowany adres e-mail. Adres powienien zawierać znak '@'",
      }));
    }

    if (password === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        password: "Wprowadź hasło",
      }));
    }

    if (email !== "" && password !== "" && emailRegex.test(email)) {
      dispatch(login(email, password));
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/report" replace />;
  }

  return (
    <>
      {isTestCompleted || !searchParams.get("email") ? (
        <>
          <Skiplinks />
          <Navbar />
          <main id="content">
            <ContentLogo />
            <div className="l-home-container">
              <div className="c-home c-home--middle-width">
                <div className="c-home__title">
                  <p>
                    Jesteś tylko o krok od poznania swoich mocnych i słabych
                    stron!
                  </p>
                </div>
                <div className="c-home__content">
                  <div className="c-home__box">
                    <img src={LoginHomeImg} alt="" />
                  </div>
                  <div className="c-home__box">
                    <form
                      className="c-form c-form--large-width"
                      onSubmit={onSubmit}
                      noValidate
                    >
                      <h2
                        className="c-form__title"
                        tabIndex={-1}
                        ref={headingRef}
                      >
                        Zaloguj się
                      </h2>
                      {alertMessage ? <Alert /> : null}
                      <label htmlFor="emailInput" className="c-form__label">
                        Adres email*:
                      </label>
                      {searchParams.get("email") ? (
                        <input
                          type="email"
                          id="emailInput"
                          name="email"
                          className={
                            errorAlertMessages.email
                              ? "c-form__input c-form__input--error"
                              : "c-form__input"
                          }
                          value={searchParams.get("email")}
                          onChange={(e) => onChange(e)}
                          disabled
                          required
                          aria-describedby="emailInputDesc"
                        />
                      ) : (
                        <input
                          type="email"
                          id="emailInput"
                          name="email"
                          className={
                            errorAlertMessages.email
                              ? "c-form__input c-form__input--error"
                              : "c-form__input"
                          }
                          value={email}
                          onChange={(e) => onChange(e)}
                          required
                          aria-describedby="emailInputDesc"
                        />
                      )}
                      {errorAlertMessages.email && (
                        <p
                          role="alert"
                          id="emailInputDesc"
                          className="c-form__error-message"
                          aria-atomic="true"
                        >
                          {errorAlertMessages.email}
                        </p>
                      )}
                      <label htmlFor="passwordInput" className="c-form__label">
                        Hasło*:
                      </label>
                      <div className="c-form__group">
                        <input
                          type={showPasswordInput ? "text" : "password"}
                          id="passwordInput"
                          name="password"
                          className={
                            errorAlertMessages.password
                              ? "c-form__input c-form__input--password c-form__input--error"
                              : "c-form__input c-form__input--password"
                          }
                          value={password}
                          onChange={(e) => onChange(e)}
                          required
                          aria-describedby="passwordInputDesc"
                        />
                        <button
                          type="button"
                          className="c-btn-toggle-password"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowPasswordInput(!showPasswordInput);
                          }}
                          aria-label={
                            showPasswordInput ? "Ukryj hasło" : "Pokaż hasło"
                          }
                        >
                          <img src={TogglePasswordIcon} alt="" />
                        </button>
                      </div>
                      {errorAlertMessages.password && (
                        <p
                          role="alert"
                          id="passwordInputDesc"
                          className="c-form__error-message"
                          aria-atomic="true"
                        >
                          {errorAlertMessages.password}
                        </p>
                      )}
                      <Link to="/forgot-password" className="c-form__link">
                        Nie pamiętam hasła :(
                      </Link>
                      <button
                        type="submit"
                        disabled={loading}
                        className="c-btn c-btn--in-icon c-btn--small-size c-btn--blue-color"
                      >
                        {loading ? (
                          <>
                            <img className="c-icon" src={LoadingIcon} alt="" />{" "}
                            Logowanie
                          </>
                        ) : (
                          "Zaloguj się"
                        )}
                      </button>
                      <p className="text--center text--padding-t-1">
                        lub zaloguj się używając konta
                      </p>
                      <div className="c-oath-container">
                        <OAuthButton
                          label="Zaloguj się przy użyciu konta Google"
                          logo={GoogleLogo}
                          type={"google"}
                          handleClick={() =>
                            (window.location.href = `${process.env.REACT_APP_API_URL}/api/connect/google`)
                          }
                        />
                        <OAuthButton
                          label="Zaloguj się przy użyciu konta Facebook"
                          logo={FacebookLogo}
                          type={"facebook"}
                          handleClick={() =>
                            (window.location.href = `${process.env.REACT_APP_API_URL}/api/connect/facebook`)
                          }
                        />
                      </div>
                      <p className="text--center">
                        Nie mam konta{" "}
                        <Link
                          to="/sign-up"
                          className="c-form__link c-form__link--blue"
                        >
                          Zarejestruj się
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </>
      ) : (
        <main id="content">
          <Loader content={"Trwa przygotowanie raportu"} />
        </main>
      )}
    </>
  );
};

export default Login;
