import React, { useLayoutEffect, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import Skiplinks from "./layout/Skiplinks";
import ContentLogo from "./layout/ContentLogo";
import Navbar from "./layout/Navbar";
import DashboardTable from "./layout/DashboardTable";
import Loader from "./layout/Loader";
import Footer from "./layout/Footer";

import RocketIcon from "../assets/images/rocket_icon.png";

import "../styles/Dashboard.css";

const Dashboard = () => {
  const { user } = useSelector((state) => state.user);

  const headingRef = useRef(null);

  const { data, isLoading } = useQuery({
    queryKey: ["coupons_users"],
    queryFn: async () =>
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/coupons/campaign-owner/${user?.id}?populate[claimed_by]`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => response.data)
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
  });

  useLayoutEffect(() => {
    document.title = "Moi klienci | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Skiplinks />
      <Navbar />
      <main id="content">
        <ContentLogo />
        <div className="container">
          {isLoading ? (
            <Loader content={"Trwa przygotowywanie dashboardu"} />
          ) : (
            <div className="l-dashboard-container">
              <h2 className="heading heading-h2" tabIndex={-1} ref={headingRef}>
                Moi klienci <img src={RocketIcon} alt="" />
              </h2>
              <DashboardTable data={data} />
            </div>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Dashboard;
