import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";

import Chatbot from "./layout/Chatbot";

import JobbliLogoIcon from "../assets/images/jobbli-icon-chatbot.svg";

import "../styles/Chatbot.css";

const chatbotContentDOMElement = document.getElementById("chatbot");

const ChatbotButton = () => {
  const { chatbot_mode } = useSelector((state) => state.user);

  const [isActive, setIsActive] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  const navigate = useNavigate();

  const chatbotButtonRef = useRef(null);

  const toggleChat = () => {
    window.location.hash = "";
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (window.location.hash === "#chatbot") {
      navigate(window.location.pathname);
      setIsActive(true);
    }
  }, [window.location.hash, navigate]);

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (rootElement && isActive && width <= 768)
      rootElement.style.display = "none";
    else rootElement.style.display = "block";
  }, [isActive, width]);

  return (
    <>
      {isActive &&
        createPortal(
          <Chatbot
            chatbotButtonRef={chatbotButtonRef}
            close={() => {
              setIsActive(false);

              const rootElement = document.getElementById("root");
              if (rootElement) {
                rootElement.style.display = "block";
              }
            }}
          />,
          chatbotContentDOMElement
        )}
      {chatbot_mode && (
        <button
          className="c-chatbot__btn"
          onClick={toggleChat}
          aria-expanded={isActive ? true : false}
          aria-label={isActive ? "Zamknij chatbota" : "Otwórz chatbota"}
          ref={chatbotButtonRef}
        >
          <span className="c-chatbot__notification">1</span>
          <img src={JobbliLogoIcon} alt="" />
        </button>
      )}
    </>
  );
};

export default ChatbotButton;
