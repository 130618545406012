import React, { useState, useEffect } from "react";

import "../../styles/PasswordStrengthMeter.css";

const PasswordStrengthMeter = ({ password }) => {
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    const strengthChecks = {
      length: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = password.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(password);
    strengthChecks.hasLowerCase = /[a-z]+/.test(password);
    strengthChecks.hasDigit = /[0-9]+/.test(password);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(password);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length === 5
        ? "Tak trzymaj ;)"
        : verifiedList.length >= 3
        ? "Można było to zrobić lepiej"
        : "Potrafisz lepiej ;(";

    setProgress(`${(verifiedList.length / 5) * 100}%`);
    setMessage(strength);
  }, [password]);

  const getActiveColor = (type) => {
    if (type === "Tak trzymaj ;)") return "#35C544";
    if (type === "Można było to zrobić lepiej") return "#EDB201";
    return "#CD1414";
  };

  return (
    <>
      {password.length !== 0 ? (
        <p
          style={{
            color: getActiveColor(message),
            textAlign: "right",
          }}
        >
          {message}
        </p>
      ) : null}
      <div className="c-progress-bg">
        <div
          className="c-progress"
          style={{
            width: progress,
            backgroundColor: getActiveColor(message),
          }}
        ></div>
      </div>
    </>
  );
};

export default PasswordStrengthMeter;
