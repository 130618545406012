import React, { useState, useEffect } from "react";

import OfflineIcon from "../../assets/images/offline.svg";

import "../../styles/OfflineAlertInfo.css";

const OfflineAlertInfo = () => {
  const [isOffline, setIsOffline] = useState(!window.navigator.onLine);

  useEffect(() => {
    const handleOffline = () => {
      setIsOffline(true);
    };
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    isOffline && (
      <div role="alert" className="c-offline-alert">
        <img src={OfflineIcon} alt="" />
        <p>Brak połączenia z siecią. Jobbli jest w trybie offline.</p>
      </div>
    )
  );
};

export default OfflineAlertInfo;
