import React from "react";

import "../../styles/OAuthButton.css";

const OAuthButton = ({ label, logo, type, handleClick }) => {
  return (
    <button
      type="button"
      className={`c-oath__btn c-oath__btn--${type}`}
      onClick={handleClick}
      aria-label={label}
    >
      <img src={logo} alt="" />
    </button>
  );
};

export default OAuthButton;
