import { useState, useEffect } from "react";
import { useAddToHomescreenPrompt } from "../../hooks/useAddToHomescreenPrompt";

import Close from "../../assets/images/close-circle-small.svg";

import Vector1Img from "../../assets/images/vector-1.png";

import "../../styles/AddToHomeScreenButton.css";

const AddToHomeScreenButton = () => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = useState(false);

  const hide = () => {
    setVisibleState(false);
    localStorage.setItem("visibleAddToHomeNotification", false);
  };

  useEffect(() => {
    if (prompt && !localStorage.getItem("visibleAddToHomeNotification")) {
      setVisibleState(true);
    }
  }, [prompt]);

  if (!isVisible) {
    return;
  }

  return (
    <div role="alert" className="c-add-home-notification" onClick={hide}>
      <img className="c-decoration-img" src={Vector1Img} alt="" />
      <button onClick={hide} aria-label="Zamknij">
        <img src={Close} alt="" />
      </button>
      <p>
        Zainstaluj aplikację <strong>Jobbli</strong>, aby mieć informacje o
        dopasowanych zawodach i kursach zawsze pod ręką!
      </p>
      <button className="c-btn-add-home-notification" onClick={promptToInstall}>
        Zainstaluj
      </button>
    </div>
  );
};

export default AddToHomeScreenButton;
