import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  skills: [],
  totalSkills: 0,
};

const careerSlice = createSlice({
  name: "skills",
  initialState,
  reducers: {
    setTotalSkillsList: (state, action) => {
      return {
        ...state,
        totalSkills: action.payload,
      };
    },
    setInitialSkillsList: (state, action) => {
      return {
        ...state,
        skills: action.payload,
      };
    },
    addSkillToUserSkillsList: (state, action) => {
      return {
        ...state,
        skills: state.skills.concat(action.payload),
      };
    },
    removeSkillFromUserSkillsList: (state, action) => {
      return {
        ...state,
        skills: state.skills.filter((item) => item.id !== action.payload),
      };
    },
  },
});

export default careerSlice.reducer;

export const {
  setTotalSkillsList,
  setInitialSkillsList,
  addSkillToUserSkillsList,
  removeSkillFromUserSkillsList,
} = careerSlice.actions;

export const getInitialSkillsList = () => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/users/mySkills`, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
    .then((response) => {
      dispatch(setTotalSkillsList(response.data.length));
      dispatch(setInitialSkillsList(response.data));
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);
    });
};

export const getSkillAndSaveToUserSkillsList =
  (id) => async (dispatch, getState) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/skills/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(async (response) => {
        dispatch(
          addSkillToUserSkillsList({
            id: response.data.data.id,
            ...response.data.data.attributes,
          })
        );

        const currentSkillsLenght = getState().skills.skills.length;
        dispatch(setTotalSkillsList(currentSkillsLenght));

        await axios
          .patch(
            `${process.env.REACT_APP_API_URL}/api/users/mySkills`,
            {
              connect: [response.data.data.attributes.esco_uri],
              disconnect: [],
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
          .catch((error) => {
            //console.error("An error occurred:", error.response);
          });
      })
      .catch((error) => {
        //console.error("An error occurred:", error.response);
      });
  };

export const getSkillAndRemoveFromUserSkillsList =
  (id) => async (dispatch, getState) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/skills/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(async (response) => {
        dispatch(removeSkillFromUserSkillsList(response.data.data.id));

        const currentSkillsLenght = getState().skills.skills.length;
        dispatch(setTotalSkillsList(currentSkillsLenght));

        await axios
          .patch(
            `${process.env.REACT_APP_API_URL}/api/users/mySkills`,
            {
              connect: [],
              disconnect: [response.data.data.attributes.esco_uri],
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
          .catch((error) => {
            //console.error("An error occurred:", error.response);
          });
      })
      .catch((error) => {
        //console.error("An error occurred:", error.response);
      });
  };

export const getCoursesForSkill = async (id) => {
  let courses = [];
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/skills/${id}/courses`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );

    if (response.data.length !== 0) {
      for (const course of response.data) {
        try {
          const courseResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/courses/${course.id}?populate[course_offers][populate]=accessibility_info&populate[for_careers][populate]&populate[for_skills][populate]`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          );
          courses.push(courseResponse.data.data.attributes);
        } catch (error) {
          //console.error("An error occurred:", error.response);
        }
      }
      return courses;
    }
  } catch (error) {
    //console.error("An error occurred:", error.response);
  }
  return courses;
};

export const getCoursesSkillInfo = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/visible-skills/${id}?populate=*`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    return response.data.data.attributes.skill_esco.data;
  } catch (error) {
    //console.error("An error occurred:", error.response);
  }
};

export const getCareerSkills = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/careers/${id}/skills`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    //console.error("An error occurred:", error.response);
  }
};
