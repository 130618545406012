import React from "react";

import JobbliIconFooter from "../../assets/images/jobbli-icon-footer.svg";

import FELogo from "../../assets/images/FE.jpg";
import RPLogo from "../../assets/images/RP.png";
import UEEFSILogo from "../../assets/images/UE_EFSI.jpg";

import "../../styles/Footer.css";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="c-footer">
        <div className="c-footer__info">
          <div className="c-footer-info__box">
            <img src={JobbliIconFooter} alt="" />
          </div>
          <div className="c-footer-info__box">
            <p>Copy (c) {new Date().getFullYear()} POLA.IA spółka z o.o.</p>
          </div>
          <div className="c-footer-info__box">
            <ul>
              <li>
                <a
                  href="https://jobbli.pl/wp-content/uploads/2023/06/Regulamin-uslugi-Jobbli_.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Regulamin
                  <span className="sr-only">
                    (link otworzy się w nowej karcie)
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://jobbli.pl/wp-content/uploads/2024/01/Polityka-prywatnosci-POLA.IA_.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Polityka prywatności
                  <span className="sr-only">
                    (link otwiera się w nowej karcie)
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="c-footer-info__box">
            <div className="c-footer__contact">
              <div className="c-footer-contact__box">
                <p>
                  <strong>Kontakt</strong>
                </p>
                <p>
                  <a href="mailto:contact@jobbli.pl">contact@jobbli.pl</a>
                </p>
              </div>
              <div className="c-footer-contact__box">
                <p>
                  <strong>Kontakt dla mediów</strong>
                </p>
                <p>
                  Alicja Marchewka<br></br>
                  <a href="mailto:alicja.marchewka@jobbli.pl">
                    alicja.marchewka@jobbli.pl
                  </a>
                  <br></br>
                  <a href="tel:+48 570 976 060">+48 570 976 060</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="c-footer__logos">
          <div className="c-footer__logo">
            <img src={FELogo} alt="Fundusze Europejskie" />
          </div>
          <div className="c-footer__logo">
            <img src={RPLogo} alt="Barwy Rzeczypospolitej Polskiej" />
          </div>
          <div className="c-footer__logo">
            <img
              src={UEEFSILogo}
              alt="Unia Europejska z Europejskimi Funduszami Strukturalnymi i Inwestycyjnymi"
            />
          </div>
        </div>
        <div className="c-footer__description">
          <div className="c-footer-description__box">
            <p>
              Przedsiębiorstwo POLA.IA SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ
              otrzymało dofinansowanie na realizację projektu ze środków
              Europejskiego Funduszu Rozwoju Regionalnego w ramach Programu
              Operacyjnego Polska Wschodnia 2014-2020, Poddziałanie 1.1.2 Rozwój
              startupów w Polsce Wschodniej.
            </p>
            <br></br>
            <p>
              <strong>Tytuł projektu:</strong>
            </p>
            <p>
              „Opracowanie aplikacji POLA.AI wykorzystującej metody AI do
              profilowania indywidualnego doradztwa zawodowego i job matching
              dla przedstawicieli generacji Z oraz osób z niepełnosprawnościami”
            </p>
            <br></br>
            <p>
              <strong>Cele projektu:</strong>
            </p>
            <p>
              Głównym celem projektu jest rozwinięcie w makroregionie
              działalności biznesowej przedsiębiorstwa typu startup w oparciu o
              innowacyjny produkt poprzez:
            </p>
            <ul>
              <li>
                zaspokojenie specyficznych potrzeb grupy docelowej produktu:
                pracodawców (B2B), osób z generacji Z wykluczonych lub
                zmagających się z niestabilnością rynku pracy ze względu na
                zmiany technologiczne (B2C) w tym osób niepełnosprawnych (B2C);
              </li>
              <li>
                generowanie przychodów poprzez opracowanie innowacyjnego
                produktu „POLA.AI – wykorzystującej metody AI do profilowania
                indywidualnego doradztwa zawodowego i job matching dla
                przedstawicieli generacji Z oraz osób z niepełnosprawnościami.
              </li>
            </ul>
          </div>
          <div className="c-footer-description__box">
            <p>
              <strong>Planowane efekty:</strong>
            </p>
            <p>
              Efektem realizacji projektu będzie innowacyjny produkt – aplikacja
              POLA.AI wykorzystująca metody AI do profilowania indywidualnego
              doradztwa zawodowego i job matching, stanowiąca kompleksowego,
              kieszonkowego doradcę zawodowego w formie aplikacji mobilnej.
            </p>
            <br></br>
            <p>
              <strong>Wartość projektu:</strong> 1 236 022,73 PLN
            </p>
            <p>
              <strong>Wartość dofinansowania:</strong> 999 182,89 PLN
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
