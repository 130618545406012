import React, { useState } from "react";
import { useSelector } from "react-redux";

import Skill from "./Skill";

import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";

import "../../styles/SkillList.css";

const SkillList = () => {
  const { skills, totalSkills } = useSelector((state) => state.skills);

  const [isActive, setActive] = useState(false);

  return (
    <div className="c-accordion">
      <button
        type="button"
        className={isActive ? "c-accordion__btn active" : "c-accordion__btn"}
        onClick={() => setActive(!isActive)}
        aria-expanded={isActive ? true : false}
      >
        <h3 className="c-accordion__title--skills-list">Twoje umiejętności</h3>
        <img src={isActive ? ArrowUp : ArrowDown} alt="" />
      </button>
      <div
        className={
          isActive
            ? "c-accordion__content c-accordion__content--skills-list open"
            : "c-accordion__content"
        }
      >
        {totalSkills !== 0 ? (
          <>
            <p>Umiejętności oznaczone w zawodach jako znane</p>
            <div className="c-skills-list">
              <ul>
                {skills.map((item) => (
                  <li key={item.id}>
                    <Skill {...item} />
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <p>
            Oznacz znane kompetencje na liście zawodów lub poznaj je korzystając
            z kursów, aby to miejsce nie świeciło pustką.
          </p>
        )}
      </div>
    </div>
  );
};

export default SkillList;
