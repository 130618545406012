import { useState, useEffect, useRef } from "react";

import FiltersIcon from "../../assets/images/filters.svg";

import "../../styles/DropdownCheckboxList.css";

const PROFILE_FILTERS_NAMES = {
  profileHearing: "osób z zaburzeniami głosu i słuchu",
  profileSight: "osób z dysfunkcją wzroku",
  profileMovement: "osób z zaburzeniem naturalnej ruchomości",
};

const DropdownCheckboxList = ({ PROFILE_FILTERS, onProfileChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const dropdownButtonRef = useRef(null);
  const dropdownRef = useRef(null);
  const firstCheckboxRef = useRef(null);

  const toggleOptions = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      setTimeout(() => {
        firstCheckboxRef.current?.focus();
      }, 0);
    }
  };

  const closeOptions = () => {
    setIsOpen(false);

    setTimeout(() => {
      dropdownButtonRef.current?.focus();
    }, 0);
  };

  const handleProfileChange = (profileId) => {
    const updatedProfiles = selectedProfiles.includes(profileId)
      ? selectedProfiles.filter((id) => id !== profileId)
      : [...selectedProfiles, profileId];

    setSelectedProfiles(updatedProfiles);
    onProfileChange(updatedProfiles);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();

      const checkboxes = Array.from(
        dropdownRef.current?.querySelectorAll(".c-dropdown__checkbox")
      );
      const currentIndex = checkboxes.findIndex(
        (checkbox) => checkbox === document.activeElement
      );
      let nextIndex;

      if (e.key === "ArrowDown") {
        nextIndex = (currentIndex + 1) % checkboxes.length;
      } else {
        nextIndex = (currentIndex - 1 + checkboxes.length) % checkboxes.length;
      }

      checkboxes[nextIndex].focus();
    } else if (e.key === "Escape") {
      closeOptions();
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        dropdownButtonRef.current &&
        !dropdownButtonRef.current?.contains(e.target) &&
        dropdownRef.current &&
        !dropdownRef.current?.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="c-dropdown-container">
        <button
          type="button"
          className="c-dropdown__btn"
          aria-label="Filtruj"
          aria-expanded={isOpen}
          onClick={toggleOptions}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              toggleOptions();
            }
          }}
          ref={dropdownButtonRef}
        >
          <span>Filtruj</span>
          <img src={FiltersIcon} alt="" />
        </button>
        <ul
          className={`c-dropdown__list ${isOpen ? "expanded" : ""}`}
          ref={dropdownRef}
        >
          <li
            className="c-dropdown__header"
            tabIndex={0}
            ref={firstCheckboxRef}
          >
            pokazuj tylko zawody zalecane dla:
          </li>
          {Object.entries(PROFILE_FILTERS).map(([key, value]) => (
            <li
              key={key}
              className={
                selectedProfiles.includes(key)
                  ? "c-dropdown__item c-dropdown__item--checked"
                  : "c-dropdown__item"
              }
            >
              <input
                type="checkbox"
                id={key}
                name={key}
                className="c-dropdown__checkbox"
                onChange={() => handleProfileChange(key)}
                onKeyDown={handleKeyDown}
                checked={selectedProfiles.includes(key)}
              />
              <label htmlFor={key} className="c-dropdown__label">
                {PROFILE_FILTERS_NAMES[key]}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default DropdownCheckboxList;
