import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSkillAndSaveToUserSkillsList,
  getSkillAndRemoveFromUserSkillsList,
} from "../../store/skills";

import "../../styles/Skill.css";

const Skill = ({ id, preferred_label }) => {
  const dispatch = useDispatch();
  const { skills } = useSelector((state) => state.skills);

  const [isChecked, setChecked] = useState(
    skills.some((item) => item.id === id)
  );

  const skillRef = useRef(null);

  const handleSkillStatus = (id, checked) => {
    if (checked) {
      dispatch(getSkillAndSaveToUserSkillsList(id));
    } else {
      dispatch(getSkillAndRemoveFromUserSkillsList(id));
    }
  };

  useEffect(() => {
    setChecked(skills.some((item) => item.id === id));
  }, [skills, id]);

  return (
    <div className={isChecked ? "c-skill c-skill--checked" : "c-skill"}>
      <input
        type="checkbox"
        id={`skill-${id}`}
        name={`skill-${id}`}
        className="c-skill__checkbox"
        checked={isChecked}
        ref={skillRef}
        onChange={(e) => {
          handleSkillStatus(id, e.target.checked);
          setChecked(e.target.checked);
        }}
        onKeyDown={(e) => {
          handleSkillStatus(id, e.target.checked);
          setChecked(e.target.checked);
        }}
      />
      <label htmlFor={`skill-${id}`} className="c-skill__label">
        {preferred_label}
      </label>
    </div>
  );
};

export default Skill;
