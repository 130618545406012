import React, { useLayoutEffect, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import Footer from "./layout/Footer";
import SkillList from "./layout/SkillList";
import FavoriteCareerSkillsAndCourses from "./layout/FavoriteCareerSkillsAndCourses";
import NoFavoriteCareersSkillsInfo from "./layout/NoFavoriteCareersSkillsInfo";

import ChatbotButton from "./ChatbotButton";

import FlexedBicepsIcon from "../assets/images/flexed-biceps_icon.png";
import SparklesIcon from "../assets/images/sparkles_icon.png";
import MagnifyingGlassIcon from "../assets/images/magnifying-glass_icon.png";
import LightBulbIcon from "../assets/images/light-bulb_icon.png";

import Vector5Img from "../assets/images/vector-5.png";

import Infinity from "../assets/images/infinity.svg";

import "../styles/Skills.css";

const Skills = () => {
  const { favorite } = useSelector((state) => state.careers);
  const { totalSkills } = useSelector((state) => state.skills);

  const headingRef = useRef(null);

  useLayoutEffect(() => {
    document.title =
      "Umiejętności i kursy | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Skiplinks isChatbot />
      <Navbar />
      <main id="content">
        <ContentLogo />
        <div className="l-skills-container">
          <div className="c-skills">
            <div className="c-skills__box">
              <div className="c-skills__content">
                <div className="c-skills__content-box">
                  <h2
                    className="heading heading-h2"
                    tabIndex={-1}
                    ref={headingRef}
                  >
                    Umiejętności i kursy <img src={FlexedBicepsIcon} alt="" />
                  </h2>
                  <p>
                    Zebrałem dla Ciebie informacje na temat umiejętności
                    niezbędnych do podjęcia pracy w polubionych przez Ciebie
                    zawodach przyszłości.<br></br>Sprawdź w jaki sposób możesz
                    rozwinąć swoje zdolności za pomocą proponowanych kursów i
                    szkoleń. Do dzieła!
                  </p>
                </div>
                <div className="c-skills__content-box">
                  <div className="c-skills__info">
                    <img className="c-decoration-img" src={Vector5Img} alt="" />
                    <p className="c-skills__info-title">
                      {true ? (
                        <>
                          {`${totalSkills}/`}
                          <img src={Infinity} alt="Symbol nieskończoności" />
                        </>
                      ) : (
                        <Skeleton />
                      )}
                    </p>
                    <p className="c-skills__info-subtitle">
                      Twoje umiejętności{" "}
                      <img className="c-icon" src={SparklesIcon} alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="c-skills__box">
              <h3 className="heading heading-h3">
                To, co już masz <img src={MagnifyingGlassIcon} alt="" />
              </h3>
              <p>Umiejętności oznaczone w zawodach jako znane</p>
              <SkillList />
            </div>
            <div className="c-skills__box">
              <h3 className="heading heading-h3">
                Polecane kursy Twoich polubionych zawodów
                <img src={LightBulbIcon} alt="" />
              </h3>
              <div className="c-skills__list">
                {favorite.length !== 0 ? (
                  favorite.map((item) => (
                    <FavoriteCareerSkillsAndCourses key={item.id} {...item} />
                  ))
                ) : (
                  <NoFavoriteCareersSkillsInfo />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <ChatbotButton />
      <Footer />
    </>
  );
};

export default Skills;
