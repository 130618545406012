import React, { useLayoutEffect, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Fragment } from "react";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import Footer from "./layout/Footer";
import Loader from "./layout/Loader";

import ChatbotButton from "./ChatbotButton";

import Union from "../assets/images/Union.png";
import Star1 from "../assets/images/Star-1.png";
import Star4 from "../assets/images/Star-4.png";

import BriefcaseIcon from "../assets/images/briefcase_icon.png";
import DrumIcon from "../assets/images/drum_icon.png";
import MagnifyingGlassIcon from "../assets/images/magnifying-glass_icon.png";
import CrownIcon from "../assets/images/crown_icon.png";
import BarChartIcon from "../assets/images/bar-chart_icon.png";
import PencilIcon from "../assets/images/pencil_icon.png";
import MemoIcon from "../assets/images/memo_icon.png";
import RocketIcon from "../assets/images/rocket_icon.png";
import SmilingFaceWithSunglassesIcon from "../assets/images/smiling-face-with-sunglasses_icon.png";
import SpeakNoEvilMonkeyIcon from "../assets/images/speak-no-evil-monkey_icon.png";
import StarStruckIcon from "../assets/images/star-struck_icon.png";
import SparklesIcon from "../assets/images/sparkles_icon.png";
import WomenWithBunnyEarsIcon from "../assets/images/women-with-bunny-ears_icon.png";

import ReportImg from "../assets/images/Report-img.png";

import Vector1Img from "../assets/images/vector-1.png";
import Vector2Img from "../assets/images/vector-2.png";
import Vector5Img from "../assets/images/vector-5.png";

import JobbliReportIcon from "../assets/images/jobbli-report.svg";
import DiscordLogo from "../assets/images/discord-logo.svg";

import "../styles/Report.css";

const RIASEC_PROFILE_PL_NAMES = {
  R: "Przedmiotowy",
  I: "Innowacyjny",
  A: "Kreatywny",
  S: "Społeczny",
  E: "Liderski",
  C: "Metodyczny",
};

const RIASEC_PROFILE_OPTIONS = {
  R: {
    color: "#6F8EFF",
    icon: MagnifyingGlassIcon,
  },
  I: {
    color: "#8C6FFE",
    icon: RocketIcon,
  },
  A: {
    color: "#FFD362",
    icon: PencilIcon,
  },
  S: {
    color: "#F67090",
    icon: SmilingFaceWithSunglassesIcon,
  },
  E: {
    color: "#F8673A",
    icon: CrownIcon,
  },
  C: {
    color: "#73AB51",
    icon: BarChartIcon,
  },
};

const ANSWERS_STATISTICS = {
  R: [4.4, 11.5, 13.8, 13.5, 12.3, 11.2, 10.8, 9.4, 7.3, 4.7, 1.3],
  I: [0.1, 0.5, 1.5, 3.8, 7.7, 12.4, 17.4, 20.3, 18.3, 12.4, 5.6],
  A: [0.3, 1.5, 3.8, 7.3, 10.4, 13.8, 15.8, 16.8, 14.6, 10.1, 5.7],
  S: [0.9, 1.9, 3.6, 6.0, 8.7, 11.6, 14.1, 15.9, 15.5, 13.1, 8.6],
  E: [0.6, 2.1, 4.1, 5.9, 7.4, 9.3, 11.1, 13.4, 16.3, 17.2, 12.7],
  C: [0.4, 1.2, 2.3, 4.1, 6.7, 10.3, 14.1, 17.2, 19.9, 16.8, 7.1],
};

const Report = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);
  const {
    riasecProfile,
    riasecFirstProfileName,
    riasecSecondProfileName,
    riasecScore,
    riasecInfo,
    profileWeakness,
  } = useSelector((state) => state.report);
  const { matched, totalCareers, totalMatchedCareers } = useSelector(
    (state) => state.careers
  );

  const headingRef = useRef(null);

  const chartData = {
    series: [
      {
        name: "Wynik testu dopasowania predyspozycji zawodowych dla poszczególnych profili",
        data:
          riasecScore &&
          Object.values(riasecScore).slice(1, riasecScore.lenght),
      },
    ],
    options: {
      chart: {
        type: "radar",
        fontFamily: "AtkinsonHyperlegible-Bold",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "24px",
          fontFamily: "AtkinsonHyperlegible-Bold",
          fontWeight: "bold",
          colors: ["#161623"],
        },
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: "#FFFFFF88",
            fill: {
              colors: ["#161623", "#161623"],
            },
          },
        },
      },
      title: {
        text: undefined,
      },
      colors: ["#FFFFFF"],
      markers: {
        size: 4,
        colors: ["#161623"],
        strokeColor: "#FFFFFF",
        strokeWidth: 2,
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: Object.values(RIASEC_PROFILE_PL_NAMES),
        labels: {
          show: true,
          style: {
            colors: [
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
            ],
            fontSize: "18px",
            fontFamily: "AtkinsonHyperlegible-Bold",
          },
        },
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 12,
        labels: {
          style: {
            colors: "#FFFFFF",
            fontSize: "18x",
            fontFamily: "AtkinsonHyperlegible-Bold",
          },
          formatter: (val, i) => {
            if (i % 2 === 0) {
              return val;
            } else {
              return "";
            }
          },
        },
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              height: 500,
            },
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "18px",
                fontFamily: "AtkinsonHyperlegible-Bold",
                fontWeight: "bold",
                colors: ["#161623"],
              },
            },
            plotOptions: {
              radar: {
                size: 160,
              },
            },
            xaxis: {
              categories: Object.values(RIASEC_PROFILE_PL_NAMES).map(
                ([v]) => v
              ),
              labels: {
                show: true,
                tickPlacement: "on",
                style: {
                  colors: Object.values(RIASEC_PROFILE_OPTIONS).map(
                    (v) => v.color
                  ),
                  fontSize: "20px",
                  fontFamily: "AtkinsonHyperlegible-Bold",
                },
              },
            },
          },
        },
      ],
    },
  };

  const getKeyByValue = (object, value) => {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        if (object[prop] === value) return prop.toLowerCase();
      }
    }
  };

  const { refetch } = useQuery({
    queryKey: ["visitlog", user?.id],
    queryFn: async () =>
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/visitlog?uid=${user?.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
    enabled: false,
  });

  useLayoutEffect(() => {
    document.title = "Raport | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });

    refetch();
  }, []);

  return (
    <>
      <Skiplinks isChatbot />
      <Navbar />
      <main id="content">
        <ContentLogo />
        <div className="container">
          {riasecProfile && riasecScore ? (
            <>
              <div className="l-report-container">
                <div className="c-report-welcome-container">
                  <div className="c-report-welcome-box">
                    <h2
                      className="heading heading--weight-400 heading-h2"
                      tabIndex={-1}
                      ref={headingRef}
                    >
                      Hej {user?.username}!<br></br>
                      <span className="heading--color-underline heading--blue-color-underline">
                        Super, że jesteś!
                      </span>
                      <br></br>Przed Tobą<br></br>
                      <span className="heading--weight-700 heading--blue-color">
                        profil zawodowy
                      </span>
                    </h2>
                    <p>
                      Na jego podstawie opracowuję dla Ciebie rekomendacje
                      zawodów i plan kariery, który najlepiej wykorzysta Twój
                      potencjał.<br></br> Poznaj co Cię wyróżnia na rynku
                      digital!{" "}
                      <img className="c-icon" src={SparklesIcon} alt="" />
                    </p>
                  </div>
                  <div className="c-report-welcome-box">
                    <img src={ReportImg} alt="" />
                  </div>
                </div>
                <div className="c-report-profile">
                  <h3 className="heading heading-h3">
                    Profil zawodowy:{" "}
                    <span className="heading--weight-400 heading--color-underline heading--blue-color-underline">
                      {riasecFirstProfileName} - {riasecSecondProfileName}
                    </span>{" "}
                    <img src={BriefcaseIcon} alt="" />
                  </h3>
                  <div className="c-report-profile-container">
                    <div className="c-report-profile-box">
                      <img
                        className="c-decoration-img"
                        src={Vector1Img}
                        alt=""
                      />
                      <div>
                        <p>
                          Twój dominujący profil to...{" "}
                          <img className="c-icon" src={DrumIcon} alt="" />
                          <br></br>
                          <span>
                            {riasecFirstProfileName} - {riasecSecondProfileName}
                          </span>
                        </p>
                      </div>
                      <div></div>
                    </div>
                    <div className="c-report-profile-box" aria-hidden={true}>
                      <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="radar"
                        height={750}
                      />
                    </div>
                    {Object.keys(RIASEC_PROFILE_PL_NAMES).map((key) => (
                      <div
                        key={key}
                        className={`c-report-profile-box c-report-profile-box-${key.toLowerCase()}`}
                      >
                        <img
                          className="c-decoration-img"
                          src={Vector1Img}
                          alt=""
                        />
                        <p className="c-report-profile-box__title">
                          {RIASEC_PROFILE_PL_NAMES[key]}
                        </p>
                        <div
                          className={`c-report-profile-box__value c-report-profile-box__value-${key.toLowerCase()}`}
                        >
                          <img
                            className="c-icon"
                            src={RIASEC_PROFILE_OPTIONS[key].icon}
                            alt=""
                          />
                          <p>{riasecScore[key]} pkt.</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="c-report-profile-description">
                  <h3 className="heading heading-h3">
                    Więcej o profilu:{" "}
                    <span className="heading--weight-400 heading--color-underline heading--blue-color-underline">
                      {riasecFirstProfileName}
                    </span>{" "}
                    <img src={RocketIcon} alt="" />
                  </h3>
                  <div className="c-report-profile-description-container">
                    <div
                      className={`c-report-profile-description-box c-report-profile-description-box-${getKeyByValue(
                        RIASEC_PROFILE_PL_NAMES,
                        riasecFirstProfileName
                      )}`}
                    >
                      <img
                        className="c-decoration-img"
                        src={Vector1Img}
                        alt=""
                      />
                      <p>
                        aż<br></br>
                        <span>
                          {(
                            (riasecScore[
                              Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                (key) =>
                                  RIASEC_PROFILE_PL_NAMES[key] ===
                                  riasecFirstProfileName
                              )
                            ] /
                              (Object.values(riasecScore).reduce(
                                (a, b) => a + b
                              ) -
                                riasecScore["id"])) *
                            100
                          ).toFixed(1)}
                          %
                        </span>
                        <br></br>
                        Twoich predyspozycji odwołuje się do profilu znanego
                        jako <strong>{riasecFirstProfileName}!</strong>{" "}
                        <img
                          className="c-icon"
                          src={SmilingFaceWithSunglassesIcon}
                          alt=""
                        />
                      </p>
                    </div>
                    <div className="c-report-profile-description-box">
                      <img
                        className="c-decoration-img"
                        src={Vector2Img}
                        alt=""
                      />
                      <p>
                        tylko<br></br>
                        <span>
                          {riasecFirstProfileName &&
                            ANSWERS_STATISTICS[
                              Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                (key) =>
                                  RIASEC_PROFILE_PL_NAMES[key] ===
                                  riasecFirstProfileName
                              )
                            ][
                              riasecScore[
                                Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                  (key) =>
                                    RIASEC_PROFILE_PL_NAMES[key] ===
                                    riasecFirstProfileName
                                )
                              ]
                            ]}
                          %
                        </span>
                        <br></br>
                        użytkowników zdobyło tak wysoki wynik w tej
                        predyspozycji!{" "}
                        <img className="c-icon" src={StarStruckIcon} alt="" />
                      </p>
                    </div>
                    <div
                      className={`c-report-profile-description-box c-report-profile-description-box-${getKeyByValue(
                        RIASEC_PROFILE_PL_NAMES,
                        riasecFirstProfileName
                      )}`}
                    >
                      <img src={JobbliReportIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div className="c-report-characteristics">
                  <h3 className="heading heading-h3">
                    6 Twoich cech <img src={SparklesIcon} alt="" />
                  </h3>
                  <p className="heading--weight-400 heading--small-size">
                    które nakierują Cię na właściwy wybór zawodowy
                  </p>
                  <div className="c-report-characteristics-container">
                    {riasecInfo[0] &&
                      riasecInfo[0].character.map((data, index) => (
                        <div
                          key={data.id}
                          className="c-report-characteristic-box"
                        >
                          <div className="c-report-characteristic-box__index">
                            <p>{index + 1}</p>
                          </div>
                          <div className="c-report-characteristic-box__content">
                            <p>{data}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="c-report-profile-description">
                  <h3 className="heading heading-h3">
                    Więcej o profilu:{" "}
                    <span className="heading--weight-400 heading--color-underline heading--blue-color-underline">
                      {riasecSecondProfileName}
                    </span>{" "}
                    <img src={RocketIcon} alt="" />
                  </h3>
                  <div className="c-report-profile-description-container">
                    <div
                      className={`c-report-profile-description-box c-report-profile-description-box-${getKeyByValue(
                        RIASEC_PROFILE_PL_NAMES,
                        riasecSecondProfileName
                      )}`}
                    >
                      <img
                        className="c-decoration-img"
                        src={Vector1Img}
                        alt=""
                      />
                      <p>
                        aż<br></br>
                        <span>
                          {(
                            (riasecScore[
                              Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                (key) =>
                                  RIASEC_PROFILE_PL_NAMES[key] ===
                                  riasecSecondProfileName
                              )
                            ] /
                              (Object.values(riasecScore).reduce(
                                (a, b) => a + b
                              ) -
                                riasecScore["id"])) *
                            100
                          ).toFixed(1)}
                          %
                        </span>
                        <br></br>
                        Twoich predyspozycji odwołuje się do profilu znanego
                        jako <strong>{riasecSecondProfileName}!</strong>{" "}
                        <img
                          className="c-icon"
                          src={SmilingFaceWithSunglassesIcon}
                          alt=""
                        />
                      </p>
                    </div>
                    <div className="c-report-profile-description-box">
                      <img
                        className="c-decoration-img"
                        src={Vector2Img}
                        alt=""
                      />
                      <p>
                        tylko<br></br>
                        <span>
                          {riasecSecondProfileName &&
                            ANSWERS_STATISTICS[
                              Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                (key) =>
                                  RIASEC_PROFILE_PL_NAMES[key] ===
                                  riasecSecondProfileName
                              )
                            ][
                              riasecScore[
                                Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                  (key) =>
                                    RIASEC_PROFILE_PL_NAMES[key] ===
                                    riasecSecondProfileName
                                )
                              ]
                            ]}
                          %
                        </span>
                        <br></br>
                        użytkowników zdobyło tak wysoki wynik w tej
                        predyspozycji!{" "}
                        <img className="c-icon" src={StarStruckIcon} alt="" />
                      </p>
                    </div>
                    <div
                      className={`c-report-profile-description-box c-report-profile-description-box-${getKeyByValue(
                        RIASEC_PROFILE_PL_NAMES,
                        riasecSecondProfileName
                      )}`}
                    >
                      <img src={JobbliReportIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div className="c-report-characteristics">
                  <h3 className="heading heading-h3">
                    6 Twoich cech <img src={SparklesIcon} alt="" />
                  </h3>
                  <p className="heading--weight-400 heading--small-size">
                    które nakierują Cię na właściwy wybór zawodowy
                  </p>
                  <div className="c-report-characteristics-container">
                    {riasecInfo[1] &&
                      riasecInfo[1].character.map((data, index) => (
                        <div
                          key={data.id}
                          className="c-report-characteristic-box"
                        >
                          <div className="c-report-characteristic-box__index">
                            <p>{index + 1}</p>
                          </div>
                          <div className="c-report-characteristic-box__content">
                            <p>{data}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="c-report-developments">
                  {profileWeakness !== "none" && (
                    <Fragment>
                      <h3 className="heading heading-h3">
                        Obszary do rozwoju:{" "}
                        <span className="heading--weight-400 heading--color-underline heading--blue-color-underline">
                          {profileWeakness === "organizacja"
                            ? "Organizacja czasu"
                            : profileWeakness === "samoocena"
                            ? "Zaniżona pewność siebie"
                            : profileWeakness === "stres"
                            ? "Radzenie sobie ze stresem"
                            : profileWeakness === "odkladanie"
                            ? "Odkładanie rzeczy na później"
                            : "Zaniżona pewność siebie"}
                        </span>{" "}
                        <img src={SpeakNoEvilMonkeyIcon} alt="" />
                      </h3>
                      {/* Rest of the development section */}
                      <div className="c-report-developments-container">
                        <div className="c-report-development-box">
                          <p className="c-report-development__title">Ryzyka:</p>
                          <ul>
                            <li>
                              {profileWeakness === "organizacja"
                            ? "Wraz ze zbliżającym  się deadlinem możesz odczuwać obniżony nastrój, stres, poczucie winy oraz napięcie."
                            : profileWeakness === "samoocena"
                            ? "Nieobiektywna, zaniżona ocena swoich umiejętności i talentów może utrudnić skuteczną autoprezentację w trakcie np. rozmowy kwalifikacyjnej."
                            : profileWeakness === "stres"
                            ? "Permanentny stres jest jedną z głównych przyczyn wypalenia zawodowego, który został włączony do międzynarodowej klasyfikacji chorób ICD-11."
                            : profileWeakness === "odkladanie"
                            ? "Gromadzenie zadań na później prowadzi do nagromadzenia obowiązków i silnego stresu tuż przed terminem ich realizacji. Może to wpłynąć negatywnie na jakość wykonywanej pracy, prowadząc do błędów wynikających z pośpiechu i braku dokładności."
                            : "Nieobiektywna, zaniżona ocena swoich umiejętności i talentów może utrudnić skuteczną autoprezentację w trakcie np. rozmowy kwalifikacyjnej."}
                            </li>
                            <li>
                              {profileWeakness === "organizacja"
                            ? "Możemy mierzyć się z problemami relacyjnymi w pracy zarówno z przełożonym jak i ze współpracownikami. Może to też zmniejszyć nasze szanse na awans."
                            : profileWeakness === "samoocena"
                            ? "Mniejsza odwaga do angażowania się w ciekawe i rozwojowe projekty."
                            : profileWeakness === "stres"
                            ? "Trwały stres może prowadzić do uczucia wyczerpania, niepokoju i zmniejszonej efektywności jak i poważnych chorób serca, cukrzycy czy insomnii."
                            : profileWeakness === "odkladanie"
                            ? "Codzienne życie może stać się chaotyczne i nieuporządkowane, co prowadzi do frustracji i poczucia braku kontroli nad czasem."
                            : "Mniejsza odwaga do angażowania się w ciekawe i rozwojowe projekty."}
                            </li>
                          </ul>
                        </div>
                        <div className="c-report-development-box">
                          <p className="c-report-development__title"> Co możesz z tym zrobić? </p>
                          <ul>
                            <li>
                              {profileWeakness === "organizacja"
                            ? "Minimalizuj  “rozpraszacze” - zacznij kontrolować ilość i częstotliwość korzystania np. z mediów społecznościowych, które skutecznie potrafią odciągnąć od realizacji zadania!"
                            : profileWeakness === "samoocena"
                            ? "Pierwszy krok to uświadomienie sobie, że to nie obiektywna ocena Twoich możliwości, a zaniżone poczucie wartości może dyktować Twoje zachowania i postawy."
                            : profileWeakness === "stres"
                            ? "Zwróć większą uwagę na dostosowanie pracy, zadań i warunków jej wykonywania do własnych psychofizycznych i fizjologicznych potrzeb."
                            : profileWeakness === "odkladanie"
                            ? "Wykorzystaj techniki zarządzania czasem, takie jak metoda Pomodoro, aby skoncentrować się na zadaniach przez krótkie, intensywne okresy, po których następują krótkie przerwy. Możesz też zastosować system nagród, aby zmotywować się do wcześniejszego rozpoczęcia pracy nad zadaniami."
                            : "Pierwszy krok to uświadomienie sobie, że to nie obiektywna ocena Twoich możliwości, a zaniżone poczucie wartości może dyktować Twoje zachowania i postawy."}
                            </li>
                            <li>
                              {profileWeakness === "organizacja"
                            ? "Postaraj się wyznaczać sobie mniejsze cele, które stopniowo będą Cię przybliżać do realizacji większego zadania."
                            : profileWeakness === "samoocena"
                            ? "Zwróć uwagę na komunikację z samym sobą, czy język jakiego używasz jest nacechowany negatywnie?"
                            : profileWeakness === "stres"
                            ? (
                                <>
                                  Jeśli odczuwasz symptomy wypalenia zawodowego niezwłocznie zgłoś się o pomoc do lekarza lub napisz do nas maila na <a href="mailto:contact@jobbli.pl" target="_blank">contact@jobbli.pl</a>, a my przekierujemy Cię do sprawdzonych specjalistów.
                                </>
                              )
                            : profileWeakness === "odkladanie"
                            ? "Regularnie monitoruj swoje postępy i dostosowuj planowanie oraz techniki, aby znaleźć najbardziej skuteczne dla Ciebie metody zarządzania czasem."
                            : "Zwróć uwagę na komunikację z samym sobą, czy język jakiego używasz jest nacechowany negatywnie?"}
                            </li>
                              {profileWeakness === "organizacja"
                            ? (
                                <>
                                  <li><a href="https://jobbli.typeform.com/to/LC2CekKW" target="_blank">Zapytaj o pomoc naszych job coachów!</a></li>
                                </>
                              )
                            : profileWeakness === "samoocena"
                            ? (
                                <>
                                  <li><a href="https://jobbli.typeform.com/to/LC2CekKW" target="_blank">Porozmawiaj z naszymi psychologami i job coachami.</a></li>
                                </>
                              )

                            : profileWeakness === "odkladanie"
                            ? (
                                <>
                                  <li><a href="https://jobbli.typeform.com/to/LC2CekKW" target="_blank">Porozmawiaj z naszymi psychologami i job coachami.</a></li>
                                </>
                              )
                            : (
                                <>
                                  <li><a href="https://jobbli.typeform.com/to/LC2CekKW" target="_blank">Porozmawiaj z naszymi psychologami i job coachami.</a></li>
                                </>
                              )}
                          </ul>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
                <div className="c-report-cv">
                  <h3 className="heading heading-h3">
                    Jak przełożyć tę wiedzę na Twoje CV?{" "}
                    <img src={MemoIcon} alt="" />
                  </h3>
                  <div className="c-report-cv-advices-container">
                    <div className="c-report-cv-advice-box">
                      <img id="star-4" src={Star1} alt="" />
                      <p>
                        Rozpocznij od słów oznaczających działanie, np.
                        zorganizowałem, zaprezentowałem.
                      </p>
                    </div>
                    <div className="c-report-cv-advice-box">
                      <img id="union" src={Union} alt="" />
                      <p>
                        Nie mów o tym, za co byłeś odpowiedzialny,<br></br>a co
                        osiągnąłeś.
                      </p>
                    </div>
                    <div className="c-report-cv-advice-box">
                      <img id="star-1" src={Star4} alt="" />
                      <p>
                        Struktura każdego zdania powinna zawierać rezultat
                        działań, drogę do jego osiągnięcia oraz jego wpływ.
                      </p>
                    </div>
                  </div>
                  <h3 className="heading heading-h3">
                    Przykłady: <img src={PencilIcon} alt="" />
                  </h3>
                  <div className="c-report-cv-advices-example-container">
                    <div className="c-report-cv-advices-example-box">
                      {riasecInfo[0] && (
                        <>
                          <p>
                            {riasecInfo[0].strong_suit}: {riasecInfo[0].desc}
                          </p>
                          <p>
                            <em>{riasecInfo[0].cv_entry}</em>
                          </p>
                        </>
                      )}
                    </div>
                    <div className="c-report-cv-advices-example-box">
                      {riasecInfo[1] && (
                        <>
                          <p>
                            {riasecInfo[1].strong_suit}: {riasecInfo[1].desc}
                          </p>
                          <p>
                            <em>{riasecInfo[1].cv_entry}</em>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="c-report-careers">
                  <h3 className="heading heading-h3">
                    Jaki zawód pasuje do Ciebie najlepiej?{" "}
                    <img src={StarStruckIcon} alt="" />
                  </h3>
                  <p>
                    Nasz algorytm zweryfikował, że pasuje do Ciebie{" "}
                    <span className="heading--weight-bold">
                      {totalMatchedCareers} zawodów
                    </span>{" "}
                    z naszej bazy {totalCareers} zawodów przyszłości.<br></br>
                    Zawody pasujące do Ciebie to między innymi{" "}
                    <span className="heading--weight-bold">
                      {matched[0]?.name_en}
                    </span>
                    ,{" "}
                    <span className="heading--weight-bold">
                      {matched[1]?.name_en}
                    </span>{" "}
                    czy{" "}
                    <span className="heading--weight-bold">
                      {matched[2]?.name_en}
                    </span>
                    .<br></br>
                    Wśród naszej listy brakuje jedynie zawodów miłosnych.
                  </p>
                  <button
                    className="c-btn c-btn--in-icon c-btn--regular-size c-btn--blue-color c-btn--100-w"
                    onClick={() => navigate("/careers-list")}
                  >
                    <img src={BriefcaseIcon} alt="" /> Lista zawodów
                  </button>
                </div>
              </div>
              <div className="l-report-discord">
                <div className="c-report-discord">
                  <img className="c-decoration-img" src={Vector5Img} alt="" />
                  <h3 className="heading heading-h3">
                    Wciąż szukasz więcej?{" "}
                    <img src={SparklesIcon} alt="" />
                  </h3>
                  <p>
                    Skonsultuj swój raport z naszym doradcą kariery, który pomoże Ci odkryć stojące przed Tobą możliwości
                    i ustalić krok po kroku ścieżkę rozwoju Twojej kariery. Nasi doradcy to nie tylko źródło fachowej wiedzy
                    o rynku i eksperci w poruszaniu się po nim, ale także specjaliści w zakresie wsparcia w wypaleniu
                    zawodowym i rozwoju kompetencji społecznych przydatnych w każdej pracy.</p>
                  <button
                    className="c-btn c-btn--in-icon c-btn--regular-size c-btn--dark-blue-color c-btn--100-w"
                    onClick={() =>
                      window.open(
                        `/api/outlink?uid=${user?.id}&url=https://konsultacjejobbli.youcanbook.me/`,
                        "_blank"
                      )
                    }
                  >
                    Skonsultuj swoje wyniki
                    <span className="sr-only">
                      (link otwiera się w nowej karcie)
                    </span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <Loader
              type={"raport"}
              content={"Trwa przygotowanie raportu"}
              noDataContent={[
                "Wypełnij test lub połącz ponownie raport z kontem i dowiedz się, jaka praca da Ci najwięcej satysfakcji!",
                "Jesteś o krok od poznania idealnie dopasowanej kariery!",
              ]}
            />
          )}
        </div>
      </main>
      <ChatbotButton />
      <Footer />
    </>
  );
};

export default Report;
