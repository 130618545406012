import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import user from "./user";
import report from "./report";
import careers from "./careers";
import skills from "./skills";

const persistConfig = {
  key: "root",
  storage,
};

/* The `combineReducers` function is used to combine multiple reducers into a single reducer function.
In this case, it is combining the `user` reducer into a single reducer called `reducer`. This allows
the Redux store to handle multiple slices of state, each managed by a separate reducer. */
const reducer = combineReducers({
  user,
  report,
  careers,
  skills,
});

const persistedReducer = persistReducer(persistConfig, reducer);

/* The code is creating a Redux store using the `configureStore` function from the `@reduxjs/toolkit`
library. */
const store = configureStore({
  reducer: persistedReducer,
  devTools: false,
});

export default store;
