import React from "react";
import { useSelector } from "react-redux";

import NoCoursesImg from "../../assets/images/No-courses-img.png";

import RocketIcon from "../../assets/images/rocket_icon.png";
import DiscordLogo from "../../assets/images/discord-logo.svg";

import "../../styles/NoCoursesInfo.css";

const NoCoursesInfo = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <div className="c-no-courses-info">
      <div className="c-no-courses-info__box">
        <p className="heading heading-h3">
          Już wkrótce! <img src={RocketIcon} alt="" />
        </p>
        <p>
          W tym miejscu otrzymasz dostęp do{" "}
          <strong>kursów i materiałów edukacyjnych</strong>, które pomogą Ci
          zdobyć nowe umiejętności niezbędne do wykonywania Twojej pracy marzeń.
        </p>
        <p>
          Dołącz do naszego serwera Discord, aby na bieżąco śledzić rozwój
          Jobbli i wpływać na niego swoimi sugestiami!
        </p>
        <button
          className="c-btn c-btn--in-icon c-btn--small-size c-btn--dark-blue-color"
          aria-label="Dołącz do Discorda Jobbli"
          onClick={() =>
            window.open(
              `/api/outlink?uid=${user?.id}&url=https://discord.gg/MsVeBGMQZ8`,
              "_blank"
            )
          }
        >
          Dołącz
          <span className="sr-only">(link otwiera się w nowej karcie)</span>
          <img src={DiscordLogo} alt="" />
        </button>
      </div>
      <div className="c-no-courses-info__box">
        <img src={NoCoursesImg} alt="" />
      </div>
    </div>
  );
};

export default NoCoursesInfo;
