import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";
import TagManager from "react-gtm-module";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Provider } from "react-redux";
import store from "./store";

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import App from "./App";

import AddToHomeScreenButton from "./components/layout/AddToHomeScreenButton";

import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
};

TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient();

let persistore = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SkeletonTheme baseColor="#FAF5F5" highlightColor="#C2C0C0">
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate persistor={persistore}>
            <QueryClientProvider client={queryClient}>
              <App />
              <AddToHomeScreenButton />
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </SkeletonTheme>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
