import React from "react";
import { Link } from "react-router-dom";

import RightTiltedArrow from "../../assets/images/right-tilted-arrow.svg";
import DefaultJobbliIcon from "../../assets/images/jobbli-default-icon.svg";
import LocationIcon from "../../assets/images/location.svg";
import CategoryIcon from "../../assets/images/briefcase.svg";

import "../../styles/JobOfferCard.css";

const JobOfferCard = ({
  id,
  company_logo,
  name,
  company,
  location,
  category,
}) => {
  return (
    <Link to={`/job-offers/${id}`} className="c-job-offer-card">
      <div className="c-job-offer-card__box">
        <img src={company_logo ? company_logo : DefaultJobbliIcon} alt="" />
      </div>
      <div className="c-job-offer-card__box">
        <p>
          <strong>{name}</strong>
        </p>
        <p>{company}</p>
      </div>
      {location ? (
        <div className="c-job-offer-card__box">
          <p>
            <img src={LocationIcon} alt="" />
            Lokalizacja
          </p>
          <p>
            <strong>{location}</strong>
          </p>
        </div>
      ) : (
        <div className="c-job-offer-card__box"></div>
      )}
      {category ? (
        <div className="c-job-offer-card__box">
          <p>
            <img src={CategoryIcon} alt="" />
            Dział
          </p>
          <p>
            <strong>{category}</strong>
          </p>
        </div>
      ) : (
        <div className="c-job-offer-card__box"></div>
      )}
      <div className="c-job-offer-card__box">
        <img src={RightTiltedArrow} alt="" />
      </div>
    </Link>
  );
};

export default JobOfferCard;
