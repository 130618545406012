import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import { getCareerSkills } from "../../store/skills";

import Skill from "./Skill";

import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";

import Star1 from "../../assets/images/Star-1-career.png";
import Star3 from "../../assets/images/Star-3-career.png";
import Star4 from "../../assets/images/Star-4-career.png";
import Star5 from "../../assets/images/Star-5-career.png";
import Union from "../../assets/images/Union-career.png";

import OpenBookIcon from "../../assets/images/open-book_icon.png";
import MemoIcon from "../../assets/images/memo_icon.png";
import CircusTentIcon from "../../assets/images/circus-tent_icon.png";
import FlexedBicepsIcon from "../../assets/images/flexed-biceps_icon.png";
import PencilIcon from "../../assets/images/pencil_icon.png";

import Vector7Img from "../../assets/images/vector-7.png";

import "../../styles/Career.css";

const CHARACTERISTICS_COLORS_ARRAY_PALETTE = [
  "blue",
  "pink",
  "orange",
  "green",
  "purple",
];

const TASKS_DECORATION_ARRAY_PALETTE = [Union, Star1, Star5, Star4, Star3];

const Career = ({
  id,
  name_en,
  description,
  characteristics,
  tasks,
  workplaces,
  handleStatusChecked,
}) => {
  const { favorite } = useSelector((state) => state.careers);

  const [isActive, setActive] = useState(false);
  const [isChecked, setChecked] = useState(
    favorite.some((item) => item.id === id)
  );

  const { data: skills } = useQuery({
    queryKey: ["careers", id, "skills"],
    queryFn: () => getCareerSkills(id),
    enabled: isActive,
  });

  return (
    <div className="c-career-accordion">
      <button
        type="button"
        className={
          isActive
            ? `c-career-accordion__btn ${
                !handleStatusChecked && "c-career-accordion__btn--public"
              } active`
            : `c-career-accordion__btn ${
                !handleStatusChecked && "c-career-accordion__btn--public"
              }`
        }
        onClick={() => setActive(!isActive)}
        aria-expanded={isActive ? true : false}
      >
        {handleStatusChecked && (
          <label htmlFor={`career-${id}`}>
            <input
              type="checkbox"
              id={`career-${id}`}
              name={`career-${id}`}
              className="c-career__checkbox"
              defaultChecked={isChecked}
              aria-hidden={true}
              tabIndex={-1}
              onClick={(e) => {
                e.stopPropagation();
                handleStatusChecked(id, e.target.checked);
                setChecked(e.target.checked);
              }}
            />
          </label>
        )}
        <h3>{name_en}</h3>
        <img src={isActive ? ArrowUp : ArrowDown} alt="" />
      </button>

      <div
        className={
          isActive
            ? "c-career-accordion__content open"
            : "c-career-accordion__content"
        }
      >
        {handleStatusChecked && (
          <button
            className="sr-only"
            aria-label={
              isChecked
                ? `Usuń ${name_en} z ulubionych`
                : `Dodaj ${name_en} do ulubionych`
            }
            onClick={() => {
              handleStatusChecked(id, !isChecked);
              setChecked(!isChecked);
            }}
          ></button>
        )}
        <div className="c-career-description-container">
          <h4 className="c-career-accordion-content__title">
            Opis <img src={OpenBookIcon} alt="" />
          </h4>
          <p>{description}</p>
        </div>
        <div className="c-career-characteristics-container">
          <div className="c-career-characteristic-box">
            <span>{`${characteristics.routine * 20}%`}</span>
            <p>rutynowość</p>
          </div>
          <div className="c-career-characteristic-box">
            <span>{`${characteristics.creativity * 20}%`}</span>
            <p>kreatywność</p>
          </div>
          <div className="c-career-characteristic-box">
            <span>{`${characteristics.stress * 20}%`}</span>
            <p>poziom stresu</p>
          </div>
          <div className="c-career-characteristic-box">
            <span>{`${characteristics.people * 20}%`}</span>
            <p>kontakt z ludźmi</p>
          </div>
          <div className="c-career-characteristic-box">
            <span>{`${characteristics.structure * 20}%`}</span>
            <p>ustrukturyzowanie pracy</p>
          </div>
          <div className="c-career-characteristic-box">
            <span>{`${characteristics.autonomy * 20}%`}</span>
            <p>samoorganizacja</p>
          </div>
          <div className="c-career-characteristic-box">
            <span>{`${characteristics.flexibility * 20}%`}</span>
            <p>elastyczność godzin i miejsca pracy</p>
          </div>
        </div>
        <div className="c-career-tasks">
          <h4 className="c-career-accordion-content__title">
            Zadania <img src={MemoIcon} alt="" />
          </h4>
          <div className="c-career-tasks-container">
            {tasks &&
              tasks.map((task, index) => (
                <div
                  key={task.id}
                  className={`c-career-task-box c-career-task-box--${CHARACTERISTICS_COLORS_ARRAY_PALETTE[index]}`}
                >
                  <img
                    className="c-decoration-img--bottom"
                    src={Vector7Img}
                    alt=""
                  />
                  {task.text}
                </div>
              ))}
          </div>
        </div>
        <div className="c-career-workplaces">
          <h4 className="c-career-accordion-content__title">
            Miejsca pracy <img src={CircusTentIcon} alt="" />
          </h4>
          <div className="c-career-workplaces-container">
            {workplaces &&
              workplaces.map((workplace, index) => (
                <div key={workplace.id} className="c-career-workplace-box">
                  <div className="c-career-workplace-box__img">
                    <img src={TASKS_DECORATION_ARRAY_PALETTE[index]} alt="" />
                  </div>
                  <div className="c-career-workplace-box__content">
                    <p>{workplace.text}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {skills && (
          <div className="c-career-skills">
            <h4 className="c-career-accordion-content__title">
              Wymagane umiejętności <img src={FlexedBicepsIcon} alt="" />
            </h4>
            <p>
              Przygotuj się do wykonywania idealnego dla Ciebie zawodu i zdobądź
              nowe skille w panelu <Link to="/skills">Umiejętności</Link>{" "}
              <img className="c-icon" src={PencilIcon} alt="" />
            </p>
            <div className="c-career-skills-container">
              <p>Możesz odznaczyć umiejętności, które już posiadasz.</p>
              <ul>
                {skills?.map((item) => (
                  <li key={item.id}>
                    <Skill {...item} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Career;
