import React, { useState, useEffect, useRef } from "react";
import { Navigate, Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../store/user";

import OfflineAlertInfo from "./OfflineAlertInfo";

import JobbliLogo from "../../assets/images/jobbli-logo.svg";

import UserIcon from "../../assets/images/user.svg";
import SettingsIcon from "../../assets/images/settings.svg";
import CouponsIcon from "../../assets/images/coupons.svg";
import LogoutIcon from "../../assets/images/logout.svg";

import "../../styles/Navbar.css";

const Navbar = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, hasAccessToDashboard, user } = useSelector(
    (state) => state.user
  );

  const [isActive, setActivate] = useState(false);
  const [isDropdownMenuActive, setIsDropdownMenuActive] = useState(false);

  const [redirect, setRedirect] = useState(false);

  const dropdownRef = useRef(null);
  const firstDropdownMenuItem = useRef(null);

  const handleListKeyDown = (e) => {
    const active = document.activeElement;

    switch (e.key) {
      case "Escape":
        setIsDropdownMenuActive(false);

        dropdownRef.current?.children[2].children[3].children[0].focus();

        break;
      case "ArrowDown":
        if (
          e.key === "ArrowDown" &&
          active.offsetParent.className ===
            "c-navbar-dropdown-menu__list open" &&
          active.parentElement.nextSibling
        ) {
          active.parentElement.nextSibling.firstChild.focus();
        }
        break;
      case "ArrowUp":
        if (
          e.key === "ArrowUp" &&
          active.offsetParent.className ===
            "c-navbar-dropdown-menu__list open" &&
          active.parentElement.previousSibling
        ) {
          active.parentElement.previousSibling.firstChild.focus();
        } else if (
          e.key === "ArrowUp" &&
          active.offsetParent.className ===
            "c-navbar-dropdown-menu__list open" &&
          !active.parentElement.previousSibling
        ) {
          setIsDropdownMenuActive(false);

          dropdownRef.current?.children[2].children[3].children[0].focus();
        }
        break;
      case "ArrowLeft":
        if (
          e.key === "ArrowLeft" &&
          active.offsetParent.className !==
            "c-navbar-dropdown-menu__list open" &&
          active.parentElement.previousSibling
        ) {
          active.parentElement.previousSibling.firstChild.focus();
        }
        break;
      case "ArrowRight":
        if (
          e.key === "ArrowRight" &&
          active.offsetParent.className !==
            "c-navbar-dropdown-menu__list open" &&
          active.parentElement.nextSibling
        ) {
          active.parentElement.nextSibling.firstChild.focus();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
        setActivate(false);
        setIsDropdownMenuActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleListKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleListKeyDown);
    };
  }, []);

  const onLogout = async (e) => {
    e.preventDefault();
    dispatch(logout());
    setRedirect(true);
  };

  const toggleDropdownMenu = () => {
    setIsDropdownMenuActive(!isDropdownMenuActive);

    if (!isDropdownMenuActive) {
      setTimeout(() => {
        firstDropdownMenuItem.current?.focus();
      }, 300);
    }
  };

  if (redirect) {
    return <Navigate to="/" replace />;
  }

  return (
    <header>
      <nav className="c-navbar" ref={dropdownRef}>
        <h1>
          <Link className="c-navbar__link" to="/">
            <img src={JobbliLogo} alt="Przejdź do strony głównej Jobbli" />
          </Link>
        </h1>
        {isAuthenticated ? (
          <>
            <button
              className="c-navbar-hamburger-menu__btn"
              type="button"
              onClick={() => setActivate((prevState) => !prevState)}
              aria-label={isActive ? "Zamknij menu" : "Otwórz menu"}
              aria-expanded={isActive ? true : false}
              aria-haspopup="true"
              aria-controls="menu1"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <ul
              id="menu1"
              className={isActive ? "c-navbar-menu open" : "c-navbar-menu"}
            >
              <li className="c-navbar-menu__item">
                <NavLink to="/report">Raport</NavLink>
              </li>
              <li className="c-navbar-menu__item">
                <NavLink to="/careers-list">Lista zawodów</NavLink>
              </li>
              <li className="c-navbar-menu__item">
                <NavLink to="/skills">Umiejętności i kursy</NavLink>
              </li>
              <li className="c-navbar-dropdown-menu">
                <button
                  className="c-navbar__btn"
                  aria-expanded={isDropdownMenuActive ? true : false}
                  onClick={toggleDropdownMenu}
                >
                  <img src={UserIcon} alt="Profil" />
                </button>
                <ul
                  className={
                    isDropdownMenuActive
                      ? "c-navbar-dropdown-menu__list open"
                      : "c-navbar-dropdown-menu__list"
                  }
                >
                  <li>
                    <NavLink to="/settings" ref={firstDropdownMenuItem}>
                      <img src={SettingsIcon} alt="" />
                      Ustawienia konta
                    </NavLink>
                  </li>
                  {hasAccessToDashboard && (
                    <li>
                      <NavLink to="/dashboard">
                        <img src={CouponsIcon} alt="" />
                        Moi klienci
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <button className="c-navbar__btn" onClick={onLogout}>
                      <img src={LogoutIcon} alt="" />
                      Wyloguj się
                    </button>
                  </li>
                </ul>
              </li>
              <li className="c-navbar-menu__item">
                <button
                  className="c-navbar__btn c-navbar__btn--discord"
                  onClick={() =>
                    window.open(
                      `/api/outlink?uid=${user?.id}&url=https://konsultacjejobbli.youcanbook.me/`
                    )
                  }
                >
                  Skonsultuj swój raport
                  <span className="sr-only">
                    (link otwiera się w nowej karcie)
                  </span>
                </button>
              </li>
              <li className="c-navbar-menu__item--mobile">
                <NavLink to="/settings">
                  <img src={SettingsIcon} alt="" />
                  Ustawienia konta
                </NavLink>
              </li>
              {hasAccessToDashboard && (
                <li className="c-navbar-menu__item--mobile">
                  <NavLink to="/dashboard">
                    <img src={CouponsIcon} alt="" />
                    Moi klienci
                  </NavLink>
                </li>
              )}
              <li className="c-navbar-menu__item--mobile">
                <button className="c-navbar__btn" onClick={onLogout}>
                  <img src={LogoutIcon} alt="" />
                  Wyloguj się
                </button>
              </li>
            </ul>
          </>
        ) : null}
      </nav>
      <OfflineAlertInfo />
    </header>
  );
};

export default Navbar;
