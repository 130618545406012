import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { resetPassword, authorizationAlert } from "../store/user";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import Footer from "./layout/Footer";
import Alert from "./layout/Alert";
import PasswordStrengthMeter from "./layout/PasswordStrengthMeter";

import LoadingIcon from "../assets/images/loading-message-icon.gif";

import TogglePasswordIcon from "../assets/images/eye.svg";

const ResetPassword = (props) => {
  const { code } = props;
  const [formData, setFormData] = useState({
    password: "",
    password2: "",
  });

  const [passwordCheck, setPasswordCheck] = useState({
    length: null,
    hasUpperCase: null,
    hasDigit: null,
  });

  const [errorAlertMessages, setErrorAlertMessages] = useState({
    password: "",
    password2: "",
  });

  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showPassword2Input, setShowPassword2Input] = useState(false);

  const headingRef = useRef(null);

  const { password, password2 } = formData;

  const dispatch = useDispatch();
  const { loading, isAuthenticated, alertMessage } = useSelector(
    (state) => state.user
  );

  useLayoutEffect(() => {
    document.title = "Zresetuj hasło | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(authorizationAlert("", null));
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === "password") {
      setPasswordCheck((prevState) => ({
        ...prevState,
        length: e.target.value.length >= 8 ? true : false,
      }));
      setPasswordCheck((prevState) => ({
        ...prevState,
        hasUpperCase: /[A-Z]+/.test(e.target.value),
      }));
      setPasswordCheck((prevState) => ({
        ...prevState,
        hasDigit: /[0-9]+/.test(e.target.value),
      }));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setPasswordCheck((prevState) => ({
      ...prevState,
      length: password.length >= 8 ? true : false,
    }));
    setPasswordCheck((prevState) => ({
      ...prevState,
      hasUpperCase: /[A-Z]+/.test(password),
    }));
    setPasswordCheck((prevState) => ({
      ...prevState,
      hasDigit: /[0-9]+/.test(password),
    }));

    if (password === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        password: "Wprowadź hasło",
      }));
    } else {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        password:
          passwordCheck.length &&
          passwordCheck.hasUpperCase &&
          passwordCheck.hasDigit
            ? null
            : `Hasło powinno zawierać co najmniej ${
                !passwordCheck.length ? " 8 znaków" : ""
              } ${!passwordCheck.hasUpperCase ? " 1 wielką literę" : ""} ${
                !passwordCheck.hasDigit ? " 1 cyfrę" : ""
              }`,
      }));
    }

    if (password2 === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        password2: "Powtórz hasło",
      }));
    }

    if (password !== "" && password2 !== "" && password !== password2) {
      dispatch(authorizationAlert("Password do not match", false));
    } else if (password !== "" && password2 !== "" && password === password2) {
      dispatch(resetPassword(code, password, password2));

      setFormData({
        password: "",
        password2: "",
      });
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/report" replace />;
  }

  return (
    <>
      <Skiplinks />
      <Navbar />
      <main id="content">
        <ContentLogo />
        <div className="l-home-container">
          <div className="c-home c-home--regular-width">
            <div className="c-home__content c-home__content--column">
              <form
                className="c-form c-form--full-width"
                onSubmit={onSubmit}
                noValidate
              >
                <h2 className="c-form__title" tabIndex={-1} ref={headingRef}>
                  Ustaw nowe hasło
                </h2>
                {alertMessage ? <Alert /> : null}
                <label htmlFor="passwordInput" className="c-form__label">
                  Hasło*:
                </label>
                <div className="c-form__group">
                  <input
                    type={showPasswordInput ? "text" : "password"}
                    id="passwordInput"
                    name="password"
                    className={
                      errorAlertMessages.password
                        ? "c-form__input c-form__input--password c-form__input--error"
                        : "c-form__input c-form__input--password"
                    }
                    value={password}
                    onChange={(e) => onChange(e)}
                    required
                    aria-describedby="passwordInputDesc"
                  />
                  <button
                    type="button"
                    className="c-btn-toggle-password"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPasswordInput(!showPasswordInput);
                    }}
                    aria-label={
                      showPasswordInput ? "Ukryj hasło" : "Pokaż hasło"
                    }
                  >
                    <img src={TogglePasswordIcon} alt="" />
                  </button>
                </div>
                <PasswordStrengthMeter password={password} />
                {errorAlertMessages.password && (
                  <p
                    role="alert"
                    id="passwordInputDesc"
                    className={
                      password !== "" ? "sr-only" : "c-form__error-message"
                    }
                    aria-atomic="true"
                  >
                    {errorAlertMessages.password}
                  </p>
                )}
                <div id="passwordInputDesc">
                  <p>Hasło powinno zawierać:</p>
                  <ul>
                    <li
                      style={
                        passwordCheck.length !== null
                          ? passwordCheck.length
                            ? { color: "#35C544" }
                            : { color: "#CD1414" }
                          : null
                      }
                    >
                      co najmniej 8 znaków
                    </li>
                    <li
                      style={
                        passwordCheck.hasUpperCase !== null
                          ? passwordCheck.hasUpperCase
                            ? { color: "#35C544" }
                            : { color: "#CD1414" }
                          : null
                      }
                    >
                      co najmniej 1 wielką literę
                    </li>
                    <li
                      style={
                        passwordCheck.hasDigit !== null
                          ? passwordCheck.hasDigit
                            ? { color: "#35C544" }
                            : { color: "#CD1414" }
                          : null
                      }
                    >
                      co najmniej 1 cyfrę
                    </li>
                  </ul>
                </div>
                <label htmlFor="passwordInput2" className="c-form__label">
                  Powtórz hasło*:
                </label>
                <div className="c-form__group">
                  <input
                    type={showPassword2Input ? "text" : "password"}
                    id="passwordInput2"
                    name="password2"
                    className={
                      errorAlertMessages.password2
                        ? "c-form__input c-form__input--password c-form__input--error"
                        : "c-form__input c-form__input--password"
                    }
                    value={password2}
                    onChange={(e) => onChange(e)}
                    required
                    aria-describedby="password2InputDesc"
                  />
                  <button
                    type="button"
                    className="c-btn-toggle-password"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword2Input(!showPassword2Input);
                    }}
                    aria-label={
                      showPassword2Input ? "Ukryj hasło" : "Pokaż hasło"
                    }
                  >
                    <img src={TogglePasswordIcon} alt="" />
                  </button>
                </div>
                {errorAlertMessages.password2 && (
                  <p
                    role="alert"
                    id="password2InputDesc"
                    className="c-form__error-message"
                    aria-atomic="true"
                  >
                    {errorAlertMessages.password2}
                  </p>
                )}
                <button
                  type="submit"
                  disabled={loading}
                  className="c-btn c-btn--in-icon c-btn--small-size c-btn--blue-color"
                >
                  {loading ? (
                    <>
                      <img className="c-icon" src={LoadingIcon} alt="" /> Zmień
                      hasło
                    </>
                  ) : (
                    "Zmień hasło"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ResetPassword;
