import React from "react";

import JobbliMessageLogoIcon from "../../assets/images/jobbli-icon-chatbot.svg";

const ChatMessage = ({ message, direction, sentTime, sender }, ref) => {
  return (
    <>
      {direction === "outgoing" ? (
        <li
          className="c-chatbot__message c-chatbot__message--outgoing"
          tabIndex={"0"}
        >
          <div className="c-chatbot__message-box">
            <p className="c-chatbot__content">
              <span className="sr-only">{sender}: </span>
              {message}
            </p>
            <p className="c-chatbot__time">{sentTime}</p>
          </div>
        </li>
      ) : (
        <li
          className="c-chatbot__message c-chatbot__message--incoming"
          tabIndex={"0"}
          aria-live="polite"
          ref={ref}
        >
          <div className="c-chatbot__message-box">
            <span>
              <img src={JobbliMessageLogoIcon} alt="" />
            </span>
          </div>
          <div className="c-chatbot__message-box">
            <p className="c-chatbot__sender">{sender}</p>
            <p className="c-chatbot__content">{message}</p>
            <p className="c-chatbot__time">{sentTime}</p>
          </div>
        </li>
      )}
    </>
  );
};

export default React.forwardRef(ChatMessage);
