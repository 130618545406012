import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { forgotPassword, authorizationAlert } from "../store/user";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import Footer from "./layout/Footer";
import Alert from "./layout/Alert";

import LoadingIcon from "../assets/images/loading-message-icon.gif";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [errorAlertMessage, setErrorAlertMessage] = useState("");

  const headingRef = useRef(null);

  const { email } = formData;

  const dispatch = useDispatch();
  const { loading, isAuthenticated, alertMessage } = useSelector(
    (state) => state.user
  );

  useLayoutEffect(() => {
    document.title =
      "Zapomniałeś hasła | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(authorizationAlert("", null));
  }, []);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );

    if (email === "") {
      setErrorAlertMessage("Wprowadź adres email");
    } else {
      if (!emailRegex.test(email)) {
        dispatch(
          authorizationAlert(
            "Podaj poprawnie sformatowany adres e-mail. Adres powienien zawierać znak '@'",
            false
          )
        );
      } else {
        dispatch(forgotPassword(email));

        setFormData({
          email: "",
        });
      }
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/report" replace />;
  }

  return (
    <>
      <Skiplinks />
      <Navbar />
      <main id="content">
        <ContentLogo />
        <div className="l-home-container">
          <div className="c-home c-home--regular-width">
            <div className="c-home__content">
              <form
                className="c-form c-form--full-width"
                onSubmit={onSubmit}
                noValidate
              >
                <h2 className="c-form__title" tabIndex={-1} ref={headingRef}>
                  Nie pamiętam hasła ;(
                </h2>
                {alertMessage ? <Alert /> : null}
                <label htmlFor="emailInput" className="c-form__label">
                  Adres email*:
                </label>
                <input
                  type="email"
                  id="emailInput"
                  name="email"
                  className={
                    errorAlertMessage
                      ? "c-form__input c-form__input--error"
                      : "c-form__input"
                  }
                  value={email}
                  onChange={(e) => onChange(e)}
                  required
                  aria-describedby="emailInputDesc"
                />
                {errorAlertMessage && (
                  <p
                    role="alert"
                    id="emailInputDesc"
                    className="c-form__error-message"
                    aria-atomic="true"
                  >
                    {errorAlertMessage}
                  </p>
                )}
                <button
                  type="submit"
                  disabled={loading}
                  className="c-btn c-btn--in-icon c-btn--small-size c-btn--blue-color"
                >
                  {loading ? (
                    <>
                      <img className="c-icon" src={LoadingIcon} alt="" />{" "}
                      Przypomnij hasło
                    </>
                  ) : (
                    "Przypomnij hasło"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ForgotPassword;
