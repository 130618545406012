import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useSpring, animated } from "react-spring";
import axios from "axios";

import ChatMessage from "./ChatMessage";

import SmilingFaceWithSunglassesIcon from "../../assets/images/smiling-face-with-sunglasses_icon.png";

import JobbliLogoIcon from "../../assets/images/jobbli-logo-chatbot.svg";
import JobbliMessageLogoIcon from "../../assets/images/jobbli-icon-chatbot.svg";
import Close from "../../assets/images/close-circle-small.svg";
import Vector5Img from "../../assets/images/vector-5.png";
import RightArrow from "../../assets/images/right-arrow.svg";

import LoadingMessageIcon from "../../assets/images/loading-message-icon.gif";

import "../../styles/Chatbot.css";

const Chatbot = ({ chatbotButtonRef, close }) => {
  const { user, chatbot_mode } = useSelector((state) => state.user);

  const chatbotRef = useRef(null);
  const chatbotBodyMessagesRef = useRef(null);
  const messagesRef = useRef(null);

  const [message, setMessage] = useState("");

  const [messages, setMessages] = useState(() => {
    const storedHistory = localStorage.getItem("chatbotHistory");

    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const formattedTime = `${currentHour}:${
      currentMinutes < 10 ? "0" : ""
    }${currentMinutes}`;

    const initialMessage = {
      message:
        "Cześć, jestem AI Jobbli! Jeśli masz jakieś pytanie, napisz, a ja postaram się pomóc na tyle, na ile będę potrafić.",
      sentTime: formattedTime,
      direction: "incoming",
      sender: "Jobbli",
    };

    return storedHistory ? JSON.parse(storedHistory) : [initialMessage];
  });

  const [typing, setTyping] = useState(false);

  const sendMessageMutation = useMutation({
    mutationFn: (newMessage) => {
      setTyping(true);

      setTimeout(() => {
        if (chatbotBodyMessagesRef.current) {
          chatbotBodyMessagesRef.current.lastChild?.focus();
          chatbotBodyMessagesRef.current.lastChild?.scrollIntoView({
            block: "start",
            behavior: "smooth",
          });
        }
      }, 100);

      return axios
        .post(
          `https://app.jobbli.pl:8026/chatbot`,
          {
            user_id: user?.id,
            message: newMessage.message,
            mode: chatbot_mode,
            chat_open: "True",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setTyping(false);

          return response.data;
        })
        .catch((error) => {
          //console.error("An error occurred:", error);
          throw error;
        });
    },
  });

  const typingAnimation = useSpring({
    opacity: typing ? "1" : "0",
    display: typing ? "flex" : "none",
  });

  const onSubmitSendMessage = (e) => {
    e.preventDefault();

    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const formattedTime = `${currentHour}:${
      currentMinutes < 10 ? "0" : ""
    }${currentMinutes}`;

    if (message) {
      const newMessage = {
        message,
        sentTime: formattedTime,
        direction: "outgoing",
        sender: user?.username,
      };

      const newMessages = [...messages, newMessage];

      setMessage("");
      setMessages(newMessages);

      sendMessageMutation.mutate(newMessage);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        chatbotRef.current &&
        !chatbotRef.current?.contains(e.target) &&
        chatbotButtonRef.current &&
        !chatbotButtonRef.current?.contains(e.target)
      ) {
        close();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      const activeElement = document.activeElement;
      document.removeEventListener("mousedown", handleClickOutside);
      activeElement.focus();
    };
  }, []);

  useEffect(() => {
    if (sendMessageMutation.isSuccess) {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const formattedTime = `${currentHour}:${
        currentMinutes < 10 ? "0" : ""
      }${currentMinutes}`;

      const newBotMessage = {
        message: sendMessageMutation?.data?.bot_answer,
        sentTime: formattedTime,
        direction: "incoming",
        sender: "Jobbli",
      };

      const newMessages = [...messages, newBotMessage];
      setMessages(newMessages);
    }
  }, [sendMessageMutation.isSuccess, sendMessageMutation.data]);

  useEffect(() => {
    localStorage.setItem("chatbotHistory", JSON.stringify(messages));

    if (chatbotBodyMessagesRef.current) {
      chatbotBodyMessagesRef.current.lastChild?.focus();
      chatbotBodyMessagesRef.current.lastChild?.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }, [messages]);

  return (
    <div
      role="region"
      className="c-chatbot"
      aria-labelledby="chatbotDesc"
      ref={chatbotRef}
    >
      <div className="c-chatbot__header">
        <img className="c-decoration-img" src={Vector5Img} alt="" />
        <div className="c-chatbot__header-box">
          <img src={JobbliLogoIcon} alt="" />
          <button
            className="c-chatbot__close-btn"
            onClick={close}
            aria-label="Zamknij okno chatbota"
          >
            <img src={Close} alt="" />
          </button>
        </div>
        <div className="c-chatbot__header-box">
          <p id="chatbotDesc">
            Hej! Jestem botem Jobbli{" "}
            <img src={SmilingFaceWithSunglassesIcon} alt="" />
            <br></br>Napisz do mnie jeśli masz jakiekolwiek pytania lub
            potrzebujesz wsparcia.
          </p>
        </div>
      </div>
      <div className="c-chatbot__body">
        <ul ref={chatbotBodyMessagesRef}>
          {messages.map((message, index) => (
            <ChatMessage key={index} ref={messagesRef} {...message} />
          ))}
          {typing && (
            <animated.li
              style={typingAnimation}
              className="c-chatbot__message c-chatbot__message--incoming"
              tabIndex={0}
            >
              <div className="c-chatbot__message-box">
                <span>
                  <img src={JobbliMessageLogoIcon} alt="" />
                </span>
              </div>
              <div className="c-chatbot__message-box">
                <p className="c-chatbot__sender">Jobbli</p>
                <img
                  className="c-chatbot__loading-message-icon"
                  src={LoadingMessageIcon}
                  alt="Bot Jobbli pisze..."
                />
              </div>
            </animated.li>
          )}
        </ul>
      </div>
      <form
        className="c-chatbot__input"
        noValidate
        onSubmit={onSubmitSendMessage}
      >
        {sendMessageMutation.isError && (
          <span role="alert" className="c-chatbot__toast">
            Błąd podczas próby połączenia z chatbotem
          </span>
        )}
        <div className="c-chatbot__input-box">
          <textarea
            name="chatbotMessage"
            placeholder="Napisz wiadomość.."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="c-chatbot__input-box">
          <button
            type="submit"
            className="c-chatbot-btn"
            aria-label="Wyślij wiadomość"
            disabled={typing}
          >
            <img src={RightArrow} alt="" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Chatbot;
