import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { Navigate, Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { register, authorizationAlert } from "../store/user";
import { userCompletedTest } from "../store/report";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import OAuthButton from "./layout/OAuthButton";
import Footer from "./layout/Footer";
import Alert from "./layout/Alert";
import Loader from "./layout/Loader";
import PasswordStrengthMeter from "./layout/PasswordStrengthMeter";

import LoadingIcon from "../assets/images/loading-message-icon.gif";

import GoogleLogo from "../assets/images/google-logo.svg";
import FacebookLogo from "../assets/images/fb-logo.svg";

import TogglePasswordIcon from "../assets/images/eye.svg";

import SignUpHomeImg from "../assets/images/Sign-up-home-img.png";

const Signup = () => {
  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    password2: "",
    isRulesConfirmed: false,
  });

  const [passwordCheck, setPasswordCheck] = useState({
    length: null,
    hasUpperCase: null,
    hasDigit: null,
  });

  const [errorAlertMessages, setErrorAlertMessages] = useState({
    username: "",
    email: "",
    password: "",
    password2: "",
    isRulesConfirmed: "",
  });

  const isRulesConfirmedRef = useRef(null);
  const isRulesConfirmedErrorAlertRef = useRef(null);
  const headingRef = useRef(null);

  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showPassword2Input, setShowPassword2Input] = useState(false);

  const { username, email, password, password2, isRulesConfirmed } = formData;

  const dispatch = useDispatch();
  const { loading, isAuthenticated, alertMessage, status } = useSelector(
    (state) => state.user
  );
  const { isTestCompleted } = useSelector((state) => state.report);

  useLayoutEffect(() => {
    document.title = "Zarejestruj się | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(authorizationAlert("", null));
  }, []);

  useEffect(() => {
    let email = searchParams.get("email");
    if (email) {
      email = decodeURI(email);
      dispatch(userCompletedTest(email));
      setFormData({ ...formData, email });
    }
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrorAlertMessages({ ...errorAlertMessages, [e.target.name]: "" });

    if (e.target.name === "password") {
      setPasswordCheck((prevState) => ({
        ...prevState,
        length: e.target.value.length >= 8 ? true : false,
      }));
      setPasswordCheck((prevState) => ({
        ...prevState,
        hasUpperCase: /[A-Z]+/.test(e.target.value),
      }));
      setPasswordCheck((prevState) => ({
        ...prevState,
        hasDigit: /[0-9]+/.test(e.target.value),
      }));
    }
  };

  const handleRulesConfirmed = () => {
    if (!isRulesConfirmedRef.current?.checked) {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        isRulesConfirmed: "Aby przejść dalej zaakceptuj warunki Regulaminu",
      }));

      const isRulesConfirmedCheckbox = isRulesConfirmedRef.current;
      isRulesConfirmedCheckbox.classList.add("c-form__checkbox--error");

      const isRulesConfirmedErrorAlert = isRulesConfirmedErrorAlertRef.current;
      isRulesConfirmedErrorAlert.style.display = "block";
    } else {
      const isRulesConfirmedCheckbox = isRulesConfirmedRef.current;
      isRulesConfirmedCheckbox.classList.remove("c-form__checkbox--error");

      const isRulesConfirmedErrorAlert = isRulesConfirmedErrorAlertRef.current;
      isRulesConfirmedErrorAlert.style.display = "none";
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setErrorAlertMessages({
      username: "",
      email: "",
      password: "",
      password2: "",
      isRulesConfirmed: "",
    });

    const emailRegex = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );
    if (username === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        username: "Wprowadź nazwę użytkownika",
      }));
    }

    if (email === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        email: "Wprowadź adres email",
      }));
    } else if (!emailRegex.test(email)) {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        email:
          "Podaj poprawnie sformatowany adres e-mail. Adres powienien zawierać znak '@'",
      }));
    }

    setPasswordCheck((prevState) => ({
      ...prevState,
      length: password.length >= 8 ? true : false,
    }));
    setPasswordCheck((prevState) => ({
      ...prevState,
      hasUpperCase: /[A-Z]+/.test(password),
    }));
    setPasswordCheck((prevState) => ({
      ...prevState,
      hasDigit: /[0-9]+/.test(password),
    }));

    if (password === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        password: "Wprowadź hasło",
      }));
    } else {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        password:
          passwordCheck.length &&
          passwordCheck.hasUpperCase &&
          passwordCheck.hasDigit
            ? null
            : `Hasło powinno zawierać co najmniej ${
                !passwordCheck.length ? " 8 znaków" : ""
              } ${!passwordCheck.hasUpperCase ? " 1 wielką literę" : ""} ${
                !passwordCheck.hasDigit ? " 1 cyfrę" : ""
              }`,
      }));
    }

    if (password2 === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        password2: "Powtórz hasło",
      }));
    }

    handleRulesConfirmed();

    if (
      username !== "" &&
      email !== "" &&
      password !== "" &&
      password2 !== "" &&
      isRulesConfirmed
    ) {
      if (!Object.values(errorAlertMessages).some((v) => v)) {
        if (password !== password2) {
          dispatch(authorizationAlert("Password do not match", false));
        } else {
          dispatch(register(username, email, password));
        }
      }
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/report" replace />;
  }

  return (
    <>
      {isTestCompleted || !searchParams.get("email") ? (
        <>
          <Skiplinks />
          <Navbar />
          <main id="content">
            <ContentLogo />
            <div className="l-home-container">
              <div className="c-home c-home--middle-width">
                <div className="c-home__title">
                  <p>
                    Jesteś tylko o krok od poznania swoich mocnych i słabych
                    stron!
                  </p>
                </div>
                <div className="c-home__content">
                  <div className="c-home__box">
                    <img src={SignUpHomeImg} alt="" />
                  </div>
                  <div className="c-home__box">
                    {!status ? (
                      <>
                        <form
                          className="c-form c-form--large-width"
                          onSubmit={onSubmit}
                          noValidate
                        >
                          <h2
                            className="c-form__title"
                            tabIndex={-1}
                            ref={headingRef}
                          >
                            Zarejestruj się
                          </h2>
                          {alertMessage ? <Alert /> : null}
                          <label
                            htmlFor="usernameInput"
                            className="c-form__label"
                          >
                            Nazwa użytkownika*:
                          </label>
                          <input
                            type="text"
                            id="usernameInput"
                            name="username"
                            className={
                              errorAlertMessages.username
                                ? "c-form__input c-form__input--error"
                                : "c-form__input"
                            }
                            value={username}
                            onChange={(e) => onChange(e)}
                            required
                            aria-describedby="usernameInputDesc"
                          />
                          {errorAlertMessages.username && (
                            <p
                              role="alert"
                              id="usernameInputDesc"
                              className="c-form__error-message"
                              aria-atomic="true"
                            >
                              {errorAlertMessages.username}
                            </p>
                          )}
                          <label htmlFor="emailInput" className="c-form__label">
                            Adres email*:
                          </label>
                          {searchParams.get("email") ? (
                            <input
                              type="email"
                              id="emailInput"
                              name="email"
                              className={
                                errorAlertMessages.email
                                  ? "c-form__input c-form__input--error"
                                  : "c-form__input"
                              }
                              value={searchParams.get("email")}
                              onChange={(e) => onChange(e)}
                              disabled
                              required
                              aria-describedby="emailInputDesc"
                            />
                          ) : (
                            <input
                              type="email"
                              id="emailInput"
                              name="email"
                              className={
                                errorAlertMessages.email
                                  ? "c-form__input c-form__input--error"
                                  : "c-form__input"
                              }
                              value={email}
                              onChange={(e) => onChange(e)}
                              required
                              aria-describedby="emailInputDesc"
                            />
                          )}
                          {errorAlertMessages.email && (
                            <p
                              role="alert"
                              id="emailInputDesc"
                              className="c-form__error-message"
                              aria-atomic="true"
                            >
                              {errorAlertMessages.email}
                            </p>
                          )}
                          <label
                            htmlFor="passwordInput"
                            className="c-form__label"
                          >
                            Hasło*:
                          </label>
                          <div className="c-form__group">
                            <input
                              type={showPasswordInput ? "text" : "password"}
                              id="passwordInput"
                              name="password"
                              className={
                                errorAlertMessages.password
                                  ? "c-form__input c-form__input--password c-form__input--error"
                                  : "c-form__input c-form__input--password"
                              }
                              value={password}
                              onChange={(e) => onChange(e)}
                              required
                              aria-describedby="passwordInputDesc"
                            />
                            <button
                              type="button"
                              className="c-btn-toggle-password"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowPasswordInput(!showPasswordInput);
                              }}
                              aria-label={
                                showPasswordInput
                                  ? "Ukryj hasło"
                                  : "Pokaż hasło"
                              }
                            >
                              <img src={TogglePasswordIcon} alt="" />
                            </button>
                          </div>
                          {errorAlertMessages.password && (
                            <p
                              role="alert"
                              id="passwordInputDesc"
                              className={
                                password !== ""
                                  ? "sr-only"
                                  : "c-form__error-message"
                              }
                              aria-atomic="true"
                            >
                              {errorAlertMessages.password}
                            </p>
                          )}
                          <PasswordStrengthMeter password={password} />
                          <div id="passwordInputDesc">
                            <p>Hasło powinno zawierać:</p>
                            <ul>
                              <li
                                style={
                                  passwordCheck.length !== null
                                    ? passwordCheck.length
                                      ? { color: "#35C544" }
                                      : { color: "#CD1414" }
                                    : null
                                }
                              >
                                co najmniej 8 znaków
                              </li>
                              <li
                                style={
                                  passwordCheck.hasUpperCase !== null
                                    ? passwordCheck.hasUpperCase
                                      ? { color: "#35C544" }
                                      : { color: "#CD1414" }
                                    : null
                                }
                              >
                                co najmniej 1 wielką literę
                              </li>
                              <li
                                style={
                                  passwordCheck.hasDigit !== null
                                    ? passwordCheck.hasDigit
                                      ? { color: "#35C544" }
                                      : { color: "#CD1414" }
                                    : null
                                }
                              >
                                co najmniej 1 cyfrę
                              </li>
                            </ul>
                          </div>
                          <label
                            htmlFor="passwordInput2"
                            className="c-form__label"
                          >
                            Powtórz hasło*:
                          </label>
                          <div className="c-form__group">
                            <input
                              type={showPassword2Input ? "text" : "password"}
                              id="passwordInput2"
                              name="password2"
                              className={
                                errorAlertMessages.password2
                                  ? "c-form__input c-form__input--password c-form__input--error"
                                  : "c-form__input c-form__input--password"
                              }
                              value={password2}
                              onChange={(e) => onChange(e)}
                              required
                              aria-describedby="password2InputDesc"
                            />
                            <button
                              type="button"
                              className="c-btn-toggle-password"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowPassword2Input(!showPassword2Input);
                              }}
                              aria-label={
                                showPassword2Input
                                  ? "Ukryj hasło"
                                  : "Pokaż hasło"
                              }
                            >
                              <img src={TogglePasswordIcon} alt="" />
                            </button>
                          </div>
                          {errorAlertMessages.password2 && (
                            <p
                              role="alert"
                              id="password2InputDesc"
                              className="c-form__error-message"
                              aria-atomic="true"
                            >
                              {errorAlertMessages.password2}
                            </p>
                          )}
                          <p className="text--center text--padding-t-1">
                            lub zarejestruj się używając konta
                          </p>
                          <div className="c-oath-container">
                            <OAuthButton
                              label="Zarejestruj się przy użyciu konta Google"
                              logo={GoogleLogo}
                              type={"google"}
                              handleClick={() =>
                                (window.location.href = `${process.env.REACT_APP_API_URL}/api/connect/google`)
                              }
                            />
                            <OAuthButton
                              label="Zarejestruj się przy użyciu konta Facebook"
                              logo={FacebookLogo}
                              type={"facebook"}
                              handleClick={() =>
                                (window.location.href = `${process.env.REACT_APP_API_URL}/api/connect/facebook`)
                              }
                            />
                          </div>
                          <div className="c-form__group">
                            <input
                              type="checkbox"
                              id="isRulesConfirmed"
                              name="isRulesConfirmed"
                              className="c-form__checkbox"
                              checked={isRulesConfirmed}
                              ref={isRulesConfirmedRef}
                              onChange={() => {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  isRulesConfirmed:
                                    isRulesConfirmedRef.current?.checked,
                                }));

                                handleRulesConfirmed();
                              }}
                              onKeyDown={() => {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  isRulesConfirmed:
                                    isRulesConfirmedRef.current?.checked,
                                }));

                                handleRulesConfirmed();
                              }}
                              aria-describedby="isRulesConfirmedDesc"
                            />
                            <label
                              htmlFor="isRulesConfirmed"
                              className="c-form__label"
                            >
                              Akceptuje warunki{" "}
                              <a
                                href="https://jobbli.pl/wp-content/uploads/2024/05/Regulamin-uslugi-Jobbli.pdf"
                                className="c-form__link"
                                rel="noreferrer"
                                target="_blank"
                              >
                                Regulaminu*{" "}
                                <span className="sr-only">
                                  (otworzy się w nowej karcie)
                                </span>
                              </a>
                            </label>
                          </div>
                          <p
                            role="alert"
                            id="isRulesConfirmedDesc"
                            ref={isRulesConfirmedErrorAlertRef}
                            className="c-form__error-message"
                            aria-atomic="true"
                          >
                            {errorAlertMessages.isRulesConfirmed}
                          </p>
                          <p>* - pola wymagane</p>
                          <button
                            type="submit"
                            disabled={loading}
                            className="c-btn c-btn--in-icon c-btn--small-size c-btn--blue-color"
                          >
                            {loading ? (
                              <>
                                <img
                                  className="c-icon"
                                  src={LoadingIcon}
                                  alt=""
                                />{" "}
                                Rejestrowanie
                              </>
                            ) : (
                              "Zarejestruj się"
                            )}
                          </button>
                          <p className="text--center">
                            Mam już konto{" "}
                            <Link
                              to="/login"
                              className="c-form__link c-form__link--blue"
                            >
                              Zaloguj się
                            </Link>
                          </p>
                        </form>
                      </>
                    ) : (
                      <>
                        <p className="c-alert__title">Sukces!</p>
                        {alertMessage ? <Alert /> : null}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </>
      ) : (
        <main id="content">
          <div className="container">
            <Loader content={"Trwa przygotowanie raportu"} />
          </div>
        </main>
      )}
    </>
  );
};

export default Signup;
