import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import {
  getSkillAndSaveToUserSkillsList,
  getSkillAndRemoveFromUserSkillsList,
  getCoursesSkillInfo,
} from "../../store/skills";

import "../../styles/Skill.css";

const SkillCourse = ({ id }) => {
  const dispatch = useDispatch();
  const { skills } = useSelector((state) => state.skills);

  const { data: skill } = useQuery({
    queryKey: ["skills", id],
    queryFn: () => getCoursesSkillInfo(id),
  });

  const [isChecked, setChecked] = useState(
    skills.some((item) => item.id === skill?.id)
  );

  const skillRef = useRef(null);

  const handleSkillStatus = (id, checked) => {
    if (checked) {
      dispatch(getSkillAndSaveToUserSkillsList(id));
    } else {
      dispatch(getSkillAndRemoveFromUserSkillsList(id));
    }
  };

  useEffect(() => {
    setChecked(skills.some((item) => item.id === skill?.id));
  }, [skills, skill?.id, id]);

  return (
    <div className={isChecked ? "c-skill c-skill--checked" : "c-skill"}>
      <input
        type="checkbox"
        id={`skill-${id}`}
        name={`skill-${id}`}
        className="c-skill__checkbox"
        checked={isChecked}
        ref={skillRef}
        onChange={(e) => {
          handleSkillStatus(skill?.id, e.target.checked);
          setChecked(e.target.checked);
        }}
        onKeyDown={(e) => {
          handleSkillStatus(skill?.id, e.target.checked);
          setChecked(e.target.checked);
        }}
      />
      <label htmlFor={`skill-${id}`} className="c-skill__label">
        {skill?.attributes?.preferred_label}
      </label>
    </div>
  );
};

export default SkillCourse;
