import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changePassword,
  authorizationAlert,
  setUserPrepaidByCode,
} from "../store/user";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
import Alert from "./layout/Alert";
import PasswordStrengthMeter from "./layout/PasswordStrengthMeter";

import TogglePasswordIcon from "../assets/images/eye.svg";

import "../styles/Settings.css";

const Settings = () => {
  const { user, alertMessage } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    currentPassword: "",
    password: "",
    password2: "",
    couponCode: "",
  });

  const [passwordCheck, setPasswordCheck] = useState({
    length: null,
    hasUpperCase: null,
    hasDigit: null,
  });

  const [errorAlertMessages, setErrorAlertMessages] = useState({
    currentPassword: "",
    password: "",
    password2: "",
    couponCode: "",
  });

  const [showCurrentPasswordInput, setShowCurrentPasswordInput] =
    useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showPassword2Input, setShowPassword2Input] = useState(false);

  const [actionType, setActionType] = useState("");

  const headingRef = useRef(null);

  const { currentPassword, password, password2, couponCode } = formData;

  useLayoutEffect(() => {
    document.title = "Ustawienia | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });

    dispatch(authorizationAlert(""));
  }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === "password") {
      setPasswordCheck((prevState) => ({
        ...prevState,
        length: e.target.value.length >= 8 ? true : false,
      }));
      setPasswordCheck((prevState) => ({
        ...prevState,
        hasUpperCase: /[A-Z]+/.test(e.target.value),
      }));
      setPasswordCheck((prevState) => ({
        ...prevState,
        hasDigit: /[0-9]+/.test(e.target.value),
      }));
    }
  };

  const onPrepaidCodeSubmit = async (e) => {
    e.preventDefault();

    if (couponCode === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        couponCode: "Wprowadź kod dostępu",
      }));
    } else {
      dispatch(setUserPrepaidByCode(couponCode, user?.id));

      setFormData((prev) => {
        return { ...prev, couponCode: "" };
      });
    }

    setActionType("couponCode");
  };

  const onPasswordChangeSubmit = async (e) => {
    e.preventDefault();

    if (currentPassword === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        currentPassword: "Wprowadź aktualne hasło",
      }));
    }

    setPasswordCheck((prevState) => ({
      ...prevState,
      length: password.length >= 8 ? true : false,
    }));
    setPasswordCheck((prevState) => ({
      ...prevState,
      hasUpperCase: /[A-Z]+/.test(password),
    }));
    setPasswordCheck((prevState) => ({
      ...prevState,
      hasDigit: /[0-9]+/.test(password),
    }));

    if (password === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        password: "Wprowadź hasło",
      }));
    } else {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        password:
          passwordCheck.length &&
          passwordCheck.hasUpperCase &&
          passwordCheck.hasDigit
            ? null
            : `Hasło powinno zawierać co najmniej ${
                !passwordCheck.length ? " 8 znaków" : ""
              } ${!passwordCheck.hasUpperCase ? " 1 wielką literę" : ""} ${
                !passwordCheck.hasDigit ? " 1 cyfrę" : ""
              }`,
      }));
    }

    if (password2 === "") {
      setErrorAlertMessages((prevState) => ({
        ...prevState,
        password2: "Powtórz hasło",
      }));
    }

    if (password !== "" && password2 !== "" && password !== password2) {
      dispatch(authorizationAlert("Password do not match", false));
    } else if (
      currentPassword !== "" &&
      password !== "" &&
      password2 !== "" &&
      password === password2
    ) {
      dispatch(changePassword(currentPassword, password, password2));

      setFormData((prev) => {
        return { ...prev, currentPassword: "", password: "", password2: "" };
      });

      setPasswordCheck({
        length: null,
        hasUpperCase: null,
        hasDigit: null,
      });
    }

    setActionType("changePassword");
  };

  return (
    <>
      <Skiplinks />
      <Navbar />
      <main id="content">
        <div className="l-skills-container">
          <div className="c-settings">
            <h2 className="c-settings__title" tabIndex={-1} ref={headingRef}>
              Ustawienia konta
            </h2>
            <div className="c-settings__box">
              <form className="c-form c-form--full-width" noValidate>
                <h2 className="c-form__title">Profil</h2>
                <label htmlFor="loginInput" className="c-form__label">
                  Login:
                </label>
                <input
                  type="text"
                  id="loginInput"
                  name="login"
                  className="c-form__input"
                  value={user?.username}
                  disabled
                />
                <label htmlFor="emailInput" className="c-form__label">
                  Email:
                </label>
                <input
                  type="text"
                  id="emailInput"
                  name="email"
                  className="c-form__input"
                  value={user?.email}
                  disabled
                />
              </form>
            </div>
            <div className="c-settings__box">
              <form
                className="c-form c-form--full-width"
                onSubmit={onPasswordChangeSubmit}
                noValidate
              >
                <h2 className="c-form__title">Zmiana hasła</h2>
                {alertMessage && actionType === "changePassword" ? (
                  <Alert />
                ) : null}
                <label htmlFor="currentPasswordInput" className="c-form__label">
                  Aktualne hasło*:
                </label>
                <div className="c-form__group">
                  <input
                    type={showCurrentPasswordInput ? "text" : "password"}
                    id="currentPasswordInput"
                    name="currentPassword"
                    className={
                      errorAlertMessages.currentPassword
                        ? "c-form__input c-form__input--password c-form__input--error"
                        : "c-form__input c-form__input--password"
                    }
                    value={currentPassword}
                    onChange={(e) => onChange(e)}
                    required
                    aria-describedby="currentPasswordInputDesc"
                  />
                  <button
                    type="button"
                    className="c-btn-toggle-password"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowCurrentPasswordInput(!showCurrentPasswordInput);
                    }}
                    aria-label={
                      showCurrentPasswordInput ? "Ukryj hasło" : "Pokaż hasło"
                    }
                  >
                    <img src={TogglePasswordIcon} alt="" />
                  </button>
                </div>
                {errorAlertMessages.currentPassword && (
                  <p
                    role="alert"
                    id="password2InputDesc"
                    className="c-form__error-message"
                    aria-atomic="true"
                  >
                    {errorAlertMessages.currentPassword}
                  </p>
                )}
                <label htmlFor="passwordInput" className="c-form__label">
                  Nowe hasło*:
                </label>
                <div className="c-form__group">
                  <input
                    type={showPasswordInput ? "text" : "password"}
                    id="passwordInput"
                    name="password"
                    className={
                      errorAlertMessages.password
                        ? "c-form__input c-form__input--password c-form__input--error"
                        : "c-form__input c-form__input--password"
                    }
                    value={password}
                    onChange={(e) => onChange(e)}
                    required
                    aria-describedby="passwordInputDesc"
                  />
                  <button
                    type="button"
                    className="c-btn-toggle-password"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPasswordInput(!showPasswordInput);
                    }}
                    aria-label={
                      showPasswordInput ? "Ukryj hasło" : "Pokaż hasło"
                    }
                  >
                    <img src={TogglePasswordIcon} alt="" />
                  </button>
                </div>
                <PasswordStrengthMeter password={password} />
                {errorAlertMessages.password && (
                  <p
                    role="alert"
                    id="passwordInputDesc"
                    className={
                      password !== "" ? "sr-only" : "c-form__error-message"
                    }
                    aria-atomic="true"
                  >
                    {errorAlertMessages.password}
                  </p>
                )}
                <div id="passwordInputDesc">
                  <p>Hasło powinno zawierać:</p>
                  <ul>
                    <li
                      style={
                        passwordCheck.length !== null
                          ? passwordCheck.length
                            ? { color: "#35C544" }
                            : { color: "#CD1414" }
                          : null
                      }
                    >
                      co najmniej 8 znaków
                    </li>
                    <li
                      style={
                        passwordCheck.hasUpperCase !== null
                          ? passwordCheck.hasUpperCase
                            ? { color: "#35C544" }
                            : { color: "#CD1414" }
                          : null
                      }
                    >
                      co najmniej 1 wielką literę
                    </li>
                    <li
                      style={
                        passwordCheck.hasDigit !== null
                          ? passwordCheck.hasDigit
                            ? { color: "#35C544" }
                            : { color: "#CD1414" }
                          : null
                      }
                    >
                      co najmniej 1 cyfrę
                    </li>
                  </ul>
                </div>
                <label htmlFor="passwordInput2" className="c-form__label">
                  Powtórz nowe hasło*:
                </label>
                <div className="c-form__group">
                  <input
                    type={showPassword2Input ? "text" : "password"}
                    id="passwordInput2"
                    name="password2"
                    className={
                      errorAlertMessages.password2
                        ? "c-form__input c-form__input--password c-form__input--error"
                        : "c-form__input c-form__input--password"
                    }
                    value={password2}
                    onChange={(e) => onChange(e)}
                    required
                    aria-describedby="password2InputDesc"
                  />
                  <button
                    type="button"
                    className="c-btn-toggle-password"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword2Input(!showPassword2Input);
                    }}
                    aria-label={
                      showPassword2Input ? "Ukryj hasło" : "Pokaż hasło"
                    }
                  >
                    <img src={TogglePasswordIcon} alt="" />
                  </button>
                </div>
                {errorAlertMessages.password2 && (
                  <p
                    role="alert"
                    id="password2InputDesc"
                    className="c-form__error-message"
                    aria-atomic="true"
                  >
                    {errorAlertMessages.password2}
                  </p>
                )}
                <button
                  type="submit"
                  className="c-btn c-btn--small-size c-btn--blue-color"
                >
                  Zmień hasło
                </button>
              </form>
            </div>
            <div className="c-settings__box">
              <h3 className="c-form__title">Konto przedpłacone</h3>
              {alertMessage && actionType === "couponCode" ? <Alert /> : null}
              <p className="c-settings__box-content">
                Sekcja dla osób i organizacji, które otrzymały specjalny kod,
                <br></br>umożliwiający dostęp do konta opłaconego z
                wyprzedzeniem.
              </p>
              <form
                className="c-form c-form--full-width"
                onSubmit={onPrepaidCodeSubmit}
                noValidate
              >
                <label htmlFor="couponCodeInput" className="c-form__label">
                  Kod dostępu*:
                </label>
                <input
                  type="text"
                  id="couponCodeInput"
                  name="couponCode"
                  className={
                    errorAlertMessages.couponCode
                      ? "c-form__input c-form__input--error"
                      : "c-form__input"
                  }
                  value={couponCode}
                  onChange={(e) => onChange(e)}
                  required
                />
                {errorAlertMessages.couponCode && (
                  <p
                    role="alert"
                    id="password2InputDesc"
                    className="c-form__error-message"
                    aria-atomic="true"
                  >
                    {errorAlertMessages.couponCode}
                  </p>
                )}
                <button
                  type="submit"
                  className="c-btn c-btn--small-size c-btn--blue-color"
                >
                  Aktywuj kod
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Settings;
