import React, { useEffect, useState, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

import Skiplinks from "./layout/Skiplinks";
import ContentLogo from "./layout/ContentLogo";
import Footer from "./layout/Footer";
import Career from "./layout/Career";
import DropdownCheckboxList from "./layout/DropdownCheckboxList";
import Loader from "./layout/Loader";

import BriefcaseIcon from "../assets/images/briefcase_icon.png";

import "../styles/CareersList.css";

const PROFILE_FILTERS = {
  profileHearing: [
    1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 14, 15, 18, 19, 20, 21, 22, 23, 24, 25,
    26, 27, 28, 30, 31, 32, 51, 54, 59, 60, 62, 74, 75, 92, 96, 97, 98, 100,
    103, 104, 107, 108, 109, 110, 113, 114, 115, 122, 123, 124, 125, 129, 132,
    139, 140, 141, 143, 144, 147, 154, 159, 160, 161, 162, 164, 165, 166, 167,
    168, 169, 205, 206, 208, 210, 211, 212, 214, 215, 217, 221, 227, 228, 230,
    235, 238, 240, 242, 243, 244, 247, 250,
  ],
  profileSight: [
    8, 13, 16, 17, 29, 33, 34, 35, 36, 61, 78, 83, 84, 85, 86, 93, 95, 126, 127,
    128, 137, 138, 142, 145, 146, 148, 149, 150, 151, 153, 157, 158, 163, 207,
    213, 218, 223, 229, 237, 245, 246, 248, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12,
    15, 18, 19, 20, 21, 27, 30, 31, 32, 51, 59, 60, 62, 96, 97, 98, 100, 103,
    104, 107, 108, 109, 110, 113, 114, 115, 122, 123, 124, 125, 132, 139, 140,
    141, 143, 144, 147, 154, 160, 161, 162, 164, 165, 166, 205, 206, 208, 210,
    217, 221, 227, 228, 230, 235, 238, 240, 243, 244, 247, 250,
  ],
  profileMovement: [
    251, 14, 22, 23, 24, 25, 26, 28, 82, 94, 136, 216, 232, 233, 234, 249, 54,
    74, 129, 159, 167, 168, 169, 211, 212, 214, 215, 8, 13, 16, 17, 29, 33, 34,
    35, 36, 61, 78, 83, 84, 85, 86, 93, 95, 126, 127, 128, 137, 138, 142, 145,
    146, 148, 149, 150, 151, 153, 157, 158, 163, 207, 213, 218, 223, 229, 237,
    245, 246, 248, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 15, 18, 19, 20, 21, 27,
    30, 31, 32, 51, 59, 60, 62, 96, 97, 98, 100, 103, 104, 107, 108, 109, 110,
    113, 114, 115, 122, 123, 124, 125, 132, 139, 140, 141, 143, 144, 147, 154,
    160, 161, 162, 164, 165, 166, 205, 206, 208, 210, 217, 221, 227, 228, 230,
    235, 238, 240, 243, 244, 247, 250,
  ],
};

const CareersList = () => {
  const { code } = useParams();

  const navigate = useNavigate();

  const [id, setId] = useState(null);

  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [filteredMatchedCareers, setFilteredMatchedCareers] = useState([]);

  const [visibleCareers, setVisibleCareers] = useState(3);

  const headingRef = useRef(null);
  const careersListRef = useRef(null);

  const { data: username } = useQuery({
    queryKey: ["username", id],
    queryFn: async () =>
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/users/${id}?fields[0]=username`
        )
        .then((response) => response.data.username)
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  const { data: matched, isError } = useQuery({
    queryKey: ["careers", "for-advisor", id],
    queryFn: async () =>
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/careers/for-advisor/${id}?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => response.data.data)
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  const itemsToShow = useMemo(() => {
    return filteredMatchedCareers
      ?.slice(0, visibleCareers)
      .map((item) => (
        <Career
          key={item.id}
          id={item.id}
          name_en={item.name_en}
          description={item.description}
          characteristics={item.characteristics}
          tasks={item.tasks}
          workplaces={item.workplaces}
        />
      ));
  }, [filteredMatchedCareers, visibleCareers]);

  const handleProfileChange = (selectedProfiles) => {
    setSelectedProfiles(selectedProfiles);
  };

  useEffect(() => {
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);
    const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_IV);

    const decryptedCode = CryptoJS.AES.decrypt(
      code
        .replace(/p1L2u3S/g, "+")
        .replace(/s1L2a3S4h/g, "/")
        .replace(/e1Q2u3A4l/g, "="),
      key,
      { iv: iv }
    );
    const decryptedPath = decryptedCode.toString(CryptoJS.enc.Utf8);

    if (!decryptedPath) {
      navigate("/404");
    } else {
      setId(parseInt(decryptedPath));
    }
  }, [code, id]);

  useEffect(() => {
    if (selectedProfiles.length === 0) {
      setFilteredMatchedCareers(matched);
    } else {
      const selectedProfileFilters = selectedProfiles.map(
        (selectedProfile) => PROFILE_FILTERS[selectedProfile]
      );
      const combinedProfileFilter = selectedProfileFilters.reduce(
        (combined, profileFilter) => {
          if (combined.length === 0) {
            return profileFilter;
          }
          return combined.filter((element) => profileFilter.includes(element));
        },
        []
      );
      const filteredMatchedList = matched.filter((item) =>
        combinedProfileFilter.includes(item.id)
      );
      setFilteredMatchedCareers(filteredMatchedList);
    }
  }, [selectedProfiles, matched]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 100
      ) {
        if (visibleCareers < filteredMatchedCareers?.length) {
          setVisibleCareers((prevVisibleCareers) => prevVisibleCareers + 3);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    const careerAccordionElements = document.querySelectorAll(
      ".c-career-accordion"
    );

    const handleFocus = (e) => {
      const lastCareerAccordionElement =
        careerAccordionElements.length > 0
          ? careerAccordionElements[careerAccordionElements.length - 1]
          : null;

      if (
        lastCareerAccordionElement &&
        e.target === lastCareerAccordionElement
      ) {
        if (visibleCareers < filteredMatchedCareers?.length) {
          setVisibleCareers((prevVisibleCareers) => prevVisibleCareers + 3);
        }
      }
    };

    careerAccordionElements.forEach((careerAccordionElement) => {
      careerAccordionElement.addEventListener("focus", handleFocus);
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);

      careerAccordionElements.forEach((careerAccordionElement) => {
        careerAccordionElement.removeEventListener("focus", handleFocus);
      });
    };
  }, [visibleCareers, filteredMatchedCareers]);

  useEffect(() => {
    if (username)
      document.title = `Lista zawodów ${username} | Jobbli - kieszonkowy doradca zawodowy`;
  }, [username]);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Skiplinks />
      <main id="content">
        <ContentLogo />
        <div className="l-careers-container">
          <div className="c-careers">
            <div className="c-careers__box">
              <h2 className="heading heading-h2" tabIndex={-1} ref={headingRef}>
                Zawody dopasowane do{" "}
                <span className="heading--weight-400 heading--color-underline heading--blue-color-underline">
                  {username ? username : <Skeleton />}
                </span>{" "}
                <img src={BriefcaseIcon} alt="" />
              </h2>
            </div>
            <div className="c-careers__box">
              <div className="c-careers-filter">
                <h3 className="c-careers-filter__title">Filtry</h3>
                <div className="c-careers-filter__box">
                  <DropdownCheckboxList
                    PROFILE_FILTERS={PROFILE_FILTERS}
                    onProfileChange={handleProfileChange}
                  />
                </div>
              </div>
            </div>
            <div className="c-careers__box">
              <div className="c-careers__list" ref={careersListRef}>
                {itemsToShow?.length ? (
                  itemsToShow
                ) : isError ? (
                  <Loader
                    content={
                      "Dla wybranego użytkownika nie odnaleziono dopasowanej listy zawodów"
                    }
                  />
                ) : (
                  <Loader content={"Trwa przygotowywanie profilu"} />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default CareersList;
