import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import Countdown from "./Countdown";

import LockedIcon from "../../assets/images/locked_icon.png";
import RocketIcon from "../../assets/images/rocket_icon.png";
import GlowingStarIcon from "../../assets/images/glowing-star_icon.png";
import SeedlingIcon from "../../assets/images/seedling_icon.png";
import PartyPopperIcon from "../../assets/images/party-popper_icon.png";
const PaymentInfo = (props, ref) => {
  const { type, totalMatchedCareers } = props;

  const { nextDayDailyCareersDisplay } = useSelector((state) => state.careers);

  const currentDate = new Date();
  const nextDay = new Date();

  nextDay.setDate(currentDate.getDate() + 1);
  nextDay.setHours(23, 59, 59);

  const formatter = new Intl.DateTimeFormat();
  const timeZone = formatter.resolvedOptions().timeZone;

  const formattedNextDay = nextDay.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone,
  });

  const { isLoading, refetch } = useQuery({
    queryKey: ["checkout"],
    queryFn: async () =>
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/checkout/new`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          window.location.href = response.data.url;
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
    enabled: false,
  });

  return (
    <div className="c-payment c-payment--info">
      {type === "matched" ? (
        <>
          <h3 className="heading heading-h3" ref={ref} tabIndex={-1}>
            Odblokuj {totalMatchedCareers} dopasowanych do Ciebie zawodów
            przyszłości! <img src={LockedIcon} alt="" />
          </h3>
          <p>
            Za jedyne <strong>29 zł</strong> poznaj wszystkie kariery
            przyszłości z naszej mapy karier i sprawdź {totalMatchedCareers}{" "}
            zawodów idealnie dopasowanych do Twoich mocnych stron, wśród których
            znajdziesz swoją pracę marzeń.{" "}
            <img className="c-icon" src={RocketIcon} alt="" />
            <img className="c-icon" src={GlowingStarIcon} alt="" />
          </p>
        </>
      ) : (
        <>
          <h3 className="heading heading-h3" ref={ref} tabIndex={-1}>
            Sprawdź kolejne kariery już za{" "}
            <Countdown targetDate={formattedNextDay} />{" "}
            <img className="c-icon" src={PartyPopperIcon} alt="" />
          </h3>
          <p>
            Wróć do nas, aby poznać kolejne zawody z listy. Już jutro odkryjesz,
            czym zajmują się{" "}
            <strong>
              {nextDayDailyCareersDisplay[0]?.attributes?.name_en}
            </strong>
            ,{" "}
            <strong>
              {nextDayDailyCareersDisplay[1]?.attributes?.name_en}
            </strong>{" "}
            i{" "}
            <strong>
              {nextDayDailyCareersDisplay[2]?.attributes?.name_en}
            </strong>{" "}
            - być może to właśnie to jeden z nich jest Twoją pracą marzeń, która
            da Ci satysfakcję, dobre zarobki i poczucie rozwoju.{" "}
            <img className="c-icon" src={SeedlingIcon} alt="" />
          </p>
          <h3 className="heading heading-h3 mt-4">
            Nie chcesz czekać?<br></br>
            Odblokuj wszystkie zawody przyszłości!
            <img src={LockedIcon} alt="" />
          </h3>
          <p>
            Za jedyne <strong>29 zł</strong> otrzymasz dostęp do wszystkich
            zawodów przyszłości, a także {totalMatchedCareers} idealnie
            dopasowanych do Ciebie karier, wśród których znajdziesz pracę
            marzeń.
            <img className="c-icon" src={RocketIcon} alt="" />
            <img className="c-icon" src={GlowingStarIcon} alt="" />
          </p>
        </>
      )}
      <button
        className="c-btn c-btn--regular-size c-btn--dark-blue-color c-btn--100-w"
        disabled={isLoading}
        onClick={() => refetch()}
      >
        Kup teraz
      </button>
    </div>
  );
};

export default React.forwardRef(PaymentInfo);
