import { useState, useMemo } from "react";
import CryptoJS from "crypto-js";
import parse from "html-react-parser";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";

import PaginationArrowLeft from "../../assets/images/pagination-arrow-left.svg";
import PaginationArrowLeftLeft from "../../assets/images/pagination-arrow-left-left.svg";
import PaginationArrowRight from "../../assets/images/pagination-arrow-right.svg";
import PaginationArrowRightRight from "../../assets/images/pagination-arrow-right-right.svg";

import SortingArrows from "../../assets/images/sorting-arrows.svg";

const DashboardTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "campaign_name",
        header: "Kampania",
      },
      {
        accessorKey: "code",
        header: "Kod",
      },
      {
        accessorKey: "is_claimed",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="c-status c-status--occupied">Wykorzystany</span>
          ) : (
            <span className="c-status c-status--free">Wolny</span>
          ),
      },
      {
        accessorKey: "claimed_by.username",
        header: "Wykorzystane przez",
      },
      {
        accessorKey: "claimed_by.id",
        header: "Dopasowane zawody",
        id: "careers-list",
        cell: (info) => {
          if (info.getValue()) {
            const id = info.getValue().toString();

            const key = CryptoJS.enc.Utf8.parse(
              process.env.REACT_APP_CRYPTO_KEY
            );
            const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_IV);

            const encryptId = CryptoJS.AES.encrypt(id, key, { iv: iv });
            const encryptCode = encryptId
              .toString()
              .replace(/\+/g, "p1L2u3S")
              .replace(/\//g, "s1L2a3S4h")
              .replace(/=/g, "e1Q2u3A4l");

            return (
              <a
                href={`${process.env.REACT_APP_API_URL}/profile/careers-list/${encryptCode}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Pokaż dopasowane zawody
                <span className="sr-only">
                  (link otwiera się w nowej karcie)
                </span>
              </a>
            );
          }
          return info.getValue();
        },
      },
      {
        accessorKey: "claimed_by.id",
        header: "Profil",
        id: "profile",
        cell: (info) => {
          if (info.getValue()) {
            const id = info.getValue().toString();

            const key = CryptoJS.enc.Utf8.parse(
              process.env.REACT_APP_CRYPTO_KEY
            );
            const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_IV);

            const encryptId = CryptoJS.AES.encrypt(id, key, { iv: iv });
            const encryptCode = encryptId
              .toString()
              .replace(/\+/g, "p1L2u3S")
              .replace(/\//g, "s1L2a3S4h")
              .replace(/=/g, "e1Q2u3A4l");

            return (
              <a
                href={`${process.env.REACT_APP_API_URL}/profile/${encryptCode}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Pokaż profil
                <span className="sr-only">
                  (link otwiera się w nowej karcie)
                </span>
              </a>
            );
          }
          return info.getValue();
        },
      },
    ],
    []
  );

  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: { sorting: sorting, globalFilter: filtering },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });

  return (
    <>
      <label className="sr-only" htmlFor="dashboardTableSearchInput">
        Wyszukaj:
      </label>
      <input
        type="text"
        id="dashboardTableSearchInput"
        name="dashboardTableSearch"
        className="c-dashboard__input"
        value={filtering}
        placeholder="Wyszukaj..."
        onChange={(e) => setFiltering(e.target.value)}
      />
      <div className="c-dashboard__table">
        <table>
          <thead>
            {table?.getHeaderGroups()?.map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {
                      {
                        asc: parse(
                          `<img src=${SortingArrows} alt="Sortuj malejąco" />`
                        ),
                        desc: parse(
                          `<img src=${SortingArrows} alt="Sortuj rosnąco" />`
                        ),
                      }[header.column.getIsSorted() ?? null]
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table?.getRowModel()?.rows?.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="c-dashboard__pagination">
        <button
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
          aria-label="Przejdź do pierwszej strony"
        >
          <img src={PaginationArrowLeftLeft} alt="" />
        </button>
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          aria-label="Poprzednia strona"
        >
          <img src={PaginationArrowLeft} alt="" />
        </button>
        <div>
          <label className="sr-only" htmlFor="dashboardTablePageInput">
            Numer strony:
          </label>
          <input
            type="number"
            id="dashboardTablePageInput"
            name="dashboardTablePage"
            className="c-dashboard-pagination__input"
            min={1}
            max={table.getPageCount()}
            value={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />{" "}
          z {table.getPageCount()}
        </div>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          aria-label="Następna strona"
        >
          <img src={PaginationArrowRight} alt="" />
        </button>
        <button
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
          aria-label="Przejdź do ostatniej strony"
        >
          <img src={PaginationArrowRightRight} alt="" />
        </button>
      </div>
    </>
  );
};

export default DashboardTable;
