import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  all: [],
  dailyCareersDisplay: [],
  nextDayDailyCareersDisplay: [],
  matched: [],
  favorite: [],
  totalCareers: 0,
  totalMatchedCareers: 0,
};

const careerSlice = createSlice({
  name: "careers",
  initialState,
  reducers: {
    setTotalCareersList: (state, action) => {
      return {
        ...state,
        totalCareers: action.payload,
      };
    },
    setInitialCareersList: (state, action) => {
      return {
        ...state,
        all: action.payload,
      };
    },
    addMoreCareersToCareersList: (state, action) => {
      return {
        ...state,
        all: state.all.concat(action.payload),
      };
    },
    setTotalMatchedCareersList: (state, action) => {
      return {
        ...state,
        totalMatchedCareers: action.payload,
      };
    },
    addCareerToDailyCareersDisplayList: (state, action) => {
      return {
        ...state,
        dailyCareersDisplay: state.dailyCareersDisplay.concat(action.payload),
      };
    },
    resetDailyCareersDisplayList: (state) => {
      return {
        ...state,
        dailyCareersDisplay: [],
      };
    },
    addCareerToNextDayDailyCareersDisplayList: (state, action) => {
      return {
        ...state,
        nextDayDailyCareersDisplay: state.nextDayDailyCareersDisplay.concat(
          action.payload
        ),
      };
    },
    resetNextDayDailyCareersDisplayList: (state) => {
      return {
        ...state,
        nextDayDailyCareersDisplay: [],
      };
    },
    setInitialMatchedList: (state, action) => {
      return {
        ...state,
        matched: action.payload,
      };
    },
    setInitialFavoriteCareersList: (state, action) => {
      return {
        ...state,
        favorite: action.payload,
      };
    },
    addCareerToFavoriteCareersList: (state, action) => {
      return {
        ...state,
        favorite: state.favorite.concat(action.payload),
      };
    },
    removeCareerFromFavoriteCareersList: (state, action) => {
      return {
        ...state,
        favorite: state.favorite.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    },
  },
});

export default careerSlice.reducer;

export const {
  setTotalCareersList,
  setInitialCareersList,
  addMoreCareersToCareersList,
  setTotalMatchedCareersList,
  addCareerToDailyCareersDisplayList,
  resetDailyCareersDisplayList,
  addCareerToNextDayDailyCareersDisplayList,
  resetNextDayDailyCareersDisplayList,
  setInitialMatchedList,
  setInitialFavoriteCareersList,
  addCareerToFavoriteCareersList,
  removeCareerFromFavoriteCareersList,
} = careerSlice.actions;

export const getTotalCareersListCount = () => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/careers`, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
    .then((response) => {
      dispatch(setTotalCareersList(response.data.meta.pagination.total));
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);
    });
};

export const getInitialCareersList = () => async (dispatch) => {
  await axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/careers?populate=*&pagination[page]=1&pagination[pageSize]=100`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    )
    .then(async (response) => {
      dispatch(setInitialCareersList(response.data.data));

      if (response.data.meta.pagination.pageCount > 1) {
        for (
          let page = 2;
          page <= response.data.meta.pagination.pageCount;
          page++
        ) {
          await axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/careers?populate=*&pagination[page]=${page}&pagination[pageSize]=100`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.token}`,
                },
              }
            )
            .then((response) => {
              dispatch(addMoreCareersToCareersList(response.data.data));
            })
            .catch((error) => {
              //console.error("An error occurred:", error.response);
            });
        }
      }
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);
    });
};

export const getDailyCareersDisplayList =
  (dailtyCareers) => async (dispatch) => {
    dispatch(resetDailyCareersDisplayList());

    for (let i = 0; i < dailtyCareers.length; i++) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/careers/${dailtyCareers[i]}?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          dispatch(addCareerToDailyCareersDisplayList(response.data.data));
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        });
    }
  };

export const getNextDayDailyCareersDisplayList =
  (dailtyCareers) => async (dispatch) => {
    dispatch(resetNextDayDailyCareersDisplayList());

    for (let i = 0; i < dailtyCareers.length; i++) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/careers/${dailtyCareers[i]}?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((response) => {
          dispatch(
            addCareerToNextDayDailyCareersDisplayList(response.data.data)
          );
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        });
    }
  };

export const getMatchedCareersList = () => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/careers/matched?populate=*`, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
    .then((response) => {
      dispatch(setTotalMatchedCareersList(response.data.total_careers));
      dispatch(setInitialMatchedList(response.data.data));
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);
    });
};

export const getInitialFavoriteCareersList = () => async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/careers/fav?populate=*`, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
    .then((response) => {
      dispatch(setInitialFavoriteCareersList(response.data));
    })
    .catch((error) => {
      //console.error("An error occurred:", error.response);
    });
};

export const getCareerAndSaveToFavoriteCareersList =
  (id) => async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/careers/${id}?populate=*`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(async (response) => {
        dispatch(
          addCareerToFavoriteCareersList({
            id: response.data.data.id,
            ...response.data.data.attributes,
          })
        );
        await axios
          .patch(
            `${process.env.REACT_APP_API_URL}/api/careers/fav`,
            { connect: [id], disconnect: [] },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
          .catch((error) => {
            //console.error("An error occurred:", error.response);
          });
      })
      .catch((error) => {
        //console.error("An error occurred:", error.response);
      });
  };

export const getCareerAndRemoveFromFavoriteCareersList =
  (id) => async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/careers/${id}?populate=*`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then(async (response) => {
        dispatch(removeCareerFromFavoriteCareersList(response.data.data));
        await axios
          .patch(
            `${process.env.REACT_APP_API_URL}/api/careers/fav`,
            { connect: [], disconnect: [id] },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.token}`,
              },
            }
          )
          .catch((error) => {
            //console.error("An error occurred:", error.response);
          });
      })
      .catch((error) => {
        //console.error("An error occurred:", error.response);
      });
  };

export const getCoursesForCareer = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/careers/${id}/courses?populate[course_offers][populate]=accessibility_info&populate[for_careers][populate]&populate[for_skills][populate]`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    //console.error("An error occurred:", error.response);
  }
};
