import React from "react";

import FavoriteIcon from "../../assets/images/favorite.svg";

import PoliceCarLightIcon from "../../assets/images/police-car-light_icon.png";

import "../../styles/NoFavoriteCareersSkillsInfo.css";

const NoFavoriteCareersSkillsInfo = () => {
  return (
    <div className="c-no-favorite-careers-info">
      <p>
        <img src={PoliceCarLightIcon} alt="" /> Na razie pusto...
      </p>
      <p>
        Wybierz{" "}
        <span>
          <img src={FavoriteIcon} alt="" />
          Ulubione
        </span>{" "}
        kariery z listy zawodów, aby poznać ścieżkę rozwoju.
      </p>
    </div>
  );
};

export default NoFavoriteCareersSkillsInfo;
