import React, { useState } from "react";

import Close from "../../assets/images/close-circle.svg";
import FavoriteIcon from "../../assets/images/favorite.svg";

import PoliceCarLightIcon from "../../assets/images/police-car-light_icon.png";

import "../../styles/Toast.css";

const Toast = () => {
  const [isActive, setIsActive] = useState(true);

  return (
    <div className={isActive ? "c-toast" : "c-toast hide"}>
      <div className="c-toast__header">
        <p>
          <img src={PoliceCarLightIcon} alt="" /> Na razie pusto...
        </p>
        <button
          onClick={() => {
            setIsActive(false);
          }}
          aria-label="Zamknij komunikat"
        >
          <img src={Close} alt="" />
        </button>
        <div className="c-toast--triangle"></div>
      </div>
      <div className="c-toast__content">
        <p>
          Wybierz{" "}
          <span>
            <img src={FavoriteIcon} alt="" />
            Ulubione
          </span>{" "}
          kariery z listy zawodów, aby mieć do nich łatwy dostęp w przyszłości.
        </p>
      </div>
    </div>
  );
};

export default Toast;
