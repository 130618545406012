import React, { useState, useMemo } from "react";
import { useQuery, useQueries } from "@tanstack/react-query";

import { getCoursesForSkill, getCareerSkills } from "../../store/skills";
import { getCoursesForCareer } from "../../store/careers";

import Skill from "./Skill";
import Course from "./Course";
import NoCoursesInfo from "./NoCoursesInfo";

import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";

import BooksIcon from "../../assets/images/books_icon.png";

import "../../styles/FavoriteCareerSkillsAndCourses.css";

const FavoriteCareerSkillsAndCourses = ({ id, name_en }) => {
  const [isActive, setActive] = useState(false);

  const { data: skills } = useQuery({
    queryKey: ["careers", id, "skills"],
    queryFn: () => getCareerSkills(id),
    enabled: isActive,
  });

  const { data: careersCourses } = useQuery({
    queryKey: ["careers", id, "courses"],
    queryFn: () => getCoursesForCareer(id),
    enabled: isActive,
  });

  const skillsCourses = useQueries({
    queries: (skills ?? []).map((skill) => ({
      queryKey: ["skills", skill.id, "courses"],
      queryFn: () => getCoursesForSkill(skill.id),
    })),
    enabled: isActive && skills?.length !== 0,
  });

  const newSkillsCourses = useMemo(
    () =>
      skillsCourses
        ?.filter((arr) => arr.data)
        ?.map((items) =>
          items?.data?.map((item) => {
            if (item?.for_careers?.data.length === 0) {
              return item;
            } else {
              return null;
            }
          })
        ),
    [skillsCourses]
  );

  return (
    <div className="c-accordion">
      <button
        type="button"
        className={isActive ? "c-accordion__btn active" : "c-accordion__btn"}
        onClick={() => setActive(!isActive)}
        aria-expanded={isActive ? true : false}
      >
        <p
          className="c-accordion__title--career-skills-courses"
          role="heading"
          aria-level={4}
        >
          Umiejętności wymagane w zawodzie<br></br>
          <span>{name_en}</span>
        </p>
        <img src={isActive ? ArrowUp : ArrowDown} alt="" />
      </button>
      <div
        className={
          isActive
            ? "c-accordion__content c-accordion__content--career-skills-courses open"
            : "c-accordion__content"
        }
      >
        {skills && (
          <>
            <p>Możesz odznaczyć umiejętności, które już posiadasz.</p>
            <div className="c-skills-list">
              <ul>
                {skills?.map((item) => (
                  <li key={item.id}>
                    <Skill {...item} />
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
        <div className="c-courses">
          <p className="c-courses__title" role="heading" aria-level={5}>
            Kursy <img src={BooksIcon} alt="" />
          </p>
          {careersCourses?.length !== 0 ||
          newSkillsCourses?.filter((arr) => arr.length !== 0)?.length !== 0 ? (
            <>
              <p className="mt-4">
                🟩⬜⬜ - początkujący, 🟨🟨⬜ - średniozaawansowany, 🟥🟥🟥 -
                zaawansowany
              </p>
              <div className="c-courses__list">
                <ul>
                  {careersCourses?.map((item) =>
                    item?.course_offers?.map((course) => (
                      <li key={course.id}>
                        <Course
                          id={course.id}
                          for_skills={item?.for_skills}
                          {...course}
                        />
                      </li>
                    ))
                  )}
                  {newSkillsCourses?.map((items) =>
                    items?.map((item) =>
                      item?.course_offers?.map((course) => (
                        <li key={course.id}>
                          <Course
                            id={course.id}
                            for_skills={item?.for_skills?.data}
                            {...course}
                          />
                        </li>
                      ))
                    )
                  )}
                </ul>
              </div>
            </>
          ) : (
            <NoCoursesInfo />
          )}
        </div>
      </div>
    </div>
  );
};

export default FavoriteCareerSkillsAndCourses;
