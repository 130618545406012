import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import parse from "html-react-parser";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useSpring, config } from "react-spring";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import Select from "./layout/Select";
import JobReportInfo from "./layout/JobReportInfo";
import JobOfferCard from "./layout/JobOfferCard";
import Footer from "./layout/Footer";

import ChatbotButton from "./ChatbotButton";

import BriefcaseIcon from "../assets/images/briefcase_icon.png";
import DoinggoodLogo from "../assets/images/doinggood-logo.png";

import "../styles/JobOffers.css";

const JobOffers = () => {
  const [locationArray, setLocationArray] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const headingRef = useRef(null);
  const jobOffersListRef = useRef(null);

  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: config.molasses,
    reset: true,
  });

  const { data: offers } = useQuery({
    queryKey: ["job_offers"],
    queryFn: async () =>
      await axios
        .get(`${process.env.REACT_APP_API_URL}/job_offers.json`)
        .then((response) =>
          Object.entries(response.data).map(([url, offer]) => ({
            url,
            ...offer,
          }))
        )
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
  });

  const filteredOffers = offers
    ? offers?.filter(
        (offer) =>
          offer?.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
          ((selectedLocation && offer?.location === selectedLocation) ||
            !selectedLocation) &&
          ((selectedCategory && offer?.category === selectedCategory) ||
            !selectedCategory)
      )
    : [];

  const [visibleOffers, setVisibleOffers] = useState(5);
  const totalOffers = filteredOffers.length;

  const isFiltering = searchTerm || selectedLocation || selectedCategory;

  useLayoutEffect(() => {
    document.title = "Oferty pracy | Jobbli - kieszonkowy doradca zawodowy";
  }, []);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const cleanAndCapitalize = (array) => {
      return [
        "",
        ...array
          .filter((item) => item.trim() !== "")
          .map((item) => item.charAt(0).toUpperCase() + item.slice(1)),
      ];
    };

    const originalLocationArray = offers?.map((offer) => offer?.location) || [];
    const uniqueLocationArray = cleanAndCapitalize([
      ...new Set(originalLocationArray),
    ]);

    const originalCategoryArray = offers?.map((offer) => offer?.category) || [];
    const uniqueCategoryArray = cleanAndCapitalize([
      ...new Set(originalCategoryArray),
    ]);

    setLocationArray(uniqueLocationArray);
    setCategoryArray(uniqueCategoryArray);
  }, [offers]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 100
      ) {
        if (visibleOffers < totalOffers) {
          setVisibleOffers((prevVisibleOffers) => prevVisibleOffers + 5);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    const jobCardElements = document.querySelectorAll(".c-job-offer-card");

    const handleFocus = (e) => {
      const lastJobCardElement =
        jobCardElements.length > 0
          ? jobCardElements[jobCardElements.length - 1]
          : null;
      if (lastJobCardElement && e.target === lastJobCardElement) {
        if (visibleOffers < totalOffers) {
          setVisibleOffers((prevVisibleOffers) => prevVisibleOffers + 5);
        }
      }
    };

    jobCardElements.forEach((jobCardElement) => {
      jobCardElement.addEventListener("focus", handleFocus);
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);

      jobCardElements.forEach((jobCardElement) => {
        jobCardElement.removeEventListener("focus", handleFocus);
      });
    };
  }, [visibleOffers, totalOffers]);

  return (
    <>
      <Skiplinks isChatbot />
      <Navbar />
      <main id="content">
        <ContentLogo />
        <div className="l-job-offers-container">
          <div className="c-job-offers">
            <div className="c-job-offers__box">
              <h2 className="heading heading-h2" tabIndex={-1} ref={headingRef}>
                Oferty pracy <img src={BriefcaseIcon} alt="" />
              </h2>
            </div>
            <div className="c-job-offers__box">
              <div className="c-job-offers-filter">
                <div className="c-job-offers__box">
                  <label className="sr-only" htmlFor="searchInput">
                    Wpisz nazwę stanowiska...
                  </label>
                  <input
                    type="text"
                    id="searchInput"
                    name="search"
                    className="c-job-offers__input"
                    placeholder="Wpisz nazwę stanowiska..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="c-job-offers__box">
                  <Select
                    type="location"
                    options={locationArray}
                    onChange={setSelectedLocation}
                  />
                </div>
                <div className="c-job-offers__box">
                  <Select
                    type="category"
                    options={categoryArray}
                    onChange={setSelectedCategory}
                  />
                </div>
              </div>
              {isFiltering && filteredOffers.length !== 0 && (
                <p role="alert">
                  Znaleziono <strong>{filteredOffers.length}</strong> ofert
                  {parse(
                    searchTerm && selectedLocation && selectedCategory
                      ? ` dla frazy <strong>${searchTerm}</strong> i kategorii <strong>${selectedLocation}</strong> oraz <strong>${selectedCategory}</strong>`
                      : searchTerm && selectedLocation
                      ? ` dla frazy <strong>${searchTerm}</strong> i kategorii <strong>${selectedLocation}</strong>`
                      : searchTerm && selectedCategory
                      ? ` dla frazy <strong>${searchTerm}</strong>i kategorii <strong>${selectedCategory}</strong>`
                      : searchTerm
                      ? ` dla frazy <strong>${searchTerm}</strong>`
                      : selectedLocation && selectedCategory
                      ? ` dla kategorii <strong>${selectedLocation}</strong> oraz <strong>${selectedCategory}</strong>`
                      : selectedLocation
                      ? ` dla kategorii <strong>${selectedLocation}</strong>`
                      : selectedCategory
                      ? ` dla kategorii <strong>${selectedCategory}</strong>`
                      : ""
                  )}
                </p>
              )}
            </div>
            <div className="c-job-offers__box">
              <h3 className="heading heading-h3">Ogłoszenia</h3>
              <JobReportInfo />
              <div className="c-job-offers__list" ref={jobOffersListRef}>
                {filteredOffers.length > 0 ? (
                  filteredOffers
                    .slice(0, visibleOffers)
                    .map((offer, index) => (
                      <JobOfferCard
                        key={index}
                        id={index}
                        style={props}
                        {...offer}
                      />
                    ))
                ) : (
                  <p role="alert">
                    Przepraszamy, obecnie nie mamy ofert pracy spełniających
                    Twoje wymagania. Sprawdź oferty pracy dla innych działów i
                    miast.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="l-doing-good">
          <div className="c-doing-good">
            <img src={DoinggoodLogo} alt="" />
            <h3>
              Oferty dostarcza<br></br>
              <span className="heading--dark-blue-color">Doing Good</span>
              <br></br>
              <span className="heading--weight-400">portal pracy z sensem</span>
            </h3>
          </div>
        </div>
      </main>
      <ChatbotButton />
      <Footer />
    </>
  );
};

export default JobOffers;
