import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Skiplinks from "./layout/Skiplinks";
import Navbar from "./layout/Navbar";
import ContentLogo from "./layout/ContentLogo";
import Footer from "./layout/Footer";

import ErrorIcon from "../assets/images/error.svg";
import SuccessIcon from "../assets/images/success.svg";

import BriefcaseIcon from "../assets/images/briefcase_icon.png";
import MoneyBagIcon from "../assets/images/money-bag_icon.png";
import PensiveFaceIcon from "../assets/images/pensive-face_icon.png";
import BackhandIndexPointingDownIcon from "../assets/images/backhand-index-pointing-down_icon.png";

import "../styles/PaymentStatusInfo.css";

const PaymentStatusInfo = ({ type }) => {
  const navigate = useNavigate();

  const headingRef = useRef(null);

  useEffect(() => {
    headingRef.current?.focus();
    window.scrollTo({
      top: headingRef.current?.offsetTop - 200,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Skiplinks />
      <Navbar />
      <main id="content">
        <ContentLogo />
        <div className="container">
          <div role="alert" className="c-payment c-payment--status">
            <div
              className={
                type === "success"
                  ? "c-alert c-alert--success"
                  : "c-alert c-alert--error"
              }
            >
              <img src={type === "success" ? SuccessIcon : ErrorIcon} alt="" />
              {type === "success" ? (
                <p tabIndex={-1} ref={headingRef}>
                  Dziękujemy za płatność!{" "}
                  <img className="c-icon" src={MoneyBagIcon} alt="" />
                </p>
              ) : (
                <p tabIndex={-1} ref={headingRef}>
                  Houston, mamy problem! Nie dokończono płatności{" "}
                  <img className="c-icon" src={PensiveFaceIcon} alt="" />
                </p>
              )}
            </div>
            {type === "success" ? (
              <p>
                Teraz możemy zbudować Ci karierę {" "}
                <img className="c-icon" src={BriefcaseIcon} alt="" />
              </p>
            ) : (
              <p>
                Jeśli chcesz poznać więcej idealnie dopasowanych<br></br>
                zawodów to wróć do płatności.{" "}
                <img
                  className="c-icon"
                  src={BackhandIndexPointingDownIcon}
                  alt=""
                />
              </p>
            )}
            {type === "success" ? (
              <button
                className="c-btn c-btn--regular-size c-btn--blue-color c-btn--100-w"
                onClick={() => navigate("/careers-list")}
              >
                Lista zawodów
              </button>
            ) : (
              <button
                className="c-btn c-btn--regular-size c-btn--blue-color c-btn--100-w"
                onClick={() => navigate(-1)}
              >
                Wróć do płatności
              </button>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PaymentStatusInfo;
