import React from "react";
import { useSelector } from "react-redux";

import MagnifyingGlassIcon from "../../assets/images/magnifying-glass_icon.png";

import "../../styles/JobReportInfo.css";

const JobReportInfo = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <div className="c-job-report-info">
      <p>
        Daj się znaleźć rekruterowi! <img src={MagnifyingGlassIcon} alt="" />
      </p>
      <p>
        Zamiast przeglądać ogłoszenia, daj się znaleźć rekruterowi w oparciu o
        Twoje mocne strony, oznaczone umiejętności i interesujące Cię zawody.
        Zgłoś się i skup na rozwoju w interesującym Cię kierunku, a my połączymy
        Cię z pracą dopasowaną do Ciebie.
      </p>
      <button
        className="c-btn c-btn--small-size c-btn--dark-blue-color c-btn--100-w"
        onClick={() =>
          window.open(
            `/api/outlink?uid=${user?.id}&url=https://jobbli.typeform.com/DajSieZnalezc#uid=${user?.id}`,
            "_blank"
          )
        }
      >
        Zgłoś się
        <span className="sr-only">(link otwiera się w nowej karcie)</span>
      </button>
    </div>
  );
};

export default JobReportInfo;
