import { useState, useEffect, useRef } from "react";

import SelectDownArrow from "../../assets/images/down-arrow.svg";
import SelectUpArrow from "../../assets/images/up-arrow.svg";

import LocationIcon from "../../assets/images/location.svg";
import CategoryIcon from "../../assets/images/building-3.svg";

import "../../styles/Select.css";

const Select = ({ type, options, onChange }) => {
  const dropdownRef = useRef(null);
  const listboxRef = useRef(null);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    onChange(options[index]);
    setIsOptionsOpen(false);

    setTimeout(() => moveFocusToSelectBtn(), 0);
  };

  const moveFocusToNextOption = () => {
    if (listboxRef.current) {
      const children = listboxRef.current.children;
      const optionsCount = children.length;

      for (let i = parseInt(selectedOption) + 1; i < optionsCount; i++) {
        if (children[i].tabIndex !== -1) {
          setSelectedOption(i);
          children[i].focus();
          break;
        }
      }
    }
  };

  const moveFocusToPreviousOption = () => {
    if (listboxRef.current) {
      const children = listboxRef.current.children;

      for (let i = selectedOption - 1; i >= 0; i--) {
        if (children[i].tabIndex !== -1) {
          setSelectedOption(i);
          children[i].focus();
          break;
        }
      }
    }
  };

  const moveFocusToSelectedOption = () => {
    listboxRef.current?.children[selectedOption].focus();
  };

  const moveFocusToFirstOption = () => {
    if (listboxRef.current) {
      listboxRef.current.firstChild.focus();
    }
  };

  const moveFocusToLastOption = () => {
    if (listboxRef.current) {
      listboxRef.current.lastChild.focus();
    }
  };

  const moveFocusToSelectBtn = () => {
    if (dropdownRef.current?.firstChild) {
      dropdownRef.current.firstChild.focus();
    }
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();

        if (isOptionsOpen) {
          setSelectedThenCloseDropdown(selectedOption);
        } else {
          setIsOptionsOpen(true);
        }

        setTimeout(() => moveFocusToSelectedOption(), 0);
        break;
      case " ":
        e.preventDefault();

        setIsOptionsOpen(true);

        setTimeout(() => moveFocusToSelectedOption(), 0);
        break;
      case "Tab":
        if (isOptionsOpen) {
          setSelectedThenCloseDropdown(selectedOption);
        }
        break;
      case "Escape":
        e.preventDefault();

        setIsOptionsOpen(false);

        setTimeout(() => moveFocusToSelectBtn(), 0);
        break;
      case "ArrowUp":
        e.preventDefault();

        if (isOptionsOpen) {
          moveFocusToPreviousOption();
        } else {
          if (dropdownRef.current?.firstChild === document.activeElement) {
            moveFocusToPreviousOption();
          }
        }
        break;
      case "ArrowDown":
        e.preventDefault();

        if (isOptionsOpen) {
          moveFocusToNextOption();
        } else {
          if (dropdownRef.current?.firstChild === document.activeElement) {
            moveFocusToNextOption();
          }
        }
        break;
      case "Home":
        e.preventDefault();

        if (isOptionsOpen) {
          moveFocusToFirstOption();
        }
        break;
      case "End":
        e.preventDefault();

        if (isOptionsOpen) {
          moveFocusToLastOption();
        }
        break;
      default:
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(e.target)) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <label id="combo-label" className="sr-only">
        {type === "location" ? "Lokalizacja" : "Dział"}
      </label>
      <div className="l-select-container" ref={dropdownRef}>
        <div
          role="combobox"
          id="combo1"
          className="c-select-box__btn"
          aria-controls="listbox1"
          aria-expanded={isOptionsOpen}
          aria-haspopup="listbox"
          aria-labelledby="combo-label"
          tabIndex={0}
          onClick={toggleOptions}
          onKeyDown={handleKeyDown}
        >
          <span>
            <img
              src={type === "location" ? LocationIcon : CategoryIcon}
              alt=""
            />
            {options[selectedOption] === ""
              ? type === "location"
                ? "Lokalizacja"
                : "Dział"
              : options[selectedOption]}
          </span>
          {isOptionsOpen ? (
            <img src={SelectUpArrow} alt="" />
          ) : (
            <img src={SelectDownArrow} alt="" />
          )}
        </div>
        <ul
          ref={listboxRef}
          role="listbox"
          id="listbox1"
          className={`c-select-box__listbox ${isOptionsOpen ? "expanded" : ""}`}
          aria-labelledby="combo1-label"
        >
          {options.map((option, index) => (
            <li
              role="option"
              key={option}
              className="c-select-box__option"
              aria-selected={selectedOption === index}
              tabIndex={0}
              onClick={() => {
                setSelectedThenCloseDropdown(index);
              }}
              onKeyDown={handleKeyDown}
            >
              {option === ""
                ? type === "location"
                  ? "Lokalizacja"
                  : "Dział"
                : option}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Select;
