const Skiplinks = ({ isChatbot }) => {
  return (
    <>
      <ul className="sr-only">
        <li>
          <a href="#content">Przejdź do treści</a>
        </li>
        {isChatbot && (
          <li>
            <a href="#chatbot">Przejdź do chatbota</a>
          </li>
        )}
        <li>
          <a href="#footer">Przejdź do stopki</a>
        </li>
      </ul>
    </>
  );
};

export default Skiplinks;
