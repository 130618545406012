import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import CryptoJS from "crypto-js";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Fragment } from "react";

import Skiplinks from "./layout/Skiplinks";
import ContentLogo from "./layout/ContentLogo";
import Loader from "./layout/Loader";
import Footer from "./layout/Footer";

import Union from "../assets/images/Union.png";
import Star1 from "../assets/images/Star-1.png";
import Star4 from "../assets/images/Star-4.png";

import BriefcaseIcon from "../assets/images/briefcase_icon.png";
import DrumIcon from "../assets/images/drum_icon.png";
import MagnifyingGlassIcon from "../assets/images/magnifying-glass_icon.png";
import CrownIcon from "../assets/images/crown_icon.png";
import BarChartIcon from "../assets/images/bar-chart_icon.png";
import PencilIcon from "../assets/images/pencil_icon.png";
import MemoIcon from "../assets/images/memo_icon.png";
import RocketIcon from "../assets/images/rocket_icon.png";
import SmilingFaceWithSunglassesIcon from "../assets/images/smiling-face-with-sunglasses_icon.png";
import SpeakNoEvilMonkeyIcon from "../assets/images/speak-no-evil-monkey_icon.png";
import StarStruckIcon from "../assets/images/star-struck_icon.png";
import SparklesIcon from "../assets/images/sparkles_icon.png";
import PartyPopperIcon from "../assets/images/party-popper_icon.png";

import ReportImg from "../assets/images/Report-img.png";

import Vector1Img from "../assets/images/vector-1.png";
import Vector2Img from "../assets/images/vector-2.png";
import Vector5Img from "../assets/images/vector-5.png";

import JobbliReportIcon from "../assets/images/jobbli-report.svg";

const RIASEC_PROFILE_PL_NAMES = {
  R: "Przedmiotowy",
  I: "Innowacyjny",
  A: "Kreatywny",
  S: "Społeczny",
  E: "Liderski",
  C: "Metodyczny",
};

const RIASEC_PROFILE_EN_NAMES = {
  R: "realistic",
  I: "investigative",
  A: "artistic",
  S: "social",
  E: "enterprising",
  C: "conventional",
};

const RIASEC_PROFILE_OPTIONS = {
  R: {
    color: "#6F8EFF",
    icon: MagnifyingGlassIcon,
  },
  I: {
    color: "#8C6FFE",
    icon: RocketIcon,
  },
  A: {
    color: "#FFD362",
    icon: PencilIcon,
  },
  S: {
    color: "#F67090",
    icon: SmilingFaceWithSunglassesIcon,
  },
  E: {
    color: "#F8673A",
    icon: CrownIcon,
  },
  C: {
    color: "#73AB51",
    icon: BarChartIcon,
  },
};

const ANSWERS_STATISTICS = {
  R: [4.4, 11.5, 13.8, 13.5, 12.3, 11.2, 10.8, 9.4, 7.3, 4.7, 1.3],
  I: [0.1, 0.5, 1.5, 3.8, 7.7, 12.4, 17.4, 20.3, 18.3, 12.4, 5.6],
  A: [0.3, 1.5, 3.8, 7.3, 10.4, 13.8, 15.8, 16.8, 14.6, 10.1, 5.7],
  S: [0.9, 1.9, 3.6, 6.0, 8.7, 11.6, 14.1, 15.9, 15.5, 13.1, 8.6],
  E: [0.6, 2.1, 4.1, 5.9, 7.4, 9.3, 11.1, 13.4, 16.3, 17.2, 12.7],
  C: [0.4, 1.2, 2.3, 4.1, 6.7, 10.3, 14.1, 17.2, 19.9, 16.8, 7.1],
};

const PublicProfile = () => {
  const { code } = useParams();

  const navigate = useNavigate();

  const [id, setId] = useState(null);

  const [profileName, setProfileName] = useState(null);
  const [userRiasecFirstProfileName, setUserRiasecFirstProfileName] =
    useState(null);
  const [userRiasecSecondProfileName, setUserRiasecSecondProfileName] =
    useState(null);
  const [profileWeakness, setProfileWeakness] = useState(null);
  const [riasecInfo, setRiasecInfo] = useState([]);

  const {
    data: user,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["user", id],
    queryFn: async () =>
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/users/${id}?populate=*`)
        .then(async (response) => {
          const profileNameArray = response.data?.riasec_profile.split("");
          const weaknesses = response.data?.weaknesses;
          setProfileWeakness(weaknesses)

          setProfileName(profileNameArray);

          if (profileNameArray && profileNameArray.length >= 2) {
            setUserRiasecFirstProfileName(
              RIASEC_PROFILE_PL_NAMES[profileNameArray[0]]
            );
            setUserRiasecSecondProfileName(
              RIASEC_PROFILE_PL_NAMES[profileNameArray[1]]
            );
          }

          return response.data;
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
    retry: 5,
    retryDelay: 1000,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const { isLoading } = useQuery({
    queryKey: ["riasec_info"],
    queryFn: async () =>
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/riasec-info`)
        .then(async (response) => {
          if (profileName && profileName.length >= 2) {
            setRiasecInfo([
              response.data.data.attributes[
                RIASEC_PROFILE_EN_NAMES[profileName[0]]
              ],
              response.data.data.attributes[
                RIASEC_PROFILE_EN_NAMES[profileName[1]]
              ],
            ]);
          }

          return response.data.data.attributes;
        })
        .catch((error) => {
          //console.error("An error occurred:", error.response);
        }),
    refetchOnWindowFocus: false,
    enabled: !!user,
  });

  const chartData = {
    series: [
      {
        name: "Wynik testu dopasowania predyspozycji zawodowych dla poszczególnych profili",
        data:
          user?.riasec_score &&
          Object.values(user?.riasec_score).slice(1, user?.riasec_score.length),
      },
    ],
    options: {
      chart: {
        type: "radar",
        fontFamily: "AtkinsonHyperlegible-Bold",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "24px",
          fontFamily: "AtkinsonHyperlegible-Bold",
          fontWeight: "bold",
          colors: ["#161623"],
        },
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: "#FFFFFF88",
            fill: {
              colors: ["#161623", "#161623"],
            },
          },
        },
      },
      title: {
        text: undefined,
      },
      colors: ["#FFFFFF"],
      markers: {
        size: 4,
        colors: ["#161623"],
        strokeColor: "#FFFFFF",
        strokeWidth: 2,
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: Object.values(RIASEC_PROFILE_PL_NAMES),
        labels: {
          show: true,
          style: {
            colors: [
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
              "#FFFFFF",
            ],
            fontSize: "18px",
            fontFamily: "AtkinsonHyperlegible-Bold",
          },
        },
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 12,
        labels: {
          style: {
            colors: "#FFFFFF",
            fontSize: "18x",
            fontFamily: "AtkinsonHyperlegible-Bold",
          },
          formatter: (val, i) => {
            if (i % 2 === 0) {
              return val;
            } else {
              return "";
            }
          },
        },
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            chart: {
              height: 500,
            },
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "18px",
                fontFamily: "AtkinsonHyperlegible-Bold",
                fontWeight: "bold",
                colors: ["#161623"],
              },
            },
            plotOptions: {
              radar: {
                size: 160,
              },
            },
            xaxis: {
              categories: Object.values(RIASEC_PROFILE_PL_NAMES).map(
                ([v]) => v
              ),
              labels: {
                show: true,
                tickPlacement: "on",
                style: {
                  colors: Object.values(RIASEC_PROFILE_OPTIONS).map(
                    (v) => v.color
                  ),
                  fontSize: "20px",
                  fontFamily: "AtkinsonHyperlegible-Bold",
                },
              },
            },
          },
        },
      ],
    },
  };

  const getKeyByValue = (object, value) => {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        if (object[prop] === value) return prop.toLowerCase();
      }
    }
  };

  useEffect(() => {
    const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);
    const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_IV);

    const decryptedCode = CryptoJS.AES.decrypt(
      code
        .replace(/p1L2u3S/g, "+")
        .replace(/s1L2a3S4h/g, "/")
        .replace(/e1Q2u3A4l/g, "="),
      key,
      { iv: iv }
    );
    const decryptedPath = decryptedCode.toString(CryptoJS.enc.Utf8);

    if (!decryptedPath) {
      navigate("/404");
    } else {
      setId(parseInt(decryptedPath));
      refetch();
    }
  }, [code, id]);

  useEffect(() => {
    if (user)
      document.title = `Profil ${user?.username} | Jobbli - kieszonkowy doradca zawodowy`;
  }, [user]);

  return (
    <>
      <Skiplinks />
      <main id="content">
        <ContentLogo />
        <div className="container">
          {!isLoading && user ? (
            <>
              <div className="l-report-container">
                <div className="c-report-welcome-container">
                  <div className="c-report-welcome-box">
                    <h2
                      className="heading heading--weight-400 heading-h2"
                      tabIndex={-1}
                    >
                      Przed Tobą<br></br>
                      <span className="heading--weight-700 heading--blue-color">
                        profil zawodowy
                      </span>{" "}
                      {user?.username},<br></br>
                      <span className="heading--color-underline heading--blue-color-underline">
                        przygotowany przez sztuczną inteligencję!
                      </span>
                    </h2>
                    <p>
                      Na jego podstawie opracowuję dla Ciebie rekomendacje
                      zawodów i plan kariery, który najlepiej wykorzysta Twój
                      potencjał.
                      <br></br> Poznaj co Cię wyróżnia na rynku digital!{" "}
                      <img className="c-icon" src={SparklesIcon} alt="" />
                    </p>
                  </div>
                  <div className="c-report-welcome-box">
                    <img src={ReportImg} alt="" />
                  </div>
                </div>
                <div className="c-report-profile">
                  <h3 className="heading heading-h3">
                    Profil zawodowy:{" "}
                    <span className="heading--weight-400 heading--color-underline heading--blue-color-underline">
                      {userRiasecFirstProfileName} -{" "}
                      {userRiasecSecondProfileName}
                    </span>{" "}
                    <img src={BriefcaseIcon} alt="" />
                  </h3>
                  <div className="c-report-profile-container">
                    <div className="c-report-profile-box">
                      <img
                        className="c-decoration-img"
                        src={Vector1Img}
                        alt=""
                      />
                      <div>
                        <p>
                          Twój dominujący profil to...{" "}
                          <img className="c-icon" src={DrumIcon} alt="" />
                          <br></br>
                          <span>
                            {userRiasecFirstProfileName} -{" "}
                            {userRiasecSecondProfileName}
                          </span>
                        </p>
                      </div>
                      <div></div>
                    </div>
                    <div className="c-report-profile-box" aria-hidden={true}>
                      <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="radar"
                        height={750}
                      />
                    </div>
                    {Object.keys(RIASEC_PROFILE_PL_NAMES).map((key) => (
                      <div
                        key={key}
                        className={`c-report-profile-box c-report-profile-box-${key.toLowerCase()}`}
                      >
                        <img
                          className="c-decoration-img"
                          src={Vector1Img}
                          alt=""
                        />
                        <p className="c-report-profile-box__title">
                          {RIASEC_PROFILE_PL_NAMES[key]}
                        </p>
                        <div
                          className={`c-report-profile-box__value c-report-profile-box__value-${key.toLowerCase()}`}
                        >
                          <img
                            className="c-icon"
                            src={RIASEC_PROFILE_OPTIONS[key].icon}
                            alt=""
                          />
                          <p>{user?.riasec_score[key]} pkt.</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="c-report-profile-description">
                  <h3 className="heading heading-h3">
                    Więcej o profilu:{" "}
                    <span className="heading--weight-400 heading--color-underline heading--blue-color-underline">
                      {userRiasecFirstProfileName}
                    </span>{" "}
                    <img src={RocketIcon} alt="" />
                  </h3>
                  <div className="c-report-profile-description-container">
                    <div
                      className={`c-report-profile-description-box c-report-profile-description-box-${getKeyByValue(
                        RIASEC_PROFILE_PL_NAMES,
                        userRiasecFirstProfileName
                      )}`}
                    >
                      <img
                        className="c-decoration-img"
                        src={Vector1Img}
                        alt=""
                      />
                      <p>
                        aż<br></br>
                        <span>
                          {(
                            (user?.riasec_score[
                              Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                (key) =>
                                  RIASEC_PROFILE_PL_NAMES[key] ===
                                  userRiasecFirstProfileName
                              )
                            ] /
                              (Object.values(user?.riasec_score).reduce(
                                (a, b) => a + b
                              ) -
                                user?.riasec_score["id"])) *
                            100
                          ).toFixed(1)}
                          %
                        </span>
                        <br></br>
                        Twoich predyspozycji odwołuje się do profilu znanego
                        jako <strong>{userRiasecFirstProfileName}!</strong>{" "}
                        <img
                          className="c-icon"
                          src={SmilingFaceWithSunglassesIcon}
                          alt=""
                        />
                      </p>
                    </div>
                    <div className="c-report-profile-description-box">
                      <img
                        className="c-decoration-img"
                        src={Vector2Img}
                        alt=""
                      />
                      <p>
                        tylko<br></br>
                        <span>
                          {userRiasecFirstProfileName &&
                            ANSWERS_STATISTICS[
                              Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                (key) =>
                                  RIASEC_PROFILE_PL_NAMES[key] ===
                                  userRiasecFirstProfileName
                              )
                            ][
                              user?.riasec_score[
                                Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                  (key) =>
                                    RIASEC_PROFILE_PL_NAMES[key] ===
                                    userRiasecFirstProfileName
                                )
                              ]
                            ]}
                          %
                        </span>
                        <br></br>
                        użytkowników zdobyło tak wysoki wynik w tej
                        predyspozycji!{" "}
                        <img className="c-icon" src={StarStruckIcon} alt="" />
                      </p>
                    </div>
                    <div
                      className={`c-report-profile-description-box c-report-profile-description-box-${getKeyByValue(
                        RIASEC_PROFILE_PL_NAMES,
                        userRiasecFirstProfileName
                      )}`}
                    >
                      <img src={JobbliReportIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div className="c-report-characteristics">
                  <h3 className="heading heading-h3">
                    6 Twoich cech <img src={SparklesIcon} alt="" />
                  </h3>
                  <p className="heading--weight-400 heading--small-size">
                    które nakierują Cię na właściwy wybór zawodowy
                  </p>
                  <div className="c-report-characteristics-container">
                    {riasecInfo[0]?.character?.map((data, index) => (
                      <div
                        key={data.id}
                        className="c-report-characteristic-box"
                      >
                        <div className="c-report-characteristic-box__index">
                          <p>{index + 1}</p>
                        </div>
                        <div className="c-report-characteristic-box__content">
                          <p>{data}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="c-report-profile-description">
                  <h3 className="heading heading-h3">
                    Więcej o profilu:{" "}
                    <span className="heading--weight-400 heading--color-underline heading--blue-color-underline">
                      {userRiasecSecondProfileName}
                    </span>{" "}
                    <img src={RocketIcon} alt="" />
                  </h3>
                  <div className="c-report-profile-description-container">
                    <div
                      className={`c-report-profile-description-box c-report-profile-description-box-${getKeyByValue(
                        RIASEC_PROFILE_PL_NAMES,
                        userRiasecSecondProfileName
                      )}`}
                    >
                      <img
                        className="c-decoration-img"
                        src={Vector1Img}
                        alt=""
                      />
                      <p>
                        aż<br></br>
                        <span>
                          {(
                            (user?.riasec_score[
                              Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                (key) =>
                                  RIASEC_PROFILE_PL_NAMES[key] ===
                                  userRiasecSecondProfileName
                              )
                            ] /
                              (Object.values(user?.riasec_score).reduce(
                                (a, b) => a + b
                              ) -
                                user?.riasec_score["id"])) *
                            100
                          ).toFixed(1)}
                          %
                        </span>
                        <br></br>
                        Twoich predyspozycji odwołuje się do profilu znanego
                        jako <strong>
                          {userRiasecSecondProfileName}!
                        </strong>{" "}
                        <img
                          className="c-icon"
                          src={SmilingFaceWithSunglassesIcon}
                          alt=""
                        />
                      </p>
                    </div>
                    <div className="c-report-profile-description-box">
                      <img
                        className="c-decoration-img"
                        src={Vector2Img}
                        alt=""
                      />
                      <p>
                        tylko<br></br>
                        <span>
                          {userRiasecSecondProfileName &&
                            ANSWERS_STATISTICS[
                              Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                (key) =>
                                  RIASEC_PROFILE_PL_NAMES[key] ===
                                  userRiasecSecondProfileName
                              )
                            ][
                              user?.riasec_score[
                                Object.keys(RIASEC_PROFILE_PL_NAMES).find(
                                  (key) =>
                                    RIASEC_PROFILE_PL_NAMES[key] ===
                                    userRiasecSecondProfileName
                                )
                              ]
                            ]}
                          %
                        </span>
                        <br></br>
                        użytkowników zdobyło tak wysoki wynik w tej
                        predyspozycji!{" "}
                        <img className="c-icon" src={StarStruckIcon} alt="" />
                      </p>
                    </div>
                    <div
                      className={`c-report-profile-description-box c-report-profile-description-box-${getKeyByValue(
                        RIASEC_PROFILE_PL_NAMES,
                        userRiasecSecondProfileName
                      )}`}
                    >
                      <img src={JobbliReportIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div className="c-report-characteristics">
                  <h3 className="heading heading-h3">
                    6 Twoich cech <img src={SparklesIcon} alt="" />
                  </h3>
                  <p className="heading--weight-400 heading--small-size">
                    które nakierują Cię na właściwy wybór zawodowy
                  </p>
                  <div className="c-report-characteristics-container">
                    {riasecInfo[1]?.character?.map((data, index) => (
                      <div
                        key={data.id}
                        className="c-report-characteristic-box"
                      >
                        <div className="c-report-characteristic-box__index">
                          <p>{index + 1}</p>
                        </div>
                        <div className="c-report-characteristic-box__content">
                          <p>{data}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="c-report-developments">
                  {profileWeakness !== "none" && (
                    <Fragment>
                      <h3 className="heading heading-h3">
                        Obszary do rozwoju:{" "}
                        <span className="heading--weight-400 heading--color-underline heading--blue-color-underline">
                          {profileWeakness === "organizacja"
                            ? "Organizacja czasu"
                            : profileWeakness === "samoocena"
                            ? "Zaniżona pewność siebie"
                            : profileWeakness === "stres"
                            ? "Radzenie sobie ze stresem"
                            : profileWeakness === "odkladanie"
                            ? "Odkładanie rzeczy na później"
                            : "Zaniżona pewność siebie"}
                        </span>{" "}
                        <img src={SpeakNoEvilMonkeyIcon} alt="" />
                      </h3>
                      {/* Rest of the development section */}
                      <div className="c-report-developments-container">
                        <div className="c-report-development-box">
                          <p className="c-report-development__title">Ryzyka:</p>
                          <ul>
                            <li>
                              {profileWeakness === "organizacja"
                            ? "Wraz ze zbliżającym  się deadlinem możesz odczuwać obniżony nastrój, stres, poczucie winy oraz napięcie."
                            : profileWeakness === "samoocena"
                            ? "Nieobiektywna, zaniżona ocena swoich umiejętności i talentów może utrudnić skuteczną autoprezentację w trakcie np. rozmowy kwalifikacyjnej."
                            : profileWeakness === "stres"
                            ? "Permanentny stres jest jedną z głównych przyczyn wypalenia zawodowego, który został włączony do międzynarodowej klasyfikacji chorób ICD-11."
                            : profileWeakness === "odkladanie"
                            ? "Gromadzenie zadań na później prowadzi do nagromadzenia obowiązków i silnego stresu tuż przed terminem ich realizacji. Może to wpłynąć negatywnie na jakość wykonywanej pracy, prowadząc do błędów wynikających z pośpiechu i braku dokładności."
                            : "Nieobiektywna, zaniżona ocena swoich umiejętności i talentów może utrudnić skuteczną autoprezentację w trakcie np. rozmowy kwalifikacyjnej."}
                            </li>
                            <li>
                              {profileWeakness === "organizacja"
                            ? "Możemy mierzyć się z problemami relacyjnymi w pracy zarówno z przełożonym jak i ze współpracownikami. Może to też zmniejszyć nasze szanse na awans."
                            : profileWeakness === "samoocena"
                            ? "Mniejsza odwaga do angażowania się w ciekawe i rozwojowe projekty."
                            : profileWeakness === "stres"
                            ? "Trwały stres może prowadzić do uczucia wyczerpania, niepokoju i zmniejszonej efektywności jak i poważnych chorób serca, cukrzycy czy insomnii."
                            : profileWeakness === "odkladanie"
                            ? "Codzienne życie może stać się chaotyczne i nieuporządkowane, co prowadzi do frustracji i poczucia braku kontroli nad czasem."
                            : "Mniejsza odwaga do angażowania się w ciekawe i rozwojowe projekty."}
                            </li>
                          </ul>
                        </div>
                        <div className="c-report-development-box">
                          <p className="c-report-development__title"> Co możesz z tym zrobić? </p>
                          <ul>
                            <li>
                              {profileWeakness === "organizacja"
                            ? "Minimalizuj  “rozpraszacze” - zacznij kontrolować ilość i częstotliwość korzystania np. z mediów społecznościowych, które skutecznie potrafią odciągnąć od realizacji zadania!"
                            : profileWeakness === "samoocena"
                            ? "Pierwszy krok to uświadomienie sobie, że to nie obiektywna ocena Twoich możliwości, a zaniżone poczucie wartości może dyktować Twoje zachowania i postawy."
                            : profileWeakness === "stres"
                            ? "Zwróć większą uwagę na dostosowanie pracy, zadań i warunków jej wykonywania do własnych psychofizycznych i fizjologicznych potrzeb."
                            : profileWeakness === "odkladanie"
                            ? "Wykorzystaj techniki zarządzania czasem, takie jak metoda Pomodoro, aby skoncentrować się na zadaniach przez krótkie, intensywne okresy, po których następują krótkie przerwy. Możesz też zastosować system nagród, aby zmotywować się do wcześniejszego rozpoczęcia pracy nad zadaniami."
                            : "Pierwszy krok to uświadomienie sobie, że to nie obiektywna ocena Twoich możliwości, a zaniżone poczucie wartości może dyktować Twoje zachowania i postawy."}
                            </li>
                            <li>
                              {profileWeakness === "organizacja"
                            ? "Postaraj się wyznaczać sobie mniejsze cele, które stopniowo będą Cię przybliżać do realizacji większego zadania."
                            : profileWeakness === "samoocena"
                            ? "Zwróć uwagę na komunikację z samym sobą, czy język jakiego używasz jest nacechowany negatywnie?"
                            : profileWeakness === "stres"
                            ? (
                                <>
                                  Jeśli odczuwasz symptomy wypalenia zawodowego niezwłocznie zgłoś się o pomoc do lekarza lub napisz do nas maila na <a href="mailto:contact@jobbli.pl" target="_blank">contact@jobbli.pl</a>, a my przekierujemy Cię do sprawdzonych specjalistów.
                                </>
                              )
                            : profileWeakness === "odkladanie"
                            ? "Regularnie monitoruj swoje postępy i dostosowuj planowanie oraz techniki, aby znaleźć najbardziej skuteczne dla Ciebie metody zarządzania czasem."
                            : "Zwróć uwagę na komunikację z samym sobą, czy język jakiego używasz jest nacechowany negatywnie?"}
                            </li>
                              {profileWeakness === "organizacja"
                            ? (
                                <>
                                  <li><a href="https://jobbli.typeform.com/to/LC2CekKW" target="_blank">Zapytaj o pomoc naszych job coachów!</a></li>
                                </>
                              )
                            : profileWeakness === "samoocena"
                            ? (
                                <>
                                  <li><a href="https://jobbli.typeform.com/to/LC2CekKW" target="_blank">Porozmawiaj z naszymi psychologami i job coachami.</a></li>
                                </>
                              )

                            : profileWeakness === "odkladanie"
                            ? (
                                <>
                                  <li><a href="https://jobbli.typeform.com/to/LC2CekKW" target="_blank">Porozmawiaj z naszymi psychologami i job coachami.</a></li>
                                </>
                              )
                            : (
                                <>
                                  <li><a href="https://jobbli.typeform.com/to/LC2CekKW" target="_blank">Porozmawiaj z naszymi psychologami i job coachami.</a></li>
                                </>
                              )}
                          </ul>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
                <div className="c-report-cv">
                  <h3 className="heading heading-h3">
                    Jak przełożyć tę wiedzę na Twoje CV?{" "}
                    <img src={MemoIcon} alt="" />
                  </h3>
                  <div className="c-report-cv-advices-container">
                    <div className="c-report-cv-advice-box">
                      <img id="star-4" src={Star1} alt="" />
                      <p>
                        Rozpocznij od słów oznaczających działanie, np.
                        zorganizowałem, zaprezentowałem.
                      </p>
                    </div>
                    <div className="c-report-cv-advice-box">
                      <img id="union" src={Union} alt="" />
                      <p>
                        Nie mów o tym, za co byłeś odpowiedzialny,<br></br>a co
                        osiągnąłeś.
                      </p>
                    </div>
                    <div className="c-report-cv-advice-box">
                      <img id="star-1" src={Star4} alt="" />
                      <p>
                        Struktura każdego zdania powinna zawierać rezultat
                        działań, drogę do jego osiągnięcia oraz jego wpływ.
                      </p>
                    </div>
                  </div>
                  <h3 className="heading heading-h3">
                    Przykłady: <img src={PencilIcon} alt="" />
                  </h3>
                  <div className="c-report-cv-advices-example-container">
                    <div className="c-report-cv-advices-example-box">
                      {riasecInfo[0] && (
                        <>
                          <p>
                            {riasecInfo[0].strong_suit}: {riasecInfo[0].desc}
                          </p>
                          <p>
                            <em>{riasecInfo[0].cv_entry}</em>
                          </p>
                        </>
                      )}
                    </div>
                    <div className="c-report-cv-advices-example-box">
                      {riasecInfo[1] && (
                        <>
                          <p>
                            {riasecInfo[1].strong_suit}: {riasecInfo[1].desc}
                          </p>
                          <p>
                            <em>{riasecInfo[1].cv_entry}</em>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="l-report-discord">
                <div className="c-report-discord">
                  <img className="c-decoration-img" src={Vector5Img} alt="" />
                  <h3 className="heading heading-h3">
                    Stwórz swój własny profil zawodowy{" "}
                    <img src={PartyPopperIcon} alt="" />
                  </h3>
                  <p>
                    Odkryj, która ścieżka kariery ma z Tobą najlepszy match.
                    Poznaj swoje supermoce, umiejętności, które warto rozwinąć i
                    zawód, w którym odniesiesz największy sukces.{" "}
                    <img className="c-icon" src={BriefcaseIcon} alt="" />
                  </p>
                  <button
                    className="c-btn c-btn--regular-size c-btn--dark-blue-color c-btn--100-w"
                    onClick={() =>
                      window.open(`https://app.jobbli.pl/`, "_self")
                    }
                  >
                    Stwórz swój profil
                  </button>
                </div>
              </div>
            </>
          ) : isError ? (
            <Loader
              content={"Dla wybranego użytkownika nie odnaleziono raportu"}
            />
          ) : (
            <Loader content={"Trwa przygotowywanie profilu"} />
          )}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PublicProfile;
