import React, { useState } from "react";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

import SkillCourse from "./SkillCourse";

import ArrowDown from "../../assets/images/arrow-down.svg";
import ArrowUp from "../../assets/images/arrow-up.svg";

import AccessibilityIcon from "../../assets/images/accessibility.svg";
import ClockIcon from "../../assets/images/clock.svg";
import CostIcon from "../../assets/images/cost.svg";
import LevelIcon from "../../assets/images/level.svg";
import CertificateIcon from "../../assets/images/certificate.svg";
import LanguageIcon from "../../assets/images/language.svg";

import BannerImg1 from "../../assets/images/course-banner-img-1.png";
import BannerImg2 from "../../assets/images/course-banner-img-2.png";
import BannerImg3 from "../../assets/images/course-banner-img-3.png";
import BannerImg4 from "../../assets/images/course-banner-img-4.png";
import BannerImg5 from "../../assets/images/course-banner-img-5.png";
import BannerImg6 from "../../assets/images/course-banner-img-6.png";
import BannerImg7 from "../../assets/images/course-banner-img-7.png";
import BannerImg8 from "../../assets/images/course-banner-img-8.png";
import BannerImg9 from "../../assets/images/course-banner-img-9.png";
import BannerImg10 from "../../assets/images/course-banner-img-10.png";
import BannerImg11 from "../../assets/images/course-banner-img-11.png";
import BannerImg12 from "../../assets/images/course-banner-img-12.png";

import "../../styles/Course.css";

const COURSE_BANNER_IMG_PALETTE = [
  BannerImg1,
  BannerImg2,
  BannerImg3,
  BannerImg4,
  BannerImg5,
  BannerImg6,
  BannerImg7,
  BannerImg8,
  BannerImg9,
  BannerImg10,
  BannerImg11,
  BannerImg12,
];

const PRIMARY_LANGUAGES_NAMES = {
  pl: "j. polski",
  en: "j. angielski",
};

const PRIMARY_LANGUAGES_LANG_NAMES = {
  pl: "pl",
  en: "en",
};

const EXPERTISE_LEVEL_NAMES = {
  1: "Początkujący",
  3: "Średniozaawansowany",
  5: "Zaawansowany",
};

const Course = ({
  id,
  name,
  primary_language,
  price,
  currency,
  link,
  description,
  offers_certificate,
  accessibility_info,
  expertise_level,
  duration_hours,
  creator,
  for_skills,
}) => {
  const { user } = useSelector((state) => state.user);

  const [isActive, setActive] = useState(false);

  const bannerCourseImg = COURSE_BANNER_IMG_PALETTE[id % 12];

  return (
    <div className="c-accordion">
      <button
        type="button"
        className={isActive ? "c-accordion__btn active" : "c-accordion__btn"}
        onClick={() => setActive(!isActive)}
        aria-expanded={isActive ? true : false}
      >
        <p className="c-accordion__title--course">{name}</p>
        <img src={isActive ? ArrowUp : ArrowDown} alt="" />
      </button>
      <div
        className={
          isActive ? "c-accordion__content open" : "c-accordion__content"
        }
      >
        <div
          style={{ backgroundImage: `url(${bannerCourseImg})` }}
          className="c-course-banner"
        ></div>
        <div className="c-course-description">
          <div
            lang={PRIMARY_LANGUAGES_LANG_NAMES[primary_language.slice(0, 2)]}
          >
            {description && parse(description)}
          </div>
          <div className="c-course-box">
            <p className="c-course-box__title">Informacje</p>
            <div className="c-course-infos">
              {duration_hours && (
                <div className="c-course-info">
                  <img src={ClockIcon} alt="Czas trwania" />
                  <p>{duration_hours} godz.</p>
                </div>
              )}
              <div className="c-course-info">
                <img src={CostIcon} alt="Cena" />
                <p>
                  {price !== 0 ? price : "Bezpłatny"} {price && currency}
                </p>
              </div>
              {expertise_level && (
                <div className="c-course-info">
                  <img src={LevelIcon} alt="Poziom trudności" />
                  <p>
                    Poziom {expertise_level}
                    <br></br>
                    {EXPERTISE_LEVEL_NAMES[expertise_level]}
                  </p>
                </div>
              )}
              <div className="c-course-info">
                <img src={CertificateIcon} alt="Certyfikat" />
                {offers_certificate ? (
                  <p>Certyfikat ukończenia</p>
                ) : (
                  <p>Brak certyfikatu ukończenia</p>
                )}
              </div>
              {primary_language && (
                <div className="c-course-info">
                  <img src={LanguageIcon} alt="Język" />
                  <p>{PRIMARY_LANGUAGES_NAMES[primary_language.slice(0, 2)]}</p>
                </div>
              )}
              {accessibility_info && (
                <div className="c-course-info">
                  <img src={AccessibilityIcon} alt="Dostępność" />
                  {accessibility_info?.reader_accessibility ? (
                    <p>Dostępny dla czytników ekranu</p>
                  ) : (
                    <p>Niedostępny dla czytników ekranu</p>
                  )}
                </div>
              )}
              {accessibility_info && (
                <div className="c-course-info">
                  <img src={AccessibilityIcon} alt="Dostępność" />
                  {accessibility_info?.video_audiodescription ? (
                    <p>Audiodeskrypcja</p>
                  ) : (
                    <p>Brak audiodeskrypcji</p>
                  )}
                </div>
              )}
              {accessibility_info && (
                <div className="c-course-info">
                  <img src={AccessibilityIcon} alt="Dostępność" />
                  {accessibility_info?.video_subtitles ? (
                    <p>Napisy</p>
                  ) : (
                    <p>Brak napisów</p>
                  )}
                </div>
              )}
            </div>
          </div>
          {creator && (
            <div className="c-course-box">
              <p className="c-course-box__title">Dostawca</p>
              {creator}
            </div>
          )}
          {for_skills && (
            <div className="c-course-box">
              <p className="c-course-box__title">
                Umiejętności po ukończeniu kursu
              </p>
              <div className="c-course-skills">
                <ul>
                  {for_skills?.map((item) => (
                    <SkillCourse key={item.id} id={item.id} />
                  ))}
                </ul>
              </div>
            </div>
          )}
          <button
            className="c-btn c-btn--small-size c-btn--blue-color c-btn--100-w"
            onClick={() =>
              window.open(
                `/api/outlink?uid=${user?.id}&url=${encodeURI(link)}`,
                "_blank"
              )
            }
          >
            Rozpocznij
            <span className="sr-only">(link otwiera się w nowej karcie)</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Course;
