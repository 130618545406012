import FavoriteIcon from "../../assets/images/favorite.svg";
import FavoriteSelectedIcon from "../../assets/images/favorite-selected.svg";

import "../../styles/RadioButtons.css";

const RadioButtons = (props) => {
  const { valueChange, category } = props;

  return (
    <>
      <div className="c-radio-container" role="radiogroup">
        <div className="c-radio">
          <input
            type="radio"
            id="radio1"
            name="radio1"
            className="c-radio__item"
            value="matched"
            checked={category === "matched"}
            aria-checked={category === "matched"}
            onChange={valueChange}
          />
          <label htmlFor="radio1" className="c-radio__label">
            Dopasowane
          </label>
        </div>
        <div className="c-radio">
          <input
            type="radio"
            id="radio2"
            name="radio2"
            className="c-radio__item"
            value="all"
            checked={category === "all"}
            aria-checked={category === "all"}
            onChange={valueChange}
          />
          <label htmlFor="radio2" className="c-radio__label">
            Wszystkie
          </label>
        </div>
        <div className="c-radio">
          <input
            type="radio"
            id="radio3"
            name="radio3"
            className="c-radio__item"
            value="favorite"
            checked={category === "favorite"}
            aria-checked={category === "favorite"}
            onChange={valueChange}
          />
          <label htmlFor="radio3" className="c-radio__label">
            <img
              src={
                category === "favorite" ? FavoriteSelectedIcon : FavoriteIcon
              }
              alt=""
            />{" "}
            Ulubione
          </label>
        </div>
      </div>
    </>
  );
};

export default RadioButtons;
